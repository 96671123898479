import { Col, Row } from 'antd'
import React, { useState } from 'react'
import { AppLayout } from 'components'
import styles from "./sytle.module.scss"
import { ReactComponent as BackIcon } from 'assets/svg/Back.svg'
import { useNavigate } from 'react-router-dom'
import ProjectManager from '../../components/Settings/ProjectManager/index'

const tabs = ['General', 'Time Manager', 'Project Manager', 'Habit Manager', 'Decision Manager']

const Settings = () => {
  const navigate = useNavigate()
  const [activeTab, setActiveTab] = useState('General')

  const handleTabClick = (tab) => {
    setActiveTab(tab)
  }

  const renderContent = () => {
    switch (activeTab) {
      case 'Project Manager':
        return <ProjectManager />
      default:
        return <p>No Content Available</p>
    }
  }

  return (
    <AppLayout>
      <Row className={styles.header} justify="space-between" align="middle">
        <Col>
          <div className={styles.titleContainer}>
            <h2 className={styles.title}>Settings</h2>
          </div>
          <div className={styles.tabsContainer}>
            {tabs.map((tab) => (
              <span
                key={tab}
                className={`${styles.tab} ${activeTab === tab ? styles.activeTab : ''}`}
                onClick={() => handleTabClick(tab)}
              >
                {tab}
              </span>
            ))}
          </div>
        </Col>
      </Row>

      <div className={styles.contentContainer}>
        {renderContent()}
      </div>
    </AppLayout>
  )
}

export default Settings
