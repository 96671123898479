import React, { useEffect, useState } from "react";
import { Dropdown, Modal, Row, Button, Space } from "antd";
import { ReactComponent as PencilIcon } from "assets/svg/pencil.svg";
import { ReactComponent as ArrowDown } from "assets/svg/arrowDownGray.svg";
import { ReactComponent as Cross } from "assets/svg/close.svg";
import { PlusOutlined, LoadingOutlined } from "@ant-design/icons";

import { useDispatch, useSelector } from "react-redux";
// @ts-ignore
import classes from "./styled.module.scss";
import ToggleMenu from "../Togglemenu/ToggleMenu";
import { blukUpdateGoal, getPredefinedMetrics } from "../../modules/actions/PlanActions";

const MetricSelect = ({
  selectedValue,
  setSelectedValue,
  setCustomModel,
}) => {
  const dispatch = useDispatch();
  const { planDetails, loadingPlan } = useSelector((state) => state.plan.plan);
  const getMetric = useSelector((state) => state?.plan?.predefinedMetrics);

  const [isDropdownOpen, setIsDropdownOpen] = useState(false);
  const [deletedItems, setDeletedItems] = useState([]);
  const [deletedUnits, setDeletedUnits] = useState([]);
  const [errorItems, setErrorItems] = useState([]);
  const [manageCustomUnit, setManageCustomUnit] = useState(false);
  const [customItems, setCustomItems] = useState([]);

  const { loading } = useSelector((state) => state.plan.plan);

  const handleCloseEditModel = () => {
    setManageCustomUnit(false);
    setDeletedItems([]);
    setErrorItems([]);
    setDeletedUnits([]);
    setCustomItems([]);
  };

  useEffect(() => {
    dispatch(getPredefinedMetrics())
  }, [])

  const capitalizeFirstLetter = (word) => {
    return word.charAt(0).toUpperCase() + word.slice(1);
  };


  const transformedItems = Array.isArray(getMetric)
  ? getMetric.map((item) => ({
      key: item?.label,
      label: capitalizeFirstLetter(item?.label),
      children: Array.isArray(item.metric_units)
        ? item.metric_units.map((unit) => ({
            key: unit?.name,
            label: unit?.name,
            onClick: () => {
              setSelectedValue({
                label: item?.label,
                unit: unit?.name,
                id: unit?.id,
              });
              setIsDropdownOpen(false);
            },
          }))
        : [],
    }))
  : [];


  const transformedLabels =
    planDetails?.goal?.custom_labels?.map((label) => ({
      key: label.id,
      label: label.value,
      unitId: label.id,

      children: label.custom_unit_options.map((item) => ({
        key: item.id,
        label: item.value,
        onClick: () => {
          setSelectedValue({
            label: label?.value,
            unit: item?.value,
            unitId: item?.id,
          });
          setIsDropdownOpen(false);
        },
      })),
    })) || [];

  const handleAddMetricClick = (event) => {
    event.stopPropagation();
    setIsDropdownOpen(false);
    setCustomModel({
      openCustom: true,
      labelName: "",
      labelValue: "",
      selectedUnitValue: [],
    });
  };

  const menuItems = [
    ...transformedItems,
    transformedLabels.length > 0 && {
      key: "customMetricsHeading",

      label: (
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            padding: 0,
          }}
        >
          <span
            style={{
              color: "#112538",
              fontSize: 11,
              cursor: "pointer",
              fontWeight: 600,
              fontFamily: "Inter",
            }}
          >
            Custom Metrics
          </span>
          <PencilIcon
            style={{ marginRight: "-7px" }}
            className="cursor-pointer"
            onClick={(e) => {
              e.stopPropagation();
              setIsDropdownOpen(false);
              setManageCustomUnit(true);
            }}
          />
        </div>
      ),
      type: "group",
    },
    ...transformedLabels.map((unit) => ({
      key: unit.key,
      label: unit.label,
      children: unit.children,
    })),
  ];

  const handleAddMetric = () => {
    setManageCustomUnit(false);
    setCustomModel({
      openCustom: true,
      labelName: "",
      labelValue: "",
      selectedUnitValue: [],
    });
  };

  const handleSave = async () => {
    const checkEmptyValue =
      customItems?.filter((item) => item.value === "") || [];

    const checkEmptyItemValue = customItems?.flatMap((item) =>
      item.custom_unit_options.filter((option) => option.value === "")
    );

    const emptyItemIds = [
      ...checkEmptyValue.map((item) => item.id),
      ...checkEmptyItemValue.map((option) => option.id),
    ];

    setErrorItems(emptyItemIds);

    if (checkEmptyValue.length > 0 || checkEmptyItemValue.length > 0) return;

    const matrics = customItems.map((item) => {
      return {
        value: item.value,
        id: item.id,
        goal: item.goal,
      };
    });

    const units = customItems.flatMap((item) =>
      item.custom_unit_options.map((option) => {
        if (option.isNew) {
          return {
            value: option.value,
            label: item.id,
          };
        }

        return {
          id: option.id,
          value: option.value,
          label: item.id,
        };
      })
    );

    const formattedData = {
      labels: [...matrics],
      units: [...units],
      unitsToBeDeleted: deletedUnits || [],
      labelsToBeDeleted: deletedItems || [],
    };

    await dispatch(
      blukUpdateGoal(formattedData, planDetails?.id, handleCloseEditModel)
    );
  };

  useEffect(() => {
    const cleanedData = planDetails?.goal?.custom_labels.map((item) => ({
      ...item,
      custom_unit_options: item.custom_unit_options.filter(
        (option) => option.value !== ""
      ),
    }));

    setCustomItems(cleanedData);
  }, [planDetails?.goal?.custom_labels, manageCustomUnit]);

  return (
    <>
      <Modal
        footer={null}
        closable={false}
        onCancel={handleCloseEditModel}
        title={false}
        open={manageCustomUnit}
        centered
        width={460}
        className="metricModel"
      >
        <div className="metric-model-header">
          <Row justify="space-between">
            <h3>Manage Custom Metrics</h3>
            <Cross className={"c-pointer"} onClick={handleCloseEditModel} />
          </Row>
        </div>

        <div className="metric-model-body">
          {customItems && customItems?.length > 0 ? (
            customItems?.map((item, index) => {
              const sortData = [...item?.custom_unit_options].sort(
                (a, b) => a.id - b.id
              );

              const data = {
                ...item,
                custom_unit_options: sortData,
              };

              return (
                <ToggleMenu
                  title={item?.value}
                  customItems={customItems}
                  setCustomItems={setCustomItems}
                  deletedItems={deletedItems}
                  setDeletedItems={setDeletedItems}
                  deletedUnits={deletedUnits}
                  setDeletedUnits={setDeletedUnits}
                  errorItems={errorItems}
                  setErrorItems={setErrorItems}
                  planDetails={data}
                  key={index}
                />
              );
            })
          ) : (
            <div className="empty-metric">No Custom Metrics Added</div>
          )}
        </div>

        <div className="metric-model-footer">
          <div
            className="footer-addMetricBtn addMetricBtn"
            onClick={handleAddMetric}
          >
            <PlusOutlined />
            Add Metric
          </div>

          <div className="footer-actions">
            <button onClick={handleCloseEditModel}>Cancel</button>

            <Button onClick={handleSave} disabled={loading} loading={loading}>
              Save
            </Button>
          </div>
        </div>
      </Modal>

      <Dropdown
        menu={{ items: menuItems }}
        trigger={["click"]}
        placement="bottomLeft"
        open={isDropdownOpen}
        overlayClassName={classes.customDropdownMenu}
        getPopupContainer={(trigger) => trigger.parentElement}
        onOpenChange={(open) => setIsDropdownOpen(open)}
        dropdownRender={(menu) => (
          <>
            <div className={classes.inner}>{menu}</div>
            <div className={classes.lastItem}>
              <span
                onClick={handleAddMetricClick}
                style={{
                  color: "#1271A6",
                  cursor: "pointer",
                  textAlign: "right",
                }}
              >
                + Add Metric
              </span>
            </div>
          </>
        )}
      >
        <a onClick={(e) => e.preventDefault()}>
          <Space className={classes.dropdownTrigger}>
            {selectedValue?.unit || "--"}
            {loadingPlan ? <LoadingOutlined /> : <ArrowDown />}
          </Space>
        </a>
      </Dropdown>
    </>
  );
};

export default MetricSelect;
