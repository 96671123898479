// @ts-ignore
import { getOptionFiles, createOptionFile, deleteOptionFile } from "api";

// @ts-ignore
import { Success, Error } from "utils/Response";

import { ActionTypes } from "./types";

export const GetOptionFiles = (payload: any) => async (dispatch: any) => {
  try {
    dispatch({ type: ActionTypes.GET_LOADING });
    const { data, status } = await getOptionFiles(payload);

    if (status === 200) {
      dispatch({
        type: ActionTypes.GET_SUCCESS,
        payload: data
      });
    }
  } catch (error: any) {
    Error(error?.response?.data?.error);

    dispatch({
      type: ActionTypes.GET_ERROR,
      payload: error,
    });
  }
};

export const CreateOptionFile = (payload: any, callback?: (response: any) => void) => async (dispatch: any) => {
  try {
    dispatch({ type: ActionTypes.CREATE_LOADING });
    const { data, status } = await createOptionFile(payload);

    if (status === 201) {
      dispatch({
        type: ActionTypes.CREATE_SUCCESS,
        payload: data
      });

      if (callback) {
        callback(data);
      }
    }
  } catch (error: any) {
    Error(error?.response?.data?.error);

    dispatch({
      type: ActionTypes.CREATE_ERROR,
      payload: error,
    });
  }
};

export const DeleteOptionFile = (payload: any) => async (dispatch: any) => {
  try {
    dispatch({ type: ActionTypes.DELETE_LOADING });
    const { status } = await deleteOptionFile(payload);

    if (status === 204) {
      dispatch({
        type: ActionTypes.DELETE_SUCCESS,
        payload
      });
    }
  } catch (error: any) {
    Error(error?.response?.data?.error);

    dispatch({
      type: ActionTypes.DELETE_ERROR,
      payload: error,
    });
  }
};

