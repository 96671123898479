import { Switch, Checkbox, DatePicker, TimePicker, Col, Row, Button as ResetButton } from "antd";
import { useDispatch, useSelector } from "react-redux";
import moment, { Moment } from "moment";
import styled from "styled-components";
import { useState, useEffect } from "react";

// @ts-ignore
import styles from "./style.module.scss";
// @ts-ignore
import { notificationSetting, getNotificationSetting } from "../../../modules/actions/PlanActions";
// @ts-ignore
import Button from "../../../components/Button";

// @ts-ignore
interface NotificationData {
  is_daily_summary: boolean;
  start_date?: string;
  end_date?: string;
  time?: string;
}

const ProjectManager: React.FC = () => {
  const dispatch = useDispatch();
  const response = useSelector((state: any) => state?.plan?.notificationData?.[0]) as NotificationData | undefined;

  const [isNotificationChecked, setIsNotificationChecked] = useState<boolean>(true);
  const [isDailySummaryChecked, setIsDailySummaryChecked] = useState<boolean>(response?.is_daily_summary ?? false);
  const [startDate, setStartDate] = useState<string | undefined>(response?.start_date);
  const [endDate, setEndDate] = useState<string | undefined>(response?.end_date);
  const [time, setTime] = useState<string | undefined>(response?.time);
  const [loading, setLoading] = useState<boolean>(false);
  const [isChanged, setIsChanged] = useState<boolean>(false);

  // 🔴 Error states for validation
  const [dateError, setDateError] = useState<string | null>(null);
  const [timeError, setTimeError] = useState<string | null>(null);

  const initialState: NotificationData = {
    is_daily_summary: response?.is_daily_summary ?? false,
    start_date: response?.start_date,
    end_date: response?.end_date,
    time: response?.time,
  };
  const getUserTimeZone = () => {
    return Intl.DateTimeFormat().resolvedOptions().timeZone;
  };

  useEffect(() => {
    if (response?.is_daily_summary !== undefined) {
      setIsDailySummaryChecked(response.is_daily_summary);
    }
    if (response?.start_date !== undefined) {
      setStartDate(response?.start_date)
    }
    if (response?.end_date !== undefined) {
      setEndDate(response?.end_date)
    }
    if (response?.time !== undefined) {
      setTime(response?.time)
    }
  }, [response]);

  useEffect(() => {
    dispatch(getNotificationSetting());
  }, [dispatch]);

  useEffect(() => {
    setIsChanged(
      isDailySummaryChecked !== initialState.is_daily_summary ||
      startDate !== initialState.start_date ||
      endDate !== initialState.end_date ||
      time !== initialState.time
    );
  }, [isDailySummaryChecked, startDate, endDate, time]);

  useEffect(() => {
    setDateError(null);
    setTimeError(null);
  }, [!startDate, !endDate, !time])

  const saveButton = async () => {
    if (loading) return;
    setLoading(true);

    // Reset errors before validation
    setDateError(null);
    setTimeError(null);
    // 🔴 Validate Date Range
    if (!startDate && !endDate && time) {
      setDateError("Please select a valid date range.");
      setLoading(false);
      return;
    }

    // 🔴 Validate Time
    if (!time && startDate && endDate) {
      setTimeError("Please select a valid time.");
      setLoading(false);
      return;
    }

    const payload: Partial<NotificationData> = {
      is_daily_summary: isDailySummaryChecked,
    };
    if (isDailySummaryChecked) {
      Object.assign(payload, {
        start_date: startDate,
        end_date: endDate,
        time: time,
        timezone: getUserTimeZone()
      });
    } else {
      Object.assign(payload, {
        start_date: null,
        end_date: null,
        time: null,
        timezone: getUserTimeZone()
      });
      setStartDate(undefined)
      setEndDate(undefined)
      setTime(undefined)
    }

    try {
      await dispatch(notificationSetting(payload));
      setIsChanged(false);
    } catch (error) {
      console.error("API Error", error);
    } finally {
      setLoading(false);
    }
  };

  const handleReset = async () => {
    setIsDailySummaryChecked(false);
    setIsNotificationChecked(true);
    setIsChanged(true)
  }
  return (
    <>
      <Row>
        <Col xs={24} md={8}>
          <div>
            <h3 className={styles.sectionTitle}>Notifications</h3>
            <div className={styles.notificationContainer}>
              <div className={styles.card}>
                <div>
                  <span className={styles.notificationTitle}>In-app notifications</span>
                  <p className={styles.notificationDescription}>
                    Receive notifications in your browser. Tap the bell to view your inbox.
                  </p>
                </div>
                <Switch checked={isNotificationChecked} onChange={setIsNotificationChecked} />
              </div>
              {isNotificationChecked && (
                <>
                  <div className={styles.card}>
                    <div className={styles.dialySummary}>
                      <div>
                        <span className={styles.notificationTitle}>Daily tasks summary </span>
                        <p className={styles.notificationDescription}>
                          Receive a notification when a summary of your daily tasks progress is available.
                        </p>
                      </div>
                      <Checkbox
                        className={styles.checkbox}
                        checked={isDailySummaryChecked}
                        onChange={() => setIsDailySummaryChecked(!isDailySummaryChecked)}
                      />
                    </div>
                  </div>
                  {isDailySummaryChecked && (
                    <Row>
                      <Col span={12}>
                        <div className={styles.dateTimeContainer}>
                          {/* 🔴 Date Range Picker with Error Message */}
                          <div>
                            <span className={styles.daterangTitle}>Date Range</span>
                            <DatePicker.RangePicker
                              value={startDate && endDate ? [moment(startDate), moment(endDate)] : undefined}
                              className={styles.datePicker}
                              format="MMM DD, YYYY"
                              separator="-"
                              disabledDate={(current) => current && current < moment().startOf("day")}
                              onChange={(dates) => {
                                if (dates && dates.length === 2) {
                                  setStartDate(dates[0]?.format("YYYY-MM-DD"));
                                  setEndDate(dates[1]?.format("YYYY-MM-DD"));
                                  setDateError(null); // Clear error on valid selection
                                } else {
                                  setStartDate(undefined)
                                  setEndDate(undefined)
                                  setDateError("Please select a valid date range.");
                                }
                              }}
                            />
                            {dateError && <span className={styles.errorText}>{dateError}</span>}
                          </div>

                          {/* 🔴 Time Picker with Error Message */}
                          <div>
                            <span className={styles.daterangTitle}>Time</span>
                            <TimePicker
                              popupClassName={styles.timepickerPopup}
                              value={time ? moment(time, "HH:mm:ss") : undefined}
                              format="h:mm A"
                              size="small"
                              className={styles.timePicker}
                              onChange={(time: Moment | null) => {
                                setTime(time ? time.format("HH:mm:ss") : undefined);
                                setTimeError(null); // Clear error on valid selection
                              }}
                            />
                            {timeError && <span className={styles.errorText}>{timeError}</span>}
                          </div>
                        </div>
                      </Col>
                    </Row>
                  )}
                </>
              )}
            </div>
          </div>
        </Col>
      </Row>
      <Row>
        <Col span={12}>
          <ResetButton
            className={styles.resetButton}
            onClick={handleReset}
            style={{ display: "flex", justifyContent: "flex-end", opacity: !isDailySummaryChecked ? 0.5 : 1 }}
            disabled={!isDailySummaryChecked}
            type="link">
            Reset to default
          </ResetButton>
        </Col>
        <Col span={12}>
          <div style={{ display: "flex", justifyContent: "flex-end", opacity: !isChanged ? 0.5 : 1 }}>
            <ActionButton onClick={saveButton} disabled={!isChanged || loading}>
              {loading ? "Saving..." : "Save Changes"}
            </ActionButton>
          </div>
        </Col>
      </Row>
    </>
  );
};

export default ProjectManager;

// 🔹 Styled Save Button
const ActionButton = styled(Button) <{ bgcolor?: string }>`
  background-color: ${({ bgcolor }) => bgcolor || "rgba(18, 113, 166, 1)"};
  color: #fff;
  width: 112px;
  height: 36px;

  ${({ theme }) => theme.max("lg")`
    width: 100%;
    max-width: 388px;
  `}

  &:disabled {
    opacity: 0.5;
    cursor: not-allowed;
  }
`;