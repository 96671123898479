const { Axios } = require("./axios");

export const generateAICriteria = (query) => {
  return Axios.get(
    `/api/v1/decision_matrix/option-criteria/ai_criteria/${query}`
  );
};

export const generateAIDefinition = (query) => {
  return Axios.get(
    `/api/v1/decision_matrix/option-criteria/ai_criteria_description/${query}`
    // `/api/v1/decision_matrix/option-criteria/ai_updated_description/${query}`
  );
};
export const generateAIPurposeDefinition = (query) => {
  return Axios.get(
    `/api/v1/decision_matrix/option-criteria/ai_generate_purpose/${query}`
  );
};
export const generateAIDescriptionDefinition = (query) => {
  return Axios.get(
    `/api/v1/decision_matrix/option-criteria/ai_generate_objective/${query}`
  );
};

export const generateAIScoringRubic = (query) => {
  return Axios.get(
    `/api/v1/decision_matrix/option-criteria/ai_rubric/${query}`
  );
};

export const generateAIProsCons = (query) => {
  return Axios.get(
    `/api/v1/decision_matrix/option-criteria/ai_pros_cons/${query}`
  );
};

export const updateOptionCriteria = (payload, id) => {
  return Axios.patch(`/api/v1/decision_matrix/option-criteria/${id}/`, payload);
};

export const createDecision = (payload) => {
  return Axios.post(`/api/v1/decision_matrix/decisions/`, payload);
};

export const getDecision = (query) => {
  return Axios.get(`/api/v1/decision_matrix/decisions/${query}`);
};
//append query parameter in above API

export const getDecisionDetails = (id, query) => {
  return Axios.get(`/api/v1/decision_matrix/decisions/${id}/${query}`);
};

export const getPublicDecisionDetails = (id, query) => {
  const config = {
    headers: {
      Authorization: undefined,
    },
  };

  return Axios.get(`/api/v1/decision_matrix/decisions/${id}/${query}`, config);
};

export const assignOption = (payload) => {
  return Axios.post(`/api/v1/decision_matrix/options/`, payload);
};

export const assignCriteria = (payload) => {
  return Axios.post(`/api/v1/decision_matrix/criteria/`, payload);
};
export const assignOptionCriteria = (payload) => {
  return Axios.post(`/api/v1/decision_matrix/option-criteria/`, payload);
};
export const getOption = (payload) => {
  return Axios.get(`/api/v1/decision_matrix/options/?decision=${payload}`);
};
export const getOptionDetail = (payload) => {
  return Axios.get(`/api/v1/decision_matrix/options/${payload}/`);
};
export const getCriteria = (payload) => {
  return Axios.get(`/api/v1/decision_matrix/criteria/?decision=${payload}`);
};
export const getMitigations = (payload) => {
  return Axios.get(
    `/api/v1/decision_matrix/mitigations/?option_criteria=${payload}`
  );
};
export const getPros = (payload) => {
  return Axios.get(`/api/v1/decision_matrix/pros/?option_criteria=${payload}`);
};
export const getCons = (payload) => {
  return Axios.get(`/api/v1/decision_matrix/cons/?option_criteria=${payload}`);
};
export const getOptionCriteria = () => {
  return Axios.get(`/api/v1/decision_matrix/option-criteria/`);
};

export const deleteOption = (payload) => {
  return Axios.delete(`/api/v1/decision_matrix/options/${payload}/`);
};

export const deleteOptionCriteria = (payload) => {
  return Axios.delete(`/api/v1/decision_matrix/option-criteria/${payload}/`);
};

export const deleteDecision = (payload, is_sample) => {
  return Axios.delete(
    `/api/v1/decision_matrix/decisions/${payload}/${
      is_sample ? "?is_sample=true" : ""
    }`
  );
};

export const deleteCriteria = (payload) => {
  return Axios.delete(`/api/v1/decision_matrix/criteria/${payload}/`);
};

export function bulkCreateCriteria(payload) {
  return Axios.post(`/api/v1/decision_matrix/bulk_criteria/`, payload.create);
}

export function bulkUpdateCriteria(payload) {
  return Axios.patch(`/api/v1/decision_matrix/bulk_criteria/`, payload.update);
}

export function bulkDeleteCriteria(payload) {
  return Axios.delete(
    `/api/v1/decision_matrix/bulk_criteria/?ids=${payload.delete.toString()}`
  );
}

export const deletePros = (payload) => {
  return Axios.delete(`/api/v1/decision_matrix/pros/${payload}/`);
};

export const deleteCons = (payload) => {
  return Axios.delete(`/api/v1/decision_matrix/cons/${payload}/`);
};

export const updateOption = (payload) => {
  return Axios.patch(
    `/api/v1/decision_matrix/options/${payload.id}/`,
    payload.body
  );
};

export const updateDecision = (payload) => {
  return Axios.patch(
    `/api/v1/decision_matrix/decisions/${payload.id}/`,
    payload.body
  );
};
export const updatePros = (payload, prosId, options = {}) => {
  return Axios.patch(`/api/v1/decision_matrix/pros/${prosId}/`, payload, {signal: options.signal});
};
export const updateCons = (payload, consId, options = {}) => {
  return Axios.patch(`/api/v1/decision_matrix/cons/${consId}/`, payload, {signal: options.signal});
};

export const updateCriteria = (payload) => {
  // return await Axios.patch(`/api/v1/decision_matrix/criteria/${payload.id}/`, payload.body);
  return Axios.patch(`/api/v1/decision_matrix/bulk_criteria/`, payload);
};

export const addOptionRows = (payload) => {
  return Axios.post(
    `/api/v1/decision_matrix/options/add_rows/?outer=true`,
    payload
  );
};

export const addCollaborators = (payload) => {
  return Axios.post(
    `/api/v1/decision_matrix/decisions/collaborators/`,
    payload
  );
};

export const deleteCollaborators = (payload) => {
  return Axios.delete(
    `/api/v1/decision_matrix/decisions/collaborators/`,
    payload
  );
};

export const addCriteriaRows = (payload) => {
  return Axios.post(`/api/v1/decision_matrix/criteria/add_rows/`, payload);
};
export const addToShortlist = (payload, id) => {
  return Axios.post(
    `/api/v1/decision_matrix/decisions/shortlist/?decision=${id}`,
    payload
  );
};
export const removeFromShortlist = (payload, id) => {
  return Axios.patch(
    `/api/v1/decision_matrix/decisions/shortlist/?decision=${id}`,
    payload
  );
};
export const getShortlists = (id) => {
  return Axios.get(
    `/api/v1/decision_matrix/decisions/shortlist/?decision=${id}`
  );
};

export const addToFinalist = (payload, id) => {
  return Axios.post(
    `/api/v1/decision_matrix/decisions/finalist/?decision=${id}`,
    payload
  );
};
export const removeFromFinalist = (payload, id) => {
  return Axios.patch(
    `/api/v1/decision_matrix/decisions/finalist/?decision=${id}`,
    payload
  );
};
export const getFinalists = (id) => {
  return Axios.get(
    `/api/v1/decision_matrix/decisions/finalist/?decision=${id}`
  );
};

export const duplicateDecision = (payload) => {
  return Axios.post(`/api/v1/decision_matrix/decisions/duplicate/`, payload);
};
// -----------AUTH APIS-------------

export const signup = (payload) => {
  return Axios.post(`/api/v1/signup/`, payload);
};
// body{
//    email,
//     password,
//     name: username,
// }
export const login = (payload) => {
  return Axios.post("/api/v1/login/", payload);
};
// body{
//     username: email,
//     password,
// }
export const getUserProfile = (token) => {
  const config = {};
  config.headers = { Authorization: `Token ${token}` };

  return Axios.get(`/api/v1/profile/get/`, config);
};

export const changeUserProfile = (payload) => {
  return Axios.patch(`/api/v1/user/update/`, payload);
};

export const updatePassword = (payload) => {
  return Axios.patch(`/api/v1/change-password/`, payload);
};

export const googleLogin = (payload) => {
  return Axios.post("/login/google/", payload);
};

export const facebookLogin = (payload) => {
  return Axios.post("/login/facebook/", payload);
};

export const appleLogin = (payload) => {
  return Axios.post("/login/apple/", payload);
};

export const forgotPassword = (payload) => {
  return Axios.post("/api/v1/password_reset/", payload);
};

// async function changePassword(payload) {
//   const url = baseURL.split("/api/v1/")[0];
//   const token = Cookies.get("lmos-token");
//   return axios.post(`${url}/rest-auth/password/change/`, payload, {
//     headers: {
//       Authorization: `Token ${token}`,
//     },
//   });
// }

export const resetPassword = (payload) => {
  return Axios.post(`/api/v1/password_reset/confirm/`, payload);
};

export const listTemplate = (is_allocator = false) => {
  return Axios.get(`/api/v1/decision_matrix/templates/?is_allocator=${is_allocator}`);
};

export const listSample = (token) => {
  return Axios.get(
    `/api/v1/decision_matrix/decisions/?nested=False&is_sample=True`
  );
};

export const createDecisionFromTemplate = (payload) => {
  return Axios.post(
    `/api/v1/decision_matrix/templates/create_decision/`,
    payload
  );
};
export const generateFacts = (optionCriteriaId) => {
  return Axios.get(
    `/api/v1/decision_matrix/option-criteria/ai_generate_facts/?option_criteria=${optionCriteriaId}`
  );
};

export const shareDecision = (payload) => {
  return Axios.post(`/api/v1/decision_matrix/decisions/share/`, payload);
};
export const getShareDecision = (query) => {
  return Axios.get(
    `/api/v1/decision_matrix/decisions/shared_view/?token=${query}`
  );
};

export const getSharedDecisions = () => {
  return Axios.get(`/api/v1/decision_matrix/decisions/shared-decisions/`);
};

export const getSharedDecisionDetails = (decisionId) => {
  return Axios.get(`/api/v1/decision_matrix/decisions/shared-decisions/?decision_id=${decisionId}`);
};

export const getDescriptionsSummary = (optionId) => {
  return Axios.get(
    `/api/v1/decision_matrix/options/pros_and_cons/?option=${optionId}`
  );
};

export const cloneDecision = payload => {
  return Axios.post(
    `/api/v1/decision_matrix/decisions/clone/`,
    payload
  );
};


export const resendOtp = payload =>{
  return Axios.post(
    `/api/v1/decision_matrix/users/resend_otp/`,
    payload
  );
};

export const verifyOtp = payload =>{
  return Axios.post(
    `/api/v1/decision_matrix/users/verify_otp/`,
    payload
  );
};

export const bulkUpdateOptionCriteria = (payload) => {
  return Axios.post(`/api/v1/decision_matrix/bulk_pro_and_con/`, payload);
};

// Option files
export const getOptionFiles = payload =>{
  return Axios.get(
    `/api/v1/decision_matrix/option_files/?option=${payload}`
  );
};

export const createOptionFile = payload =>{
  return Axios.post(
    `/api/v1/decision_matrix/option_files/`,
    payload
  );
};

export const deleteOptionFile = payload =>{
  return Axios.delete(
    `/api/v1/decision_matrix/option_files/${payload}/`,
  );
};

