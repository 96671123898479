import { updatePros } from "api";
import { Error } from "utils/Response";
import { GetPros } from "../GetPros/action";
import { ActionTypes } from "./types";
import { GetDescriptionsSummary } from "redux/DecisionMatrix/GetDecisionSummary/action";


const activeRequests = {}; // Store active requests

export const UpdatePros =
  (Data, prosId, id, setEditProsInput, optionId) => async (dispatch) => {
    // If there's already a request with the same prosId, cancel it
    if (activeRequests[prosId]) {
      activeRequests[prosId].abort();
    }

    // Create a new AbortController
    const controller = new AbortController();
    const signal = controller.signal;
    activeRequests[prosId] = controller;

    try {
      dispatch({ type: ActionTypes.LOADING });

      const { data, status } = await updatePros(Data, prosId, { signal });

      dispatch({
        type: ActionTypes.SUCCESS,
        payload: data,
      });

      if (status === 200) {
        dispatch(GetPros(id));
        if (optionId) {
          dispatch(GetDescriptionsSummary(optionId));
        }
        setEditProsInput(null);
      }

      // Remove the request from active requests after completion
      delete activeRequests[prosId];

    } catch (error) {
      if (error.name === "AbortError") {
        console.log(`Request for prosId ${prosId} was aborted`);
      } else {
        Error(error?.response?.data?.error);
        dispatch({
          type: ActionTypes.ERROR,
          payload: error,
        });
      }
    }
  };

