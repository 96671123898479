import styled, { css } from "styled-components";

export const Container = styled.div`
  width: 100%;
  height: 100%;
  padding-bottom: 45px;
  overflow-y: auto;
  max-height: calc(100vh - 200px);
  scroll-behavior: smooth;
  position: relative;
`;

export const Wrapper = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  scroll-behavior: smooth;
`;

export const Head = styled.div<{ $color?: string; isShort: boolean }>`
  display: flex;
  align-items: center;
  height: 44px;
  background: var(--Primary-400, #418db8);
  position: sticky;
  top: 0;
  z-index: 2;
  padding-left: 6px;
  min-width: ${({ isShort }) => (isShort ? "1200px" : "1680px")};

  &::before {
    content: "";
    min-width: 6px;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
  }

  ${({ $color }) =>
    $color &&
    css`
      &::before {
        background: ${$color};
      }
    `}
`;

const sizes = [
  "24.60",
  "8.09",
  "7.26",
  "5.77",
  "5.77",
  "7.03",
  "8.40",
  "8.17",
  "6.63",
  "7.66",
  "7.77",
];

export const HeadItem = styled.span<{ $center?: boolean }>`
  display: flex;
  align-items: center;
  height: 44px;
  /* border-right: 1px solid var(--Primary-300, #71aaca); */
  color: #171717;
  background-color: #fff;
  text-align: center;
  font-family: Inter;
  font-size: 14px;
  font-weight: 600;
  padding: 12px;
  width: 100%;
  min-width: 10%;

  ${({ $center }) =>
    $center &&
    css`
      text-align: center;
      justify-content: center;
    `}

  &:nth-child(1) , &:nth-child(2)  , &:nth-last-child(1) {
    position: relative;
    min-width: 40px;
    width: 40px;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  &:nth-child(3) {
    min-width: 32%;

    &:hover {
      .add-catrgory {
        opacity: 1;
        visibility: visible;
      }
    }
  }

  span {
    font-weight: 500;
    font-size: 12px;
    color: #ff9900;
    margin-left: 50px;
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 10px;
    cursor: pointer;
    opacity: 0;
    visibility: hidden;
  }
`;

const SpecialButton = css`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 44px;
  height: 28px;
  background: transparent;
  padding: 0;
  border: none;
  transform: rotate(-45deg);
  margin-top: -16px;
  margin-left: -10px;
  position: absolute;

  img {
    transform: rotate(45deg);
  }
`;

export const BtnAddCategory = styled.button`
  ${SpecialButton};
  border-bottom: 1px solid var(--Primary-300, #71aaca);
  outline: none;
`;

export const BtnExpand = styled.button`
  ${SpecialButton};
  margin-top: 14px;
  margin-left: 8px;
  outline: none;
`;

export const Body = styled.div`
  display: flex;
  flex-direction: column;
  // overflow-y: auto;
`;

export const CustomizePopover = styled.div`
  width: 260px;
  padding: 18px 16px;
  overflow-y: auto;

  &::-webkit-scrollbar {
    width: 4px; // Set the width of the scrollbar
  }

  &::-webkit-scrollbar-track {
    background: #f1f1f1 !important; // Background of the scrollbar track
    z-index: -1;
  }

  &::-webkit-scrollbar-thumb {
    background-color: #ced0d4 !important; // Color of the scrollbar thumb
    border-radius: 10px; // Rounded corners
  }

  &::-webkit-scrollbar-thumb:hover {
    background-color: #aeb0b5 !important; // Darker color on hover
  }

  /* Firefox scrollbar styling */
  scrollbar-width: thin;
  scrollbar-color: #ced0d4 #f1f1f1; // Thumb and track color for Firefox
`;

export const CustomizeSubHeader = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 16px;

  h5 {
    font-size: 12px;
    font-weight: 600;
    color: #262626;
    margin: 0;
    padding: 0;
    font-family: "Inter";
  }

  button {
    border: none;
    background: transparent;
    outline: none;
    font-size: 12px;
    font-weight: 600;
    color: #1271a6;
    margin: 0;
    padding: 0;
    font-family: "Inter";
    cursor: pointer;

    :disabled {
      color: #a0c6db;
      cursor: not-allowed;
    }
  }
`;

export const CustomizeHeader = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 25px;

  h6 {
    margin: 0;
    padding: 0;
    color: #1b2a3d;
    font-size: 14px !important;
    font-weight: 600;
  }
`;

export const CustomizeItems = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;
`;

export const CustomizeItem = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;

  .customizeItem-title {
    display: flex;
    align-items: center;
    gap: 10px;

    h4 {
      margin: 0;
      padding: 0;
      color: #1b2a3d;
      font-size: 12px;
      font-weight: 400;
    }
  }
`;

export const CardViewWrapper = styled.div`
  padding: 0px 10px 20px 20px;
  width: 100%;
  display: flex;
  gap: 10px;
  overflow: auto;
`;

export const AIGenerationLoading = styled.div`
  background-color: white;
  height: 500px;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;

  img {
    margin-top: 50px;
  }
  p {
    font-size: 18px;
    font-weight: 700;
  }
`;

export const BultAction = styled.div`
  padding: 14px 24px;
  width: max-content;
  min-width: 800px;
  height: 48px;
  display: flex;
  align-items: center;
  gap: 10px;
  position: absolute;
  z-index: 100;
  bottom: 20px;
  border-radius: 4px;
  left: 50%;
  background-color: #fff;
  box-shadow: 2px 2px 15px 0px rgba(0, 0, 0, 0.25);

  .bulk-action-undo {
    margin: 0px 10px;
    gap: 6px;
  }

  .bulk-action-item {
    position: relative;
    display: flex;
    align-items: center;
    gap: 4px;
    cursor: pointer;

    .bulk-action-item-icon {
      position: absolute;
      top: -2.5px;
      left: 0;
      color: #4c545b;

      .active {
        color: #1271a6;
      }

      .disabled {
        color: #4c545b;
        opacity: 0.5;
      }
    }

    .bulk-action-date-picker-icon {
      position: absolute;
      top: 2px;
      left: 10px;

      .active {
        color: #1271a6;
      }

      .disabled {
        color: #4c545b;
        opacity: 0.5;
      }
    }

    h3 {
      color: #4c545b;
      font-size: 12px;
      font-weight: 500;
      margin: 0;
      padding: 0;
      font-family: "Inter";
    }

    svg {
      color: #4c545b;
    }
  }
`;

export const BulkActionLoading = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  margin-right: 10px;
  color: #1271a6;
`;

export const ModalHeader = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 14px 24px;

  .modal-left {
    display: flex;
    align-items: center;
    gap: 10px;

    .modal-left-info {
      width: 48px;
      height: 48px;
      border-radius: 50%;
      background-color: #fff0f0;
      display: flex;
      align-items: center;
      justify-content: center;

      svg {
        color: #f00000;
      }
    }

    h3 {
      margin: 0;
      padding: 0;
      font-size: 18px;
      font-weight: 600;
      color: #171717;
    }
  }
`;

export const ModelContent = styled.div`
  padding: 12px 24px;
  font-size: 14px;
  font-weight: 400;
  color: #171717;

  border-bottom: 1px solid #edeef2;
`;

export const ModalBody = styled.div`
  padding: 12px 24px;
  display: flex;
  align-items: flex-start;
  flex-direction: column;
  margin-bottom: 12px;

  li {
    color: #404040;
    font-size: 14px;
    font-weight: 500;
    padding: 12px 0px;
    list-style: disc;
  }
`;

export const ModalFooter = styled.div`
  display: flex;
  align-items: flex-end;
  justify-content: flex-end;
  gap: 12px;
  padding: 16px 24px;
  background-color: #f5f5f5;
  margin-top: 16px;

  button {
    border: 1px solid #1271a6;
    border-radius: 2px;
    font-size: 13px;
    font-weight: 500;
    cursor: pointer;
    font-family: "Inter";
    padding: 8px 20px;

    &:disabled {
      cursor: not-allowed;
      opacity: 0.6;
    }
  }

  button:nth-child(1) {
    background-color: transparent;
    color: #1271a6;
  }

  button:nth-child(2) {
    background-color: #1271a6;
    color: #fff;
  }
`;
