export const SIGN_IN_PATH = "/sign-in";
export const SIGN_UP_PATH = "/sign-up";
export const FORGOT_PASSWORD_PATH = "/forgot-password";
export const FORGOT_PASSWORD_SUCCESS_PATH = "/forgot-password/success";
export const RESET_PASSWORD_PATH = "/reset-password";
export const SIGN_UP_INVITE = "/project-planner/new-user";
export const REQUEST_ACCESS = "/project-planner/request-access";
export const ACCESS_REQUESTED = "/project-planner/access-requested";
export const OTP_PATH = "/otp";

export const PROJECT_PLANNER = "project-planner";
export const DECISION_COACH = "decision-manager";
export const ALLOCATOR_TOOL = "allocator-tool";
export const SETTINGS = '/settings'
