import React, { FC, InputHTMLAttributes, ReactNode } from 'react';
import PropTypes from 'prop-types';

import styled, { css } from 'styled-components';

const CheckedIcon = () => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16" fill="none">
      <rect x="0.75" y="0.75" width="14.5" height="14.5" rx="7.25" fill="white" stroke="#418DB8" strokeWidth="1.5" />
      <rect x="4" y="4" width="8" height="8" rx="4" fill="#1271A6" />
    </svg>
  );
}

const UncheckedIcon = () => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16" fill="none">
      <rect x="0.75" y="0.75" width="14.5" height="14.5" rx="7.25" fill="white" stroke="#CDCDCD" strokeWidth="1.5" />
    </svg>
  );
}

interface RadioButtonProps extends InputHTMLAttributes<HTMLInputElement> {
  label?: ReactNode;
  id: string;
  rtl?: boolean;
  width?: string;
  styles?: any;
}

const RadioButton: FC<RadioButtonProps> = ({ label, id, checked, rtl, width, styles = {}, ...props }) => {
  const renderLabel = () => {
    if (typeof label === 'string')
      return <Content>{label}</Content>;
    return label;
  };

  return (
    <Wrapper $width={width}>
      <Input data-testid="test-radio" type="radio" id={id} {...props} />
      <Label data-testid="test-label" htmlFor={id} $gap={!!label} $rtl={rtl} $align={typeof label === 'string'} $checked={checked} style={styles?.label}>
        {checked ? <CheckedIcon /> : <UncheckedIcon />}
        {renderLabel()}
      </Label>
    </Wrapper>
  );
}

const RadioButtonPropTypes = {
  label: PropTypes.node,
  id: PropTypes.string.isRequired
};

RadioButton.propTypes = RadioButtonPropTypes;

const Wrapper = styled.div<{ $width?: string; }>`
  display: flex;

  ${({ $width }) => $width && css`
    width: ${$width};
  `}
`;

const Input = styled.input`
  position: fixed;
  width: 0;
  height: 0;
  z-index: -1;
  font-size: 0;
  opacity: 0;
  visibility: hidden;
`;

const Label = styled.label<{ $gap?: boolean; $rtl?: boolean; $align?: boolean; $checked?: boolean; }>`
  flex: 1;
  display: flex;
  cursor: pointer;
  user-select: none;

  ${({ $gap }) => $gap && css`
    gap: 13px;
  `}

  ${({ $align }) => $align && css`
    align-items: center;
  `}

  ${({ $rtl }) => $rtl && css`
    flex-direction: row-reverse;
  `}

  ${({ $checked }) => $checked && css`
    cursor: default;
  `}
`;

const Content = styled.span`
  color: var(--fill-icon-oxford-blue, #06203A);
  font-family: Inter;
  font-size: 14px;
  font-weight: 700;
  line-height: 20px;
  user-select: none;
`;

export default RadioButton;
