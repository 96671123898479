import { getStatus } from "../../ProjectPlanner/components/GoalSettings/utils";

function formatDate(dateString) {
  if (!dateString) return "";

  const date = new Date(dateString);

  // Check if date is valid
  if (isNaN(date.getTime())) return dateString;

  // Format as MM/DD/YYYY
  const month = String(date.getMonth() + 1).padStart(2, "0");
  const day = String(date.getDate()).padStart(2, "0");
  const year = date.getFullYear();

  return `${month}/${day}/${year}`;
}

export const generatePDFTemplate = (planDetails, metric) =>
  `
    <style>
      @page {
        margin: 0;
        counter-increment: page;
      }
      
      body {
        counter-reset: page;
      }

      .page-counter::before {
        content: counter(page);
      }
      
      .page-counter::after {
        content: " / " counter(pages);
      }
    </style>

    <div style="padding: 20px; font-family: Arial, sans-serif;">
      <div
        style="
          display: flex;
          align-items: center;
          justify-content: space-between;
          width: 100%;
          border-bottom: 1px solid #C1CAD1;
          padding: 30px 40px;
          padding-top: 0px;
        "
      >
        <div>
          <img
            src="https://fit-from-anywhere.nyc3.cdn.digitaloceanspaces.com/Group60381740490040650.png"
            alt="logo"
            style="width: 300px; height: 55px;"
          />
        </div>
        <div>
          <h2 style="font-size: 30px; font-weight: 600; color: #06203a; font-family: 'Poppins', sans-serif;">
            ${planDetails?.name || ""}
          </h2>
          <p style="color: #4c545b; font-size: 20px; font-weight: 400; font-family: 'Poppins', sans-serif; float: right; margin-top: 0px;">
            Deadline: <span style="font-weight: 600"> ${
              planDetails?.dead_line || ""
            } </span>
          </p>
        </div>
      </div>

      <div style="padding: 24px 30px ">

      ${
        planDetails?.objective
          ? `
        <div style="margin-bottom: 40px;">
          <h3 style="color: #404040; font-size: 24px; margin-bottom: 10px; font-family: 'Poppins', sans-serif; font-weight: 500;">
            Objective
          </h3>
          <p style="color: #737373; font-size: 18px; margin: 0; font-family: 'Poppins', sans-serif;">
            ${planDetails?.objective}
          </p>
        </div>
      `
          : ""
      }


      <div style="background: #FAFAFA; padding: 16px 20px;  border-radius: 8px; margin-bottom: 30px;">
     
        <div style="display: flex;  align-items: center; position: relative;">
          <div>
             <h2 style="color: #000000; font-size: 20px; margin: 0 0 20px 0; font-family: 'Inter', sans-serif; font-weight: 600; width: 100%; max-width:800px;">${
               planDetails?.goals?.goal_name || "No Goal Connected"
             }</h2>

            <table style="min-width: 300px; border-collapse: collapse; border-radius: 4px; overflow: hidden;">
              <tr style="">
              ${
                metric === 0 || metric === null || metric === undefined || false
                  ? ""
                  : `<th style="padding: 12px; padding-left: 0px; color: #262626; font-weight: 400; text-align: left; font-family: 'Inter', sans-serif;">
                    Metric
                  </th>`
              }
                <th style="padding: 12px; color: #262626; font-weight: 400; text-align: left; font-family: 'Inter', sans-serif;">Start</th>
                <th style="padding: 12px; color: #262626; font-weight: 400; text-align: left; font-family: 'Inter', sans-serif;">Current</th>
                <th style="padding: 12px; color: #262626; font-weight: 400; text-align: left; font-family: 'Inter', sans-serif;">Target</th>
              </tr>
              <tr>
              

                ${
                  metric === 0 || metric === null || metric === undefined
                    ? ""
                    : `<td style="padding: 12px;padding-left: 0px; padding-top: 8px; font-size: 18px; color: #A3A3A3; font-weight: 500; font-family: 'Inter', sans-serif;">${metric}</td>`
                }
             
                <td style="padding: 12px; padding-left: 13px; padding-top: 8px; font-size: 18px;  color: #A3A3A3; font-weight: 500; font-family: 'Inter', sans-serif;">${
                  parseFloat(planDetails?.goal?.start || 0) % 1 === 0
                    ? parseInt(planDetails?.goal?.start || 0)
                    : parseFloat(planDetails?.goal?.start || 0)
                }</td>
                <td style="padding: 12px; padding-left: 13px; padding-top: 8px; font-size: 18px; color: #A3A3A3; font-weight: 500; font-family: 'Inter', sans-serif;">${
                  parseFloat(planDetails?.goal?.current || 0) % 1 === 0
                    ? parseInt(planDetails?.goal?.current || 0)
                    : parseFloat(planDetails?.goal?.current || 0)
                }</td>
                <td style="padding: 12px; padding-left: 13px; padding-top: 8px; font-size: 18px; color: #A3A3A3; font-weight: 500; font-family: 'Inter', sans-serif;">${
                  parseFloat(planDetails?.goal?.target || 0) % 1 === 0
                    ? parseInt(planDetails?.goal?.target || 0)
                    : parseFloat(planDetails?.goal?.target || 0)
                }</td>
              </tr>
            </table>
          </div>

          <div style="display: flex; gap: 30px; position: absolute; right: 0;">
            <div style="text-align: center;">
              <div style="
                position: relative;
                display: flex;
                align-items: center;
                justify-content: center;
              ">
                <div style="display: flex;">
                  <!-- Goal Progress Circle -->
                  <div style="position: relative; width: 165px; height: 100%;  display: flex; align-items: center; justify-content: center;">
                    <svg width="140" height="140" viewBox="0 0 100 100">
                      <!-- Background circle -->
                      <circle
                        cx="50"
                        cy="50"
                        r="45"
                        stroke="#E5E9EB"
                        stroke-width="6"
                        fill="none"
                      />
                      <!-- Progress circle -->
                      <circle
                        cx="50"
                        cy="50"
                        r="45"
                        stroke="#FF9900"
                        stroke-width="6"
                        fill="none"
                        stroke-linecap="round"
                        stroke-dasharray="${2 * Math.PI * 45}"
                        stroke-dashoffset="${
                          2 *
                          Math.PI *
                          45 *
                          (1 - Number(planDetails?.goal?.progress || 0))
                        }"
                        transform="rotate(-90 50 50)"
                      />
                    </svg>
                    <div style="
                       position: absolute;
                      display: flex;
                      flex-direction: column;
                      align-items: center;
                      justify-content: flex-start;
                      margin-top: 50px;
                      height: 100%;
                    ">
                      <span style="font-size: 24px; font-weight: 300; color: #000000; margin-bottom:2px;">
                        ${(
                          Number(planDetails?.goal?.progress || 0) * 100
                        ).toFixed(
                          0
                        )}<span style="font-size: 12px ; margin-left: 2px;">%</span>
                      </span>
                    <span style="font-size: 17px; color: #737373; font-weight: 300; font-family: 'Inter', sans-serif; display: flex; align-items: center; justify-content: center; flex-direction: column;">Goal <br/> <span style="margin-top: -6px;">Progress</span></span>

                    </div>
                  </div>


                    <div style="position: relative; width: 165px; height: 100%;  display: flex; align-items: center; justify-content: center;">
                    <svg width="140" height="140" viewBox="0 0 100 100">
                      <!-- Background circle -->
                      <circle
                        cx="50"
                        cy="50"
                        r="45"
                        stroke="#E5E9EB"
                        stroke-width="6"
                        fill="none"
                      />
                      <!-- Progress circle -->
                      <circle
                        cx="50"
                        cy="50"
                        r="45"
                        stroke="#89C740"
                        stroke-width="6"
                        fill="none"
                        stroke-linecap="round"
                        stroke-dasharray="${2 * Math.PI * 45}"
                        stroke-dashoffset="${
                          2 *
                          Math.PI *
                          45 *
                          (1 - Number(planDetails?.plan_progress || 0))
                        }"
                        transform="rotate(-90 50 50)"
                      />
                    </svg>
                    <div style="
                      position: absolute;
                      display: flex;
                      flex-direction: column;
                      align-items: center;
                      justify-content: flex-start;
                       margin-top: 50px;
                      height: 100%;
                    ">
                        <span style="font-size: 24px; font-weight: 400; color: #000000; margin-bottom:2px;">
                        ${(
                          Number(planDetails?.plan_progress || 0) * 100
                        ).toFixed(
                          0
                        )}<span style="font-size: 12px ; margin-left: 2px;">%</span>
                      </span>
                      <span style="font-size: 17px; color: #737373; font-weight: 300; font-family: 'Inter', sans-serif; display: flex; align-items: center; justify-content: center; flex-direction: column;">Plan <br/> <span style="margin-top: -6px;">Progress</span></span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div>
        <table style="width: 100%; border-collapse: collapse; background: white; overflow: hidden; border: 1px solid #E5E9EB; box-shadow: 2px 4px 15px 0px rgba(0, 0, 0, 0.1); padding-right:20px">
          <tr style="background: white; border-bottom: 1px solid #E5E9EB; font-family: 'Inter', sans-serif; border-left: 6px solid #1C8B7E;">
            <th style="text-align: left; width: 70%; padding: 16px; padding-left: 24px; color: #171717; font-weight: 600; font-size: 22px;">Category & Action Items</th>
            <th style="text-align: left; width: 15%; padding: 16px; color: #171717; font-weight: 600; font-size: 22px;">Weight</th>
            <th style="text-align: left; width: 15%; padding: 16px; padding-right: 40px; color: #171717; font-weight: 600; font-size: 22px; white-space: nowrap;">Plan Progress</th>
          </tr>

          ${planDetails?.categories
            ?.map((category) => {
              const completedCount = getStatus(
                category?.action_items || [],
                true
              );

              const isCancel =
                category?.action_items?.length > 0
                  ? category?.action_items?.every((item) => item.status === 5)
                  : false;

              const planProgress = Number(
                (Number(category?.plan_progress) * 100).toFixed(2)
              );

              return `
            <!-- Category Row -->
            <tr style="border-bottom: 1px solid #E5E5E5; background: #F4F9FB; border-left: 6px solid #1C8B7E;">
              <td style="padding: 16px; padding-left: 24px; display: flex; gap: 16px; align-items: center; width:65%">
              <div>
                <div style="color: #171717; font-size: 22px; font-weight: 500; margin-bottom: 6px; font-family: 'Inter', sans-serif;">
                  ${category.name}
                </div>
                <div style="color: #737373; font-size: 20px; font-weight: 300; margin-top: -2px; font-family: 'Inter', sans-serif;">
                ${formatDate(category.start_line) || ""} - ${
                formatDate(category.dead_line) || ""
              }
                </div>
              </div>
                
              <div style="display: flex; align-items: center; justify-content: center; gap: 20px;">
                <span style="
                  display: inline-flex; 
                  align-items: center; 
                  justify-content: center;
                  border: 1px solid #71AACA;
                  border-radius: 12px; 
                  padding: 2px 8px;
                  margin-left: 8px; 
                  font-size: 16px; 
                  color: #404040;
                  font-weight: 500;
                  font-family: 'Inter', sans-serif;
                  min-width: 45px;
                  height: 24px;
                ">
                  <span style="
                    display: inline-flex;
                    align-items: center;
                    gap: 8px;
                  ">
                    <svg width="16" height="16" viewBox="0 0 16 16" style="margin-right: 2px;">
                      <circle 
                        cx="8" 
                        cy="8" 
                        r="7" 
                        fill="none" 
                        stroke="#E5E9EB" 
                        stroke-width="2"
                      />
                      <circle 
                        cx="8" 
                        cy="8" 
                        r="7" 
                        fill="none" 
                        stroke="#1F85CF" 
                        stroke-width="2"
                        stroke-dasharray="${2 * Math.PI * 7}"
                        stroke-dashoffset="${
                          2 *
                          Math.PI *
                          7 *
                          (1 - completedCount / category.action_items?.length)
                        }"
                        transform="rotate(-90 8 8)"
                      />
                    </svg>
                    ${completedCount}/${category.action_items?.length}
                  </span>
                </span>

                ${(() => {
                  const icons = {
                    cancel: `
                  <svg width="36" height="36" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M7.28154 13.346C7.15482 13.3463 7.03085 13.3089 6.92541 13.2387C6.81998 13.1684 6.73778 13.0683 6.6893 12.9512C6.64079 12.8342 6.62821 12.7053 6.65304 12.5811C6.67792 12.4568 6.73916 12.3427 6.82897 12.2533L12.2606 6.82173C12.32 6.7623 12.3905 6.71517 12.4682 6.68297C12.5458 6.65081 12.6291 6.63428 12.7131 6.63428C12.7972 6.63428 12.8804 6.65081 12.958 6.68297C13.0357 6.71517 13.1063 6.7623 13.1657 6.82173C13.2251 6.88116 13.2723 6.95172 13.3045 7.02938C13.3366 7.10704 13.3532 7.19026 13.3532 7.2743C13.3532 7.35835 13.3366 7.4416 13.3045 7.51926C13.2723 7.59689 13.2251 7.66745 13.1657 7.72687L7.73411 13.1585C7.67483 13.2181 7.60431 13.2654 7.52661 13.2976C7.44894 13.3298 7.36562 13.3463 7.28154 13.346Z" fill="#F00000"/>
<path d="M12.7132 13.346C12.6292 13.3461 12.5459 13.3296 12.4683 13.2974C12.3906 13.2652 12.32 13.218 12.2607 13.1585L6.82906 7.72687C6.76963 7.66745 6.72249 7.59689 6.69033 7.51926C6.65817 7.4416 6.6416 7.35835 6.6416 7.2743C6.6416 7.19026 6.65817 7.10704 6.69033 7.02938C6.72249 6.95172 6.76963 6.88116 6.82906 6.82173C6.88852 6.7623 6.95908 6.71517 7.0367 6.68297C7.11437 6.65081 7.19762 6.63428 7.28166 6.63428C7.36571 6.63428 7.44892 6.65081 7.52659 6.68297C7.60425 6.71517 7.67481 6.7623 7.73424 6.82173L13.1658 12.2533C13.2556 12.3427 13.3169 12.4568 13.3417 12.5811C13.3666 12.7053 13.354 12.8342 13.3055 12.9512C13.257 13.0683 13.1748 13.1684 13.0694 13.2387C12.9639 13.3089 12.84 13.3463 12.7132 13.346Z" fill="#F00000"/>
<path fill-rule="evenodd" clip-rule="evenodd" d="M10 16.6667C13.6819 16.6667 16.6667 13.6819 16.6667 10C16.6667 6.3181 13.6819 3.33333 10 3.33333C6.3181 3.33333 3.33333 6.3181 3.33333 10C3.33333 13.6819 6.3181 16.6667 10 16.6667ZM10 18C14.4183 18 18 14.4183 18 10C18 5.58172 14.4183 2 10 2C5.58172 2 2 5.58172 2 10C2 14.4183 5.58172 18 10 18Z" fill="#F00000" fill-opacity="0.3"/>
<path d="M16.6667 10C16.6667 13.6819 13.6819 16.6667 10 16.6667C6.3181 16.6667 3.33333 13.6819 3.33333 10C3.33333 6.3181 6.3181 3.33333 10 3.33333C13.6819 3.33333 16.6667 6.3181 16.6667 10Z" fill="#F00000" fill-opacity="0.3"/>
</svg>
`,
                    noProgress: `
                   <svg width="36" height="36" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M7.38086 7.14317C7.38086 6.88018 7.59405 6.66699 7.85704 6.66699H13.8093C14.0723 6.66699 14.2855 6.88018 14.2855 7.14317C14.2855 7.40617 14.0723 7.61935 13.8093 7.61935H7.85704C7.59405 7.61935 7.38086 7.40617 7.38086 7.14317Z" fill="#FF9900" />
<path d="M7.38086 9.99962C7.38086 9.73662 7.59405 9.52344 7.85704 9.52344H13.8093C14.0723 9.52344 14.2855 9.73662 14.2855 9.99962C14.2855 10.2626 14.0723 10.4758 13.8093 10.4758H7.85704C7.59405 10.4758 7.38086 10.2626 7.38086 9.99962Z" fill="#FF9900"/>
<path d="M7.38086 12.857C7.38086 12.594 7.59405 12.3809 7.85704 12.3809H13.8093C14.0723 12.3809 14.2855 12.594 14.2855 12.857C14.2855 13.12 14.0723 13.3332 13.8093 13.3332H7.85704C7.59405 13.3332 7.38086 13.12 7.38086 12.857Z" fill="#FF9900"/>
<path d="M6.19005 7.61935C6.45304 7.61935 6.66623 7.40617 6.66623 7.14317C6.66623 6.88018 6.45304 6.66699 6.19005 6.66699C5.92705 6.66699 5.71387 6.88018 5.71387 7.14317C5.71387 7.40617 5.92705 7.61935 6.19005 7.61935Z" fill="#FF9900"/>
<path d="M6.66623 9.99962C6.66623 10.2626 6.45304 10.4758 6.19005 10.4758C5.92705 10.4758 5.71387 10.2626 5.71387 9.99962C5.71387 9.73662 5.92705 9.52344 6.19005 9.52344C6.45304 9.52344 6.66623 9.73662 6.66623 9.99962Z" fill="#FF9900"/>
<path d="M6.19005 13.3332C6.45304 13.3332 6.66623 13.12 6.66623 12.857C6.66623 12.594 6.45304 12.3809 6.19005 12.3809C5.92705 12.3809 5.71387 12.594 5.71387 12.857C5.71387 13.12 5.92705 13.3332 6.19005 13.3332Z" fill="#FF9900"/>
<path fill-rule="evenodd" clip-rule="evenodd" d="M10 16.6667C13.6819 16.6667 16.6667 13.6819 16.6667 10C16.6667 6.3181 13.6819 3.33333 10 3.33333C6.3181 3.33333 3.33333 6.3181 3.33333 10C3.33333 13.6819 6.3181 16.6667 10 16.6667ZM10 18C14.4183 18 18 14.4183 18 10C18 5.58172 14.4183 2 10 2C5.58172 2 2 5.58172 2 10C2 14.4183 5.58172 18 10 18Z" fill="#FF9900" fill-opacity="0.3"/>
<path d="M16.6667 10C16.6667 13.6819 13.6819 16.6667 10 16.6667C6.3181 16.6667 3.33333 13.6819 3.33333 10C3.33333 6.3181 6.3181 3.33333 10 3.33333C13.6819 3.33333 16.6667 6.3181 16.6667 10Z" fill="#FF9900" fill-opacity="0.3"/>
</svg>
`,
                    inProgress: `
                    <svg width="36" height="36" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
<circle cx="10" cy="10" r="8" fill="#A2BFCF" fill-opacity="0.3"/>
<path fill-rule="evenodd" clip-rule="evenodd" d="M6.91041 3.31392C7.09477 3.63263 6.98586 4.04045 6.66715 4.22481C5.75769 4.7509 4.98365 5.48594 4.41107 6.36411C4.20997 6.67254 3.79693 6.75955 3.48851 6.55845C3.18008 6.35735 3.09308 5.94431 3.29417 5.63589C3.98072 4.58292 4.90848 3.70179 5.99951 3.07067C6.31822 2.8863 6.72604 2.99521 6.91041 3.31392ZM3.0078 7.68397C3.36643 7.76732 3.5896 8.12561 3.50625 8.48425C3.39323 8.97053 3.33333 9.47787 3.33333 10C3.33333 10.5221 3.39323 11.0295 3.50625 11.5157C3.5896 11.8744 3.36643 12.2327 3.0078 12.316C2.64917 12.3994 2.29088 12.1762 2.20753 11.8176C2.07167 11.233 2 10.6245 2 10C2 9.37553 2.07167 8.767 2.20753 8.18242C2.29088 7.82379 2.64917 7.60063 3.0078 7.68397ZM3.48851 13.4415C3.79693 13.2405 4.20997 13.3275 4.41107 13.6359C4.98365 14.5141 5.75769 15.2491 6.66715 15.7752C6.98586 15.9595 7.09477 16.3674 6.91041 16.6861C6.72604 17.0048 6.31822 17.1137 5.99951 16.9293C4.90848 16.2982 3.98072 15.4171 3.29417 14.3641C3.09308 14.0557 3.18008 13.6427 3.48851 13.4415Z" fill="#1271A6" fill-opacity="0.6"/>
<path d="M13.7913 7.5155C14.0589 7.76841 14.0707 8.19035 13.8179 8.45793L9.52913 12.9955C9.10333 13.4459 8.39664 13.4459 7.97087 12.9955L6.18217 11.103C5.92925 10.8354 5.94115 10.4135 6.20873 10.1605C6.47632 9.90767 6.89826 9.91953 7.15117 10.1871L8.75 11.8787L12.8488 7.54207C13.1017 7.27449 13.5237 7.26259 13.7913 7.5155Z" fill="#1271A6" fill-opacity="0.6"/>
<path d="M8.54623 2.13189C9.01813 2.0452 9.50407 2 10 2C14.4183 2 18 5.58172 18 10C18 14.4183 14.4183 18 10 18C9.50407 18 9.01813 17.9548 8.54623 17.8681C8.18409 17.8016 7.94445 17.4541 8.01097 17.092C8.07749 16.7299 8.42498 16.4902 8.78713 16.5567C9.17993 16.6289 9.58527 16.6667 10 16.6667C13.6819 16.6667 16.6667 13.6819 16.6667 10C16.6667 6.3181 13.6819 3.33333 10 3.33333C9.58527 3.33333 9.17993 3.37113 8.78713 3.44328C8.42498 3.5098 8.07749 3.27016 8.01097 2.90803C7.94445 2.54589 8.18409 2.19841 8.54623 2.13189Z" fill="#1271A6" fill-opacity="0.6"/>
<path d="M13.7913 7.51518C14.0589 7.76808 14.0707 8.19002 13.8179 8.45761L9.52913 12.9951C9.10333 13.4456 8.39664 13.4456 7.97087 12.9951L6.18217 11.1027C5.92925 10.8351 5.94115 10.4131 6.20873 10.1602C6.47632 9.90734 6.89826 9.91921 7.15117 10.1868L8.75 11.8784L12.8488 7.54174C13.1017 7.27416 13.5237 7.26226 13.7913 7.51518Z" fill="#1271A6"/>
</svg>
`,
                    completed: `
                      <svg width="36" height="36" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
<path fill-rule="evenodd" clip-rule="evenodd" d="M13.5021 7.50357C13.7699 7.77136 13.7699 8.2055 13.5021 8.47329L9.47923 12.4962C9.21144 12.7639 8.77733 12.7639 8.50954 12.4962L6.58951 10.5761C6.32173 10.3084 6.32173 9.87425 6.58951 9.60646C6.8573 9.33866 7.29147 9.33866 7.55926 9.60646L8.99438 11.0415L12.5324 7.50357C12.8002 7.23579 13.2343 7.23579 13.5021 7.50357Z" fill="#59A310"/>
<path fill-rule="evenodd" clip-rule="evenodd" d="M10 16.6667C13.6819 16.6667 16.6667 13.6819 16.6667 10C16.6667 6.3181 13.6819 3.33333 10 3.33333C6.3181 3.33333 3.33333 6.3181 3.33333 10C3.33333 13.6819 6.3181 16.6667 10 16.6667ZM10 18C14.4183 18 18 14.4183 18 10C18 5.58172 14.4183 2 10 2C5.58172 2 2 5.58172 2 10C2 14.4183 5.58172 18 10 18Z" fill="#59A310" fill-opacity="0.3"/>
<path d="M16.6667 10C16.6667 13.6819 13.6819 16.6667 10 16.6667C6.3181 16.6667 3.33333 13.6819 3.33333 10C3.33333 6.3181 6.3181 3.33333 10 3.33333C13.6819 3.33333 16.6667 6.3181 16.6667 10Z" fill="#59A310" fill-opacity="0.3"/>
</svg>
`,
                  };

                  if (isCancel) return icons.cancel;
                  if (planProgress === 0) return icons.noProgress;
                  if (planProgress < 100) return icons.inProgress;
                  if (planProgress === 100) return icons.completed;
                  return icons.noProgress;
                })()}
                    
              </div>

              </td>


              <td style="text-align: left; padding: 16px; color: #06203a; font-size: 20px; width: 15%">
                ${`${(Number(category.weight || 0) * 100).toFixed(0)}%` || 0}
              </td>
              <td style="text-align: left; padding: 16px; color: #06203a; font-size: 20px; width: 15%">
                ${
                  `${(Number(category.plan_progress || 0) * 100).toFixed(
                    0
                  )}%` || 0
                }
              </td>
            </tr>

            <!-- Action Items within Category -->
            ${category.action_items
              ?.map(
                (item) => `
                   <tr style="border-bottom: 1px solid #E5E5E5; border-left: 6px solid #1C8B7E;">
              <td style="padding: 16px; padding-left: 24px; display: flex; gap: 20px; align-items: center; width: 65%; font-family: 'Inter', sans-serif;">
              <div>
                <div style="color: #171717; font-size: 22px; font-weight: 400; margin-bottom: 6px; font-family: 'Inter', sans-serif;">
                  ${item.name}
                </div>
                <div style="color: #737373; font-size: 19px; font-weight: 300; margin-top: -2px; font-family: 'Inter', sans-serif;">
              ${
                item?.responsible_person?.first_name
                  ? `${item?.responsible_person?.first_name} ${item?.responsible_person?.last_name}`
                  : `${item?.responsible_person?.name}`
              } •
                ${formatDate(item.start_line) || ""} - ${
                  formatDate(item.dead_line) || ""
                }
                </div>
              </div>
              ${(() => {
                switch (item.status) {
                  case 5: // Cancelled
                    return `<svg width="36" height="36" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M7.28154 13.346C7.15482 13.3463 7.03085 13.3089 6.92541 13.2387C6.81998 13.1684 6.73778 13.0683 6.6893 12.9512C6.64079 12.8342 6.62821 12.7053 6.65304 12.5811C6.67792 12.4568 6.73916 12.3427 6.82897 12.2533L12.2606 6.82173C12.32 6.7623 12.3905 6.71517 12.4682 6.68297C12.5458 6.65081 12.6291 6.63428 12.7131 6.63428C12.7972 6.63428 12.8804 6.65081 12.958 6.68297C13.0357 6.71517 13.1063 6.7623 13.1657 6.82173C13.2251 6.88116 13.2723 6.95172 13.3045 7.02938C13.3366 7.10704 13.3532 7.19026 13.3532 7.2743C13.3532 7.35835 13.3366 7.4416 13.3045 7.51926C13.2723 7.59689 13.2251 7.66745 13.1657 7.72687L7.73411 13.1585C7.67483 13.2181 7.60431 13.2654 7.52661 13.2976C7.44894 13.3298 7.36562 13.3463 7.28154 13.346Z" fill="#F00000"/>
<path d="M12.7132 13.346C12.6292 13.3461 12.5459 13.3296 12.4683 13.2974C12.3906 13.2652 12.32 13.218 12.2607 13.1585L6.82906 7.72687C6.76963 7.66745 6.72249 7.59689 6.69033 7.51926C6.65817 7.4416 6.6416 7.35835 6.6416 7.2743C6.6416 7.19026 6.65817 7.10704 6.69033 7.02938C6.72249 6.95172 6.76963 6.88116 6.82906 6.82173C6.88852 6.7623 6.95908 6.71517 7.0367 6.68297C7.11437 6.65081 7.19762 6.63428 7.28166 6.63428C7.36571 6.63428 7.44892 6.65081 7.52659 6.68297C7.60425 6.71517 7.67481 6.7623 7.73424 6.82173L13.1658 12.2533C13.2556 12.3427 13.3169 12.4568 13.3417 12.5811C13.3666 12.7053 13.354 12.8342 13.3055 12.9512C13.257 13.0683 13.1748 13.1684 13.0694 13.2387C12.9639 13.3089 12.84 13.3463 12.7132 13.346Z" fill="#F00000"/>
<path fill-rule="evenodd" clip-rule="evenodd" d="M10 16.6667C13.6819 16.6667 16.6667 13.6819 16.6667 10C16.6667 6.3181 13.6819 3.33333 10 3.33333C6.3181 3.33333 3.33333 6.3181 3.33333 10C3.33333 13.6819 6.3181 16.6667 10 16.6667ZM10 18C14.4183 18 18 14.4183 18 10C18 5.58172 14.4183 2 10 2C5.58172 2 2 5.58172 2 10C2 14.4183 5.58172 18 10 18Z" fill="#F00000" fill-opacity="0.3"/>
<path d="M16.6667 10C16.6667 13.6819 13.6819 16.6667 10 16.6667C6.3181 16.6667 3.33333 13.6819 3.33333 10C3.33333 6.3181 6.3181 3.33333 10 3.33333C13.6819 3.33333 16.6667 6.3181 16.6667 10Z" fill="#F00000" fill-opacity="0.3"/>
</svg>`;

                  case 1: // To Do
                    return ` <svg width="36" height="36" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M7.38086 7.14317C7.38086 6.88018 7.59405 6.66699 7.85704 6.66699H13.8093C14.0723 6.66699 14.2855 6.88018 14.2855 7.14317C14.2855 7.40617 14.0723 7.61935 13.8093 7.61935H7.85704C7.59405 7.61935 7.38086 7.40617 7.38086 7.14317Z" fill="#FF9900" />
<path d="M7.38086 9.99962C7.38086 9.73662 7.59405 9.52344 7.85704 9.52344H13.8093C14.0723 9.52344 14.2855 9.73662 14.2855 9.99962C14.2855 10.2626 14.0723 10.4758 13.8093 10.4758H7.85704C7.59405 10.4758 7.38086 10.2626 7.38086 9.99962Z" fill="#FF9900"/>
<path d="M7.38086 12.857C7.38086 12.594 7.59405 12.3809 7.85704 12.3809H13.8093C14.0723 12.3809 14.2855 12.594 14.2855 12.857C14.2855 13.12 14.0723 13.3332 13.8093 13.3332H7.85704C7.59405 13.3332 7.38086 13.12 7.38086 12.857Z" fill="#FF9900"/>
<path d="M6.19005 7.61935C6.45304 7.61935 6.66623 7.40617 6.66623 7.14317C6.66623 6.88018 6.45304 6.66699 6.19005 6.66699C5.92705 6.66699 5.71387 6.88018 5.71387 7.14317C5.71387 7.40617 5.92705 7.61935 6.19005 7.61935Z" fill="#FF9900"/>
<path d="M6.66623 9.99962C6.66623 10.2626 6.45304 10.4758 6.19005 10.4758C5.92705 10.4758 5.71387 10.2626 5.71387 9.99962C5.71387 9.73662 5.92705 9.52344 6.19005 9.52344C6.45304 9.52344 6.66623 9.73662 6.66623 9.99962Z" fill="#FF9900"/>
<path d="M6.19005 13.3332C6.45304 13.3332 6.66623 13.12 6.66623 12.857C6.66623 12.594 6.45304 12.3809 6.19005 12.3809C5.92705 12.3809 5.71387 12.594 5.71387 12.857C5.71387 13.12 5.92705 13.3332 6.19005 13.3332Z" fill="#FF9900"/>
<path fill-rule="evenodd" clip-rule="evenodd" d="M10 16.6667C13.6819 16.6667 16.6667 13.6819 16.6667 10C16.6667 6.3181 13.6819 3.33333 10 3.33333C6.3181 3.33333 3.33333 6.3181 3.33333 10C3.33333 13.6819 6.3181 16.6667 10 16.6667ZM10 18C14.4183 18 18 14.4183 18 10C18 5.58172 14.4183 2 10 2C5.58172 2 2 5.58172 2 10C2 14.4183 5.58172 18 10 18Z" fill="#FF9900" fill-opacity="0.3"/>
<path d="M16.6667 10C16.6667 13.6819 13.6819 16.6667 10 16.6667C6.3181 16.6667 3.33333 13.6819 3.33333 10C3.33333 6.3181 6.3181 3.33333 10 3.33333C13.6819 3.33333 16.6667 6.3181 16.6667 10Z" fill="#FF9900" fill-opacity="0.3"/>
</svg>`;

                  case 2: // In Progress
                    return ` <svg width="36" height="36" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
<circle cx="10" cy="10" r="8" fill="#A2BFCF" fill-opacity="0.3"/>
<path fill-rule="evenodd" clip-rule="evenodd" d="M6.91041 3.31392C7.09477 3.63263 6.98586 4.04045 6.66715 4.22481C5.75769 4.7509 4.98365 5.48594 4.41107 6.36411C4.20997 6.67254 3.79693 6.75955 3.48851 6.55845C3.18008 6.35735 3.09308 5.94431 3.29417 5.63589C3.98072 4.58292 4.90848 3.70179 5.99951 3.07067C6.31822 2.8863 6.72604 2.99521 6.91041 3.31392ZM3.0078 7.68397C3.36643 7.76732 3.5896 8.12561 3.50625 8.48425C3.39323 8.97053 3.33333 9.47787 3.33333 10C3.33333 10.5221 3.39323 11.0295 3.50625 11.5157C3.5896 11.8744 3.36643 12.2327 3.0078 12.316C2.64917 12.3994 2.29088 12.1762 2.20753 11.8176C2.07167 11.233 2 10.6245 2 10C2 9.37553 2.07167 8.767 2.20753 8.18242C2.29088 7.82379 2.64917 7.60063 3.0078 7.68397ZM3.48851 13.4415C3.79693 13.2405 4.20997 13.3275 4.41107 13.6359C4.98365 14.5141 5.75769 15.2491 6.66715 15.7752C6.98586 15.9595 7.09477 16.3674 6.91041 16.6861C6.72604 17.0048 6.31822 17.1137 5.99951 16.9293C4.90848 16.2982 3.98072 15.4171 3.29417 14.3641C3.09308 14.0557 3.18008 13.6427 3.48851 13.4415Z" fill="#1271A6" fill-opacity="0.6"/>
<path d="M13.7913 7.5155C14.0589 7.76841 14.0707 8.19035 13.8179 8.45793L9.52913 12.9955C9.10333 13.4459 8.39664 13.4459 7.97087 12.9955L6.18217 11.103C5.92925 10.8354 5.94115 10.4135 6.20873 10.1605C6.47632 9.90767 6.89826 9.91953 7.15117 10.1871L8.75 11.8787L12.8488 7.54207C13.1017 7.27449 13.5237 7.26259 13.7913 7.5155Z" fill="#1271A6" fill-opacity="0.6"/>
<path d="M8.54623 2.13189C9.01813 2.0452 9.50407 2 10 2C14.4183 2 18 5.58172 18 10C18 14.4183 14.4183 18 10 18C9.50407 18 9.01813 17.9548 8.54623 17.8681C8.18409 17.8016 7.94445 17.4541 8.01097 17.092C8.07749 16.7299 8.42498 16.4902 8.78713 16.5567C9.17993 16.6289 9.58527 16.6667 10 16.6667C13.6819 16.6667 16.6667 13.6819 16.6667 10C16.6667 6.3181 13.6819 3.33333 10 3.33333C9.58527 3.33333 9.17993 3.37113 8.78713 3.44328C8.42498 3.5098 8.07749 3.27016 8.01097 2.90803C7.94445 2.54589 8.18409 2.19841 8.54623 2.13189Z" fill="#1271A6" fill-opacity="0.6"/>
<path d="M13.7913 7.51518C14.0589 7.76808 14.0707 8.19002 13.8179 8.45761L9.52913 12.9951C9.10333 13.4456 8.39664 13.4456 7.97087 12.9951L6.18217 11.1027C5.92925 10.8351 5.94115 10.4131 6.20873 10.1602C6.47632 9.90734 6.89826 9.91921 7.15117 10.1868L8.75 11.8784L12.8488 7.54174C13.1017 7.27416 13.5237 7.26226 13.7913 7.51518Z" fill="#1271A6"/>
</svg>`;

                  case 3: // On Hold
                    return `<svg width="36" height="36" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
<path fill-rule="evenodd" clip-rule="evenodd" d="M10.9307 7.02298V12.9765C10.9307 13.2846 11.1807 13.5346 11.4888 13.5346C11.7969 13.5346 12.0469 13.2846 12.0469 12.9765V7.02298C12.0469 6.71489 11.7969 6.46484 11.4888 6.46484C11.1807 6.46484 10.9307 6.71489 10.9307 7.02298Z" fill="#737373"/>
<path fill-rule="evenodd" clip-rule="evenodd" d="M7.95361 7.02298V12.9765C7.95361 13.2846 8.20366 13.5346 8.51175 13.5346C8.81985 13.5346 9.06989 13.2846 9.06989 12.9765V7.02298C9.06989 6.71489 8.81985 6.46484 8.51175 6.46484C8.20366 6.46484 7.95361 6.71489 7.95361 7.02298Z" fill="#737373"/>
<path fill-rule="evenodd" clip-rule="evenodd" d="M10 3.33333C13.6819 3.33333 16.6667 6.3181 16.6667 10C16.6667 13.6819 13.6819 16.6667 10 16.6667C6.3181 16.6667 3.33333 13.6819 3.33333 10C3.33333 6.3181 6.3181 3.33333 10 3.33333ZM10 2C14.4183 2 18 5.58172 18 10C18 14.4183 14.4183 18 10 18C5.58172 18 2 14.4183 2 10C2 5.58172 5.58172 2 10 2Z" fill="#737373" fill-opacity="0.3"/>
<path d="M16.6667 10C16.6667 6.3181 13.6819 3.33333 10 3.33333C6.3181 3.33333 3.33333 6.3181 3.33333 10C3.33333 13.6819 6.3181 16.6667 10 16.6667C13.6819 16.6667 16.6667 13.6819 16.6667 10Z" fill="#737373" fill-opacity="0.3"/>
</svg>
`;

                  case 4: // Completed
                    return ` <svg width="36" height="36" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
<path fill-rule="evenodd" clip-rule="evenodd" d="M13.5021 7.50357C13.7699 7.77136 13.7699 8.2055 13.5021 8.47329L9.47923 12.4962C9.21144 12.7639 8.77733 12.7639 8.50954 12.4962L6.58951 10.5761C6.32173 10.3084 6.32173 9.87425 6.58951 9.60646C6.8573 9.33866 7.29147 9.33866 7.55926 9.60646L8.99438 11.0415L12.5324 7.50357C12.8002 7.23579 13.2343 7.23579 13.5021 7.50357Z" fill="#59A310"/>
<path fill-rule="evenodd" clip-rule="evenodd" d="M10 16.6667C13.6819 16.6667 16.6667 13.6819 16.6667 10C16.6667 6.3181 13.6819 3.33333 10 3.33333C6.3181 3.33333 3.33333 6.3181 3.33333 10C3.33333 13.6819 6.3181 16.6667 10 16.6667ZM10 18C14.4183 18 18 14.4183 18 10C18 5.58172 14.4183 2 10 2C5.58172 2 2 5.58172 2 10C2 14.4183 5.58172 18 10 18Z" fill="#59A310" fill-opacity="0.3"/>
<path d="M16.6667 10C16.6667 13.6819 13.6819 16.6667 10 16.6667C6.3181 16.6667 3.33333 13.6819 3.33333 10C3.33333 6.3181 6.3181 3.33333 10 3.33333C13.6819 3.33333 16.6667 6.3181 16.6667 10Z" fill="#59A310" fill-opacity="0.3"/>
</svg>`;

                  default: // Default to To Do
                    return `<svg width="36" height="36" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M7.38086 7.14317C7.38086 6.88018 7.59405 6.66699 7.85704 6.66699H13.8093C14.0723 6.66699 14.2855 6.88018 14.2855 7.14317C14.2855 7.40617 14.0723 7.61935 13.8093 7.61935H7.85704C7.59405 7.61935 7.38086 7.40617 7.38086 7.14317Z" fill="#FF9900" />
<path d="M7.38086 9.99962C7.38086 9.73662 7.59405 9.52344 7.85704 9.52344H13.8093C14.0723 9.52344 14.2855 9.73662 14.2855 9.99962C14.2855 10.2626 14.0723 10.4758 13.8093 10.4758H7.85704C7.59405 10.4758 7.38086 10.2626 7.38086 9.99962Z" fill="#FF9900"/>
<path d="M7.38086 12.857C7.38086 12.594 7.59405 12.3809 7.85704 12.3809H13.8093C14.0723 12.3809 14.2855 12.594 14.2855 12.857C14.2855 13.12 14.0723 13.3332 13.8093 13.3332H7.85704C7.59405 13.3332 7.38086 13.12 7.38086 12.857Z" fill="#FF9900"/>
<path d="M6.19005 7.61935C6.45304 7.61935 6.66623 7.40617 6.66623 7.14317C6.66623 6.88018 6.45304 6.66699 6.19005 6.66699C5.92705 6.66699 5.71387 6.88018 5.71387 7.14317C5.71387 7.40617 5.92705 7.61935 6.19005 7.61935Z" fill="#FF9900"/>
<path d="M6.66623 9.99962C6.66623 10.2626 6.45304 10.4758 6.19005 10.4758C5.92705 10.4758 5.71387 10.2626 5.71387 9.99962C5.71387 9.73662 5.92705 9.52344 6.19005 9.52344C6.45304 9.52344 6.66623 9.73662 6.66623 9.99962Z" fill="#FF9900"/>
<path d="M6.19005 13.3332C6.45304 13.3332 6.66623 13.12 6.66623 12.857C6.66623 12.594 6.45304 12.3809 6.19005 12.3809C5.92705 12.3809 5.71387 12.594 5.71387 12.857C5.71387 13.12 5.92705 13.3332 6.19005 13.3332Z" fill="#FF9900"/>
<path fill-rule="evenodd" clip-rule="evenodd" d="M10 16.6667C13.6819 16.6667 16.6667 13.6819 16.6667 10C16.6667 6.3181 13.6819 3.33333 10 3.33333C6.3181 3.33333 3.33333 6.3181 3.33333 10C3.33333 13.6819 6.3181 16.6667 10 16.6667ZM10 18C14.4183 18 18 14.4183 18 10C18 5.58172 14.4183 2 10 2C5.58172 2 2 5.58172 2 10C2 14.4183 5.58172 18 10 18Z" fill="#FF9900" fill-opacity="0.3"/>
<path d="M16.6667 10C16.6667 13.6819 13.6819 16.6667 10 16.6667C6.3181 16.6667 3.33333 13.6819 3.33333 10C3.33333 6.3181 6.3181 3.33333 10 3.33333C13.6819 3.33333 16.6667 6.3181 16.6667 10Z" fill="#FF9900" fill-opacity="0.3"/>
</svg>`;
                }
              })()}
                
                </td>

                <td style="text-align: left; padding: 16px; color: #06203a; font-size: 20px; width: 15%">
                  ${item.weight || "0"}
                </td>
                <td style="text-align: left; padding: 16px; color: #06203a; font-size: 20px; width: 15%">
                  ${item.plan_progress || "0"}
                </td>
              </tr>
            `
              )
              .join("")}
          `;
            })
            .join("")}
        </table>
      </div>
       <!-- Footer -->
    <div style="
      padding: 20px 32px;
      display: flex;
      justify-content: space-between;
      align-items: center;
      margin-top: 20px;
    ">
      <!-- Left: Name -->
      <div style="
        color: #A3A3A3;
        font-size: 14px;
        font-weight: 400;
      ">
      </div>

      <!-- Center: Page Count -->
      <div style="
        color: #A3A3A3;
        font-size: 14px;
        font-weight: 400;
      " class="page-counter">
      </div>

      <!-- Right: Export Date -->
      <div style="
        color: #A3A3A3;
        font-size: 14px;
        font-weight: 400;
      ">
        Exported: <span style="font-weight: 500; font-family: 'Inter', sans-serif;">
        ${new Date().toLocaleDateString("en-US", {
          month: "2-digit",
          day: "2-digit",
          year: "numeric",
        })}</span>
      </div>
    </div>
    </div>
    
    </div>

   

`;
