import React, { useState, useEffect} from 'react';
import { Dropdown, Menu, Space } from 'antd';
import { DownOutlined, RightOutlined } from '@ant-design/icons';

import { ReactComponent as PencilIcon } from "../../../../assets/svg/pencil.svg";
// @ts-ignore
import { getPredefinedMetrics } from "../../../../modules/actions/PlanActions";

import { useSelector, useDispatch } from 'react-redux';
// @ts-ignore
import classes from './styled.module.scss';

interface CustomSelectProps {
  onAddMetric: () => void;
  selectedValue: any;
  onValueChange: any;
  selectedUnit: any;
  setIsAddMetric: any;
}

const SelectMetric: React.FC<CustomSelectProps> = ({
  onAddMetric,
  selectedValue,
  onValueChange,
  selectedUnit,
  setIsAddMetric,

}) => {
  const dispatch = useDispatch()
  const { planDetails } = useSelector((state: any) => state.plan.plan);
  const getMetric = useSelector((state: any) => state?.plan?.predefinedMetrics)

  const [isDropdownOpen, setIsDropdownOpen] = useState(false);

  useEffect(()=>{
    dispatch(getPredefinedMetrics())
  },[])

  const capitalizeFirstLetter = (word:string) => {
    return word.charAt(0).toUpperCase() + word.slice(1);
  };


  const transformedItems = Array.isArray(getMetric)
  ? getMetric.map((item:any) => ({
      key: item?.label,
      label: capitalizeFirstLetter(item?.label),
      children: Array.isArray(item.metric_units)
        ? item.metric_units.map((unit:any) => ({
            key: unit?.name,
            label: unit?.name,
            onClick: () => {
              onValueChange({
                label: item?.label,
                unit: unit?.name,
                id: unit?.id,
              });
              setIsDropdownOpen(false);
            },
          }))
        : [],
    }))
  : [];

  const transformedLabels = planDetails?.goal?.custom_labels?.map((label: any) => ({
    key: label.id,
    label: label.value,
    unitId: label.id,


    children: label.custom_unit_options.map((item: any) => ({
      key: item.id,
      label: item.value,
      onClick: () => {
        onValueChange({
          label: label?.value,
          unit: item?.value,
          unitId: item?.id
        });
        setIsDropdownOpen(false);
      },
    })),
  })) || [];

  const handleAddMetricClick = (event: any) => {
    event.stopPropagation();
    onAddMetric();
    setIsDropdownOpen(false);
  };

  const menuItems = [
    ...transformedItems,
    transformedLabels.length > 0 && {
      key: 'customMetricsHeading',

      label: (
        <div style={{ display: "flex", justifyContent: "space-between", padding: 0 }}>

          <span style={{ color: '#112538', fontSize: 10, fontWeight: 'bold', cursor: 'default', }}>
            Custom Metrics
          </span>
          <PencilIcon
            style={{ marginRight: "-10px" }}
            className="cursor-pointer"
            onClick={(e: any) => { handleAddMetricClick(e), setIsAddMetric(true) }}
          />
        </div>
      ),
      type: 'group',

    },
    ...transformedLabels.map((unit: any) => ({
      key: unit.key,
      label: unit.label,
      children: unit.children,
    })),
  ];

  return (
    <Dropdown
      overlay={<Menu items={menuItems} />}
      trigger={['click']}
      placement="bottomLeft"

      open={isDropdownOpen}
      overlayClassName={classes.customDropdownMenu}
      onOpenChange={(open) => setIsDropdownOpen(open)}
      dropdownRender={(menu) => (
        <>
          <div className={classes.inner}>
            {menu}
          </div>
          <div
            className={classes.lastItem}
          // style={{
          //   textAlign: "end", backgroundColor: "#fff",
          //   borderRadius: "2px",
          //   outline: "none",
          //   paddingRight: "5px",
          //   width: "120px"
          // }}
          >
            <span onClick={handleAddMetricClick} style={{ color: '#1271A6', cursor: 'pointer', textAlign: "right" }}>
              + Add Metric
            </span>
          </div>

        </>
      )}
    >
      <a onClick={(e) => e.preventDefault()}>
        <Space className={classes.dropdownTrigger}>
          {selectedUnit || selectedValue?.unit || '--'} <DownOutlined />
        </Space>
      </a>
    </Dropdown>
  );
};

export default SelectMetric;

