import { useState, FC, useEffect, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Accordion } from "react-bootstrap";
import moment, { Moment } from "moment";
// @ts-ignore
import { getProjectOwners } from "../../../modules/actions/UserActions";


import {
  Wrapper,
  Head,
  HeadItem,
  Body,
  CustomizeHeader,
  CustomizePopover,
  CustomizeItem,
  CustomizeItems,
  Container,
  AIGenerationLoading,
  CardViewWrapper,
  CustomizeSubHeader,
  BultAction,
  BulkActionLoading,
  ModelContent,
  ModalHeader,
  ModalBody,
  ModalFooter,
} from "./styled";

import Category, { AddCategory } from "./Category";
// @ts-ignore
import WeightModel from "../WeightModel/WeightModel";

import { IPlan } from "./@types";

// @ts-ignore
import { changeCategoryOrder } from "modules/actions/PlanActions";

// import expandIcon from './expand.svg';
import {
  DragDropContext,
  Draggable,
  DropResult,
  Droppable,
} from "react-beautiful-dnd";
import { ReactComponent as ExpandIcon } from "./expand.svg";
import { ReactComponent as PlusIcon } from "./plus.svg";
import { ReactComponent as Setting } from "assets/svg/setting.svg";
import { ReactComponent as CloseIcon } from "assets/svg/close.svg";
import { ReactComponent as Dragding } from "assets/svg/draging.svg";
import { ReactComponent as SettingGray } from "assets/svg/settingGray.svg";

import { ReactComponent as StarIcon } from "assets/svg/starGray.svg";
import { ReactComponent as PlanProgressIcon } from "assets/svg/planProgress.svg";
import { ReactComponent as DurationIcon } from "assets/svg/duration.svg";
import { ReactComponent as AssigneeIcon } from "assets/svg/assignee.svg";
import { ReactComponent as BellIcon } from "assets/svg/bell.svg";
import { ReactComponent as CalendarIcon } from "assets/svg/calanderGray.svg";
import { ReactComponent as DeleteIcon } from "assets/svg/bin.svg";
import { ReactComponent as UndoIcon } from "assets/svg/undo.svg";
import { ReactComponent as Cross } from "assets/svg/closeBox.svg";
import { ReactComponent as Info } from "assets/svg/info.svg";

// @ts-ignore
import {
  createPlanCategory,
  updateCustomizeColumn,
  updateBulkAction,
  // @ts-ignore
} from "modules/actions/PlanActions";
import { DatePicker, Modal, Popover, Select, Switch } from "antd";

// @ts-ignore
// @ts-ignore
import StarsLoading from "../../../assets/svg/yellow-stars.svg";
// @ts-ignore
import LoadingGif from "../../../assets/gifs/assistant-loader.gif";
import { ActionItemsModal } from "../../../components/ActionItemsModal";
import ActionItemCardView from "../../ProjectPlanner/ActionItemCardView";
import {
  actionItemWeightOptions,
  defaultColumns,
} from "../../ProjectPlanner/components/GoalSettings/data";
import { durationList } from "../../../constants";
import { LoadingOutlined } from "@ant-design/icons";

interface IActionItems {
  planDetails: IPlan;
  isShared: boolean;
  isShort: boolean;
  isExpandAllNotes: boolean;
  isPlanAIGenerated: boolean;
  isAIGeneratedPlanLoading: boolean;
  isListView: boolean;
  filterBy: any;
}

const ActionItems: FC<IActionItems> = ({
  planDetails,
  isShared,
  isShort,
  isExpandAllNotes,
  isAIGeneratedPlanLoading,
  isListView,
  filterBy,
}) => {
  const dispatch = useDispatch();
  const mainContainer = useRef<HTMLDivElement>(null);
  const {
    customizeColumn,
    // @ts-ignore
  } = useSelector((state) => state.plan.plan);

  // @ts-ignore
  const { users: planUsers } = useSelector((state) => state.user.projectOwners);

  // @ts-ignore
  const { weightModel } = useSelector((state) => state.plan.plan);

  const [activeKey, setActiveKey] = useState<
    { id: string; isExpand: boolean }[]
  >([]);

  const [isAddCategory, setIsAddCategory] = useState(false);
  const [isCustomizePopoverActive, setIsCustomizePopoverActive] =
    useState(false);
  const [isNoteModel, setIsNoteModel] = useState(false);
  const [data, SetData] = useState<any>();
  const [isNewCategoryLoading, setIsNewCategoryLoading] = useState(false);
  const [isResetEnabled, setIsResetEnabled] = useState(false);
  const [customizeColumns, setCustomizeColumns] = useState(defaultColumns);

  const [isUndo, setIsUndo] = useState(false);
  const [isDeleteModel, setIsDeleteModel] = useState(false);
  const [isDisabled, setIsDisabled] = useState(false);
  const [deleteItems, setDeleteItems] = useState<any[]>([]);
  const [deleteItemsLoading, setDeleteItemsLoading] = useState(false);
  const isDisabledBulkAction = isDisabled || isUndo;

  const [isFocus, setIsFocus] = useState<any>({
    weight: false,
    planProgress: false,
    duration: false,
    assignee: false,
    startDate: false,
    dueDate: false,
  });

  const [bulkAction, setBulkAction] = useState<any>({
    weight: "Weight",
    planProgress: "Plan Progress",
    duration: "Duration",
    assignee: "Assignee",
    startDate: null,
    dueDate: null,
    delete: "Delete",
  });

  const [undoData, setUndoData] = useState<{
    action: string;
    value: any;
    oldData: any[];
    showUndo: boolean;
  }>({
    action: "",
    value: null,
    oldData: [],
    showUndo: false,
  });

  const [checkedItems, setCheckedItems] = useState<{
    [key: number]: {
      category: boolean;
      items: { [key: number]: boolean };
    };
  }>({});

  const hasSelectedItems = Object.values(checkedItems).some((category) => {
    return Object.values(category.items).some(Boolean);
  });
  const isBulkShow = hasSelectedItems;

  const AssignOptions = planUsers?.map((user: any) => ({
    label: user.email,
    value: user.id,
    initials: user.name
      ? user.name.match(/\b\w/g).join("")
      : user.email[0].toUpperCase(),
  }));

  const selectedAssignee = planUsers?.find(
    (option: any) => option.email === bulkAction?.assignee
  );

  const userName = selectedAssignee
    ? selectedAssignee?.first_name && selectedAssignee?.last_name
      ? selectedAssignee?.first_name.substring(0, 1) +
        selectedAssignee?.last_name.substring(0, 1)
      : selectedAssignee?.name
      ? selectedAssignee?.name.split(" ").length > 1
        ? selectedAssignee.name.split(" ")[0].substring(0, 1) +
          selectedAssignee.name.split(" ")[1].substring(0, 1)
        : selectedAssignee.name.substring(0, 2)
      : selectedAssignee?.email?.substring(0, 2)
    : "Assignee";

  useEffect(() => {
    const updatedActiveKey = planDetails.categories.map((category) => {
      const existingKey = activeKey.find(
        (keyItem) => keyItem.id === category.id.toString()
      );
      return existingKey || { id: category.id.toString(), isExpand: true };
    });

    // Ensure we're not losing any existing keys that might not be in the current planDetails.categories
    const existingIds = planDetails.categories.map((category) =>
      category.id.toString()
    );
    const preservedKeys = activeKey.filter((keyItem) =>
      existingIds.includes(keyItem.id)
    );

    setActiveKey([...new Set([...updatedActiveKey, ...preservedKeys])]);
  }, [planDetails]);

  const handleToggle = (id: string) => {
    setActiveKey(
      activeKey.map((keyItem) =>
        keyItem.id === id
          ? { ...keyItem, isExpand: !keyItem.isExpand }
          : keyItem
      )
    );
  };

  const handleAddCategory = () => {
    setIsAddCategory(!isAddCategory);
  };

  const handleCategoryCreate = (value: string) => {
    if (!value) {
      setIsAddCategory(false);
      return;
    }

    setIsNewCategoryLoading(true);

    const payload = {
      name: value,
      start_line: moment(planDetails?.start_line ?? new Date()).format(
        "YYYY-MM-DD"
      ),
      dead_line: moment(planDetails?.dead_line ?? new Date()).format(
        "YYYY-MM-DD"
      ),
      project_plan: planDetails?.id,
    };

    dispatch(createPlanCategory(payload, handleCategoryCreateCallback));
  };

  const handleCategoryCreateCallback = () => {
    setIsAddCategory(false);
    setIsNewCategoryLoading(false);
  };

  const handleCollapseExpandAll = () => {
    if (
      activeKey.filter((keyItem) => keyItem.isExpand).length ===
      planDetails.categories.length
    ) {
      setActiveKey((prev) =>
        prev.map((keyItem) => ({ ...keyItem, isExpand: false }))
      );
    } else {
      setActiveKey((prev) =>
        prev.map((keyItem) => ({ ...keyItem, isExpand: true }))
      );
    }
  };

  const handleAdjustWeightToggle = () => {
    setCheckedItems({});
    dispatch({
      type: "WEIGHT_MODEL",
      payload: !weightModel,
    });
  };

  // Moving between different categories
  const handleMoveBetweenCategories = (
    sourceCategoryId: any,
    destinationCategoryId: any,
    sourceIndex: any,
    destinationIndex: any
  ) => {
    const updatedCategories = [...planDetails.categories];

    const sourceCategory = updatedCategories.find(
      (cat) => cat.id.toString() === sourceCategoryId
    );
    const destinationCategory = updatedCategories.find(
      (cat) => cat.id.toString() === destinationCategoryId
    );

    if (!sourceCategory || !destinationCategory) return;

    const sourceItems = Array.from(sourceCategory.action_items);
    const [movedItem] = sourceItems.splice(sourceIndex, 1);

    const destinationItems = Array.from(destinationCategory.action_items);
    destinationItems.splice(destinationIndex, 0, movedItem);

    // Update the action items for source and destination categories
    sourceCategory.action_items = sourceItems;
    destinationCategory.action_items = destinationItems;

    // Send updated actions to the API
    sendActionsToAPI(updatedCategories);
  };

  const onDragEndHandle = (result: DropResult) => {
    const { source, destination } = result;

    if (!destination) return;

    const sourceCategoryId: any = source.droppableId;
    const destinationCategoryId: any = destination.droppableId;

    const sourceCategory = planDetails.categories.find(
      (cat) => cat.id.toString() === sourceCategoryId
    );
    const movedItem = sourceCategory?.action_items[source.index];

    if (sourceCategoryId === destinationCategoryId) {
      handleReorderWithinCategory(
        sourceCategoryId,
        source.index,
        destination.index
      );

      if (movedItem && checkedItems[sourceCategoryId]?.items?.[movedItem.id]) {
        setCheckedItems((prev) => ({
          ...prev,
          [sourceCategoryId]: {
            ...prev[sourceCategoryId],
            items: {
              ...prev[sourceCategoryId]?.items,
              [movedItem.id]: true,
            },
          },
        }));
      }
    } else {
      handleMoveBetweenCategories(
        sourceCategoryId,
        destinationCategoryId,
        source.index,
        destination.index
      );

      if (movedItem && checkedItems[sourceCategoryId]?.items?.[movedItem.id]) {
        setCheckedItems((prev) => {
          const sourceItems = { ...prev[sourceCategoryId]?.items };
          delete sourceItems[movedItem.id];

          return {
            ...prev,
            [sourceCategoryId]: {
              ...prev[sourceCategoryId],
              items: sourceItems,
            },
            [destinationCategoryId]: {
              ...prev[destinationCategoryId],
              items: {
                ...prev[destinationCategoryId]?.items,
                [movedItem.id]: true,
              },
            },
          };
        });
      }
    }
  };

  // Reordering within the same category
  const handleReorderWithinCategory = (
    categoryId: any,
    sourceIndex: any,
    destinationIndex: any
  ) => {
    const updatedCategories = [...planDetails.categories];
    const category = updatedCategories.find(
      (cat) => cat.id.toString() === categoryId
    );

    if (!category) return;

    const reorderedItems = Array.from(category.action_items);
    const [movedItem] = reorderedItems.splice(sourceIndex, 1);
    reorderedItems.splice(destinationIndex, 0, movedItem);

    // Update the category with the new order of action items
    category.action_items = reorderedItems;

    // Send updated actions to the API
    sendActionsToAPI(updatedCategories);
  };

  const handleResetToDefault = async () => {
    setCustomizeColumns(defaultColumns);
    await dispatch(
      updateCustomizeColumn({
        project_plan: planDetails.id,
        column: defaultColumns.map((item) => item.key),
      })
    );
    setIsCustomizePopoverActive(false);
  };

  const sendActionsToAPI = (updatedCategories: any) => {
    // Construct the payload based on the updated categories
    const actions: any = [];

    updatedCategories.forEach((category: any) => {
      category.action_items.forEach((item: any, index: number) => {
        actions.push({
          id: item.id,
          order_id: index,
          category: category.id,
        });
      });
    });

    const payload = { actions };

    dispatch(changeCategoryOrder(payload, planDetails?.id));
  };

  const onDragEnd = (result: any) => {
    const { source, destination } = result;

    // If there's no destination (item dropped outside the list), do nothing
    if (!destination) return;

    // Reorder the list
    const reorderedColumns = Array.from(customizeColumns);
    const [moved] = reorderedColumns.splice(source.index, 1);
    reorderedColumns.splice(destination.index, 0, moved);

    const payload = {
      project_plan: planDetails.id,
      column: reorderedColumns
        ?.filter((item) => item.isVisible)
        ?.map((item) => item.key),
    };

    if (!isShared) {
      dispatch(updateCustomizeColumn(payload));
    }
    setCustomizeColumns(reorderedColumns);
  };

  const isVisibleLength = customizeColumns.filter((column) => column.isVisible);

  const checkColumns = (defaultColumns: any, sortedColumns: any) => {
    let isOrderCorrect = true;
    let isVisibilityCorrect = true;

    for (let i = 0; i < defaultColumns.length; i++) {
      if (defaultColumns[i].id !== sortedColumns[i].id) {
        isOrderCorrect = false;
        break;
      }
    }

    sortedColumns?.forEach((column: any) => {
      if (column.isVisible === false) {
        isVisibilityCorrect = false;
      }
    });

    return {
      isOrderCorrect,
      isVisibilityCorrect,
    };
  };

  useEffect(() => {
    let undoTimer: NodeJS.Timeout;
    if (isUndo) {
      undoTimer = setTimeout(() => {
        const newCheckedItems = { ...checkedItems };
        deleteItems.forEach((item) => {
          const categoryId = item.category;
          if (newCheckedItems[categoryId]?.items) {
            delete newCheckedItems[categoryId].items[item.id];
          }
        });
        setCheckedItems(newCheckedItems);

        setIsUndo(false);
        setUndoData({
          action: "",
          value: null,
          oldData: [],
          showUndo: false,
        });
        setDeleteItems([]);
        setIsDeleteModel(false);
        setIsFocus({
          name: false,
          weight: false,
          planProgress: false,
          duration: false,
          assignee: false,
          dueDate: false,
          startDate: false,
        });
      }, 10000);
    }
    return () => clearTimeout(undoTimer);
  }, [isUndo]);

  const isDateDisabled = (
    current: Moment,
    name: "start_line" | "dead_line"
  ): boolean => {
    if (!current) return false;

    if (name === "start_line") {
      if (
        planDetails?.start_line &&
        current.isBefore(moment(planDetails.start_line), "day")
      ) {
        return true;
      }
      if (
        planDetails?.dead_line &&
        current.isAfter(moment(planDetails.dead_line), "day")
      ) {
        return true;
      }
    } else if (name === "dead_line") {
      if (
        planDetails?.start_line &&
        current.isBefore(moment(planDetails.start_line), "day")
      ) {
        return true;
      }
      if (
        planDetails?.dead_line &&
        current.isAfter(moment(planDetails.dead_line), "day")
      ) {
        return true;
      }
    }

    return false;
  };

  const handleBulkAction = async (action: string, value: any) => {
    setIsDisabled(true);

    // Get all selected items data
    const selectedItemsData = planDetails.categories.reduce(
      (acc: any[], category) => {
        const categoryCheckedItems = checkedItems[category.id]?.items || {};
        const selectedItems = category.action_items.filter(
          (item) => categoryCheckedItems[item.id]
        );
        return [...acc, ...selectedItems];
      },
      []
    );

    // Store the original data for undo
    const originalData = selectedItemsData.map((item) => ({
      id: item.id,
      name: item.name,
      category: item.category,
      weight: item.weight,
      plan_progress: item.plan_progress,
      duration: item.duration,
      start_line: item.start_line,
      dead_line: item.dead_line,
      responsible_person: item?.responsible_person?.id || null,
    }));

    const updatedData = selectedItemsData.map((item) => ({
      id: item.id,
      name: item.name,
      category: item.category,
      weight: item.weight,
      plan_progress: item.plan_progress,
      duration: item.duration,
      start_line: item.start_line,
      dead_line: item.dead_line,
      responsible_person: item?.responsible_person?.id || null,
    }));

    const payload = {
      action_items: updatedData?.map((item) => ({
        ...item,
        ...(action === "weight" && {
          weight: value || item.weight,
        }),
        ...(action === "planProgress" && {
          plan_progress: value || item.plan_progress,
        }),
        ...(action === "duration" && {
          duration: value || item.duration,
        }),
        ...(action === "assignee" && {
          responsible_person: value?.value || item.responsible_person.id,
        }),
        ...(action === "startDate" && {
          start_line: moment(value).format("YYYY-MM-DD") || item.start_line,
        }),
        ...(action === "dueDate" && {
          dead_line: moment(value).format("YYYY-MM-DD") || item.dead_line,
        }),
      })),
    };

    try {
      await dispatch(
        updateBulkAction(payload, planDetails?.id, handleBulkActionCallback)
      );
      setUndoData({
        action,
        value,
        oldData: originalData,
        showUndo: true,
      });
    } catch (error) {
      console.error("Error updating bulk actions:", error);
    }
  };

  const handleBulkActionCallback = () => {
    setIsDisabled(false);
    setIsUndo(true);
    setBulkAction({
      weight: "Weight",
      planProgress: "Plan Progress",
      duration: "Duration",
      assignee: "Assignee",
      startDate: null,
      dueDate: null,
      delete: "Delete",
    });
    setIsFocus({
      name: false,
      weight: false,
      planProgress: false,
      duration: false,
      assignee: false,
      dueDate: false,
      startDate: false,
    });
  };

  const handleUndoCallback = () => {
    setIsDisabled(false);
    setCheckedItems({});
    setIsUndo(false);
    setUndoData({
      action: "",
      value: null,
      oldData: [],
      showUndo: false,
    });
  };

  const handleUndo = async () => {
    setIsDisabled(true);
    if (!undoData.oldData.length) return;

    const undoDataPayload = {
      action_items: undoData.oldData.map((item) => ({
        ...item,
        ...(undoData.action === "weight" && {
          weight: item.weight,
        }),
        ...(undoData.action === "planProgress" && {
          plan_progress: item.plan_progress,
        }),
        ...(undoData.action === "duration" && {
          duration: item.duration,
        }),
        ...(undoData.action === "assignee" && {
          responsible_person: item?.responsible_person,
        }),
        ...(undoData.action === "startDate" && {
          start_line: moment(item?.start_line).format("YYYY-MM-DD"),
        }),
        ...(undoData.action === "dueDate" && {
          dead_line: moment(item?.dead_line).format("YYYY-MM-DD"),
        }),
      })),
    };

    const updatedData = {
      action_items: undoData.oldData.map((item) => ({
        name: item.name,
        category: item.category,
        weight: item.weight,
        plan_progress: item.plan_progress,
        duration: item.duration,
        start_line: item.start_line,
        dead_line: item.dead_line,
        responsible_person: item?.responsible_person?.id || null,
      })),
    };

    const payload =
      undoData?.action === "delete" ? updatedData : undoDataPayload;

    try {
      await dispatch(
        updateBulkAction(payload, planDetails?.id, handleUndoCallback)
      );
    } catch (error) {
      console.error("Error undoing action:", error);
    }
  };

  const handleDeleteItems = () => {
    if (isDisabledBulkAction) return;

    const selectedItemsData = planDetails.categories.reduce(
      (acc: any[], category) => {
        const categoryCheckedItems = checkedItems[category.id]?.items || {};
        const selectedItems = category.action_items.filter(
          (item) => categoryCheckedItems[item.id]
        );
        return [...acc, ...selectedItems];
      },
      []
    );

    setDeleteItems(selectedItemsData);
    setIsDeleteModel(true);
  };

  const handleDeleteBulkItems = async () => {
    setDeleteItemsLoading(true);

    const payload = {
      deletedActionItemsIds: deleteItems?.map((item) => item.id),
    };

    try {
      await dispatch(
        updateBulkAction(
          payload,
          planDetails?.id,
          handleDeleteBulkItemsCallback
        )
      );
    } catch (error) {
      console.error("Error deleting bulk items:", error);
    }
  };

  const handleDeleteBulkItemsCallback = () => {
    setIsUndo(true);
    setDeleteItemsLoading(false);
    setIsDeleteModel(false);
    setUndoData({
      action: "delete",
      value: null,
      oldData: deleteItems,
      showUndo: true,
    });
  };

  const deletedModelClose = () => {
    setIsDeleteModel(false);
    setDeleteItems([]);
  };

  useEffect(() => {
    if (customizeColumn) {
      const visibleColumns =
        customizeColumn?.[0]?.column?.length > 0
          ? customizeColumn?.[0]?.column
          : [];

      const formattedColumns = customizeColumns.map((column) => ({
        ...column,
        isVisible: visibleColumns?.includes(column.key),
      }));

      const sortedColumns = [...formattedColumns].sort((a, b) => {
        const indexA = visibleColumns?.indexOf(a.key);
        const indexB = visibleColumns?.indexOf(b.key);
        return (
          (indexA === -1 ? Number.MAX_VALUE : indexA) -
          (indexB === -1 ? Number.MAX_VALUE : indexB)
        );
      });

      setCustomizeColumns(sortedColumns);
    }
  }, [customizeColumn]);

  useEffect(() => {
    if (customizeColumns) {
      const isDisabled = checkColumns(defaultColumns, customizeColumns);
      setIsResetEnabled(
        isDisabled.isOrderCorrect && isDisabled.isVisibilityCorrect
      );
    }
  }, [customizeColumns]);

  useEffect(() => {
    if (isUndo) {
      setIsUndo(false);
      setDeleteItems([]);
      setIsDeleteModel(false);
      setUndoData({
        action: "",
        value: null,
        oldData: [],
        showUndo: false,
      });
    }
  }, [checkedItems]);

  useEffect(()=>{
    dispatch(getProjectOwners(""))
  },[])

  if (isAIGeneratedPlanLoading) {
    return (
      <AIGenerationLoading>
        <img src={StarsLoading} alt="Generating" />
        <p>Suggesting Project Plan...</p>
        <img src={LoadingGif} alt="Generating" />
      </AIGenerationLoading>
    );
  }

  const combineActionItems = planDetails?.categories
    .map((category) => category?.action_items)
    ?.flat();

  const initialStatus = {
    1: [],
    2: [],
    3: [],
    4: [],
    5: [],
  };

  const groupedByStatus = combineActionItems?.reduce(
    (acc: any, project: any) => {
      const { status } = project;
      if (!acc[status]) {
        acc[status] = [];
      }
      acc[status].push(project);
      return acc;
    },
    { ...initialStatus }
  );

  return (
    <>
      <Modal
        footer={null}
        closable={false}
        onCancel={deletedModelClose}
        title={false}
        open={isDeleteModel}
        centered
        width={460}
        className="metricModel"
      >
        <ModalHeader>
          <div className="modal-left">
            <div className="modal-left-info">
              <Info />
            </div>
            <h3>Delete Action Items</h3>
          </div>
          <Cross className={"c-pointer"} onClick={deletedModelClose} />
        </ModalHeader>

        <ModelContent>
          Are you sure you want to delete following action items? <br />
          This action can be reversed by clicking on undo button.{" "}
        </ModelContent>

        <ModalBody>
          {deleteItems?.map((item) => (
            <li key={item.id}>{item.name}</li>
          ))}
        </ModalBody>

        <ModalFooter>
          <button onClick={deletedModelClose} disabled={deleteItemsLoading}>
            Cancel
          </button>
          <button onClick={handleDeleteBulkItems} disabled={deleteItemsLoading}>
            {deleteItemsLoading ? <LoadingOutlined /> : "Delete"}
          </button>
        </ModalFooter>
      </Modal>

      <WeightModel />

      {isNoteModel && data && (
        <ActionItemsModal
          isActionItem
          allData={data}
          data={data?.notes?.[0]}
          actionItemsModal={isNoteModel}
          setActionItemsModal={setIsNoteModel}
          projectPlanId={data.project_plan_id}
          PlanDetails={planDetails}
        />
      )}

      {isListView ? (
        <Container>
          <Wrapper ref={mainContainer}>
            <Head
              $color="#1C8B7E"
              isShort={isShort || isVisibleLength.length < 5}
            >
              <HeadItem>
                <input
                  type="checkbox"
                  checked={
                    planDetails.categories?.length > 0 &&
                    planDetails.categories?.every(
                      (category) =>
                        checkedItems?.[category.id]?.category === true &&
                        category.action_items?.every(
                          (item) =>
                            checkedItems?.[category.id]?.items?.[item.id] ===
                            true
                        )
                    )
                  }
                  onChange={(e) => {
                    const isChecked = e.target.checked;
                    const newCheckedItems = planDetails.categories.reduce(
                      (acc: any, category) => {
                        acc[category.id] = {
                          category: isChecked,
                          items: Object.fromEntries(
                            category.action_items.map((item) => [
                              item.id,
                              isChecked,
                            ])
                          ),
                        };
                        return acc;
                      },
                      {}
                    );
                    setCheckedItems(newCheckedItems);
                  }}
                  ref={(input) => {
                    if (input) {
                      const someChecked = planDetails.categories?.some(
                        (category) =>
                          checkedItems?.[category.id]?.category === true ||
                          category.action_items?.some(
                            (item) =>
                              checkedItems?.[category.id]?.items?.[item.id] ===
                              true
                          )
                      );
                      const allChecked = planDetails.categories?.every(
                        (category) =>
                          checkedItems?.[category.id]?.category === true &&
                          category.action_items?.every(
                            (item) =>
                              checkedItems?.[category.id]?.items?.[item.id] ===
                              true
                          )
                      );
                      input.indeterminate = someChecked && !allChecked;
                    }
                  }}
                />
              </HeadItem>

              <HeadItem>
                <ExpandIcon
                  className="cursor-pointer"
                  onClick={handleCollapseExpandAll}
                />
              </HeadItem>

              <HeadItem>
                Category & Action Items
                {!isShared && (
                  <span
                    onClick={handleAddCategory}
                    className="add-catrgory"
                    style={{
                      opacity: isAddCategory ? 0.5 : 1,
                      pointerEvents: isAddCategory ? "none" : "auto",
                      cursor: isAddCategory ? "not-allowed" : "pointer",
                    }}
                  >
                    <PlusIcon />
                    Add Category
                  </span>
                )}
              </HeadItem>

              {customizeColumns?.map((column) =>
                column.isVisible ? (
                  <HeadItem
                    key={column.key}
                    $center={
                      column?.key === "assignee" || column?.key === "duration"
                        ? true
                        : false
                    }
                  >
                    {column.title}
                  </HeadItem>
                ) : null
              )}

              <HeadItem>
                <div className="cursor-pointer">
                  <Popover
                    content={
                      <>
                        <CustomizePopover
                          style={{
                            height:
                              mainContainer.current &&
                              mainContainer.current?.offsetHeight > 350
                                ? 350
                                : mainContainer.current?.offsetHeight,
                          }}
                        >
                          <CustomizeHeader>
                            <h6>Customize Popover</h6>
                            <CloseIcon
                              style={{ cursor: "pointer" }}
                              onClick={() => setIsCustomizePopoverActive(false)}
                            />
                          </CustomizeHeader>

                          <CustomizeSubHeader>
                            <h5>Columns</h5>

                            <button
                              onClick={handleResetToDefault}
                              disabled={isResetEnabled}
                            >
                              Reset to Default
                            </button>
                          </CustomizeSubHeader>
                          <DragDropContext onDragEnd={onDragEnd}>
                            <Droppable droppableId="columns">
                              {(provided) => (
                                <CustomizeItems
                                  {...provided.droppableProps}
                                  ref={provided.innerRef}
                                >
                                  {customizeColumns.map((column, index) => (
                                    <Draggable
                                      key={column.key}
                                      draggableId={column.key}
                                      index={index}
                                    >
                                      {(provided) => (
                                        <CustomizeItem
                                          ref={provided.innerRef}
                                          {...provided.draggableProps}
                                        >
                                          <div className="customizeItem-title">
                                            {/* Apply drag handle only to this icon */}
                                            <div
                                              {...provided.dragHandleProps}
                                              style={{
                                                cursor: "grab",
                                                display: "inline-block",
                                              }}
                                            >
                                              <Dragding />{" "}
                                              {/* Your drag icon here */}
                                            </div>

                                            <h4>{column.title}</h4>
                                          </div>
                                          <Switch
                                            checked={column.isVisible}
                                            size="small"
                                            onChange={(value) => {
                                              const UpdatedColumns =
                                                customizeColumns?.map((item) =>
                                                  item.key === column.key
                                                    ? {
                                                        ...item,
                                                        isVisible:
                                                          !item.isVisible,
                                                      }
                                                    : item
                                                );

                                              const payload = {
                                                project_plan: planDetails.id,
                                                column: UpdatedColumns?.filter(
                                                  (item) => item.isVisible
                                                ).map((item) => item.key),
                                              };

                                              dispatch(
                                                updateCustomizeColumn(payload)
                                              );

                                              setCustomizeColumns(
                                                UpdatedColumns
                                              );

                                              if (value) {
                                                setTimeout(() => {
                                                  mainContainer.current?.scrollTo(
                                                    {
                                                      top: 0,
                                                      left: 10000,
                                                      behavior: "smooth",
                                                    }
                                                  );
                                                }, 200);
                                              }
                                            }}
                                          />
                                        </CustomizeItem>
                                      )}
                                    </Draggable>
                                  ))}
                                  {provided.placeholder}
                                </CustomizeItems>
                              )}
                            </Droppable>
                          </DragDropContext>
                        </CustomizePopover>
                      </>
                    }
                    open={isCustomizePopoverActive}
                    onOpenChange={(visible) =>
                      setIsCustomizePopoverActive(visible)
                    }
                    trigger="click"
                    overlayClassName="CustomizePopover"
                    placement="bottomRight"
                    builtinPlacements={{
                      bottomRight: {
                        points: ["tr", "br"],
                        offset: [0, -6],
                      },
                    }}
                    // @ts-ignore
                    getPopupContainer={(triggerNode) => triggerNode.parentNode}
                  >
                    {isCustomizePopoverActive ? (
                      <Setting
                        onClick={() =>
                          setIsCustomizePopoverActive(!isCustomizePopoverActive)
                        }
                      />
                    ) : (
                      <SettingGray
                        onClick={() =>
                          setIsCustomizePopoverActive(!isCustomizePopoverActive)
                        }
                      />
                    )}
                  </Popover>
                </div>
              </HeadItem>
            </Head>

            <Accordion
              as={Body}
              activeKey={activeKey
                .filter((item) => item.isExpand)
                .map((item) => item.id)}
            >
              {isAddCategory ? (
                <AddCategory
                  isShared={isShared}
                  isShort={isShort}
                  setIsAddCategory={setIsAddCategory}
                  onCreate={handleCategoryCreate}
                  isNewCategoryLoading={isNewCategoryLoading}
                />
              ) : null}
              <DragDropContext onDragEnd={onDragEndHandle}>
                {planDetails.categories.map((item) => (
                  <Droppable droppableId={item.id.toString()} key={item.id}>
                    {(provided) => (
                      <Category
                        data={item}
                        additionalData={{
                          plan_start_date: planDetails.start_line,
                          plan_end_date: planDetails.dead_line,
                        }}
                        customizeColumns={customizeColumns}
                        isActive={activeKey
                          .filter((a) => a.isExpand)
                          .map((a) => a.id)
                          .includes(item.id.toString())}
                        checkedItems={checkedItems}
                        setCheckedItems={setCheckedItems}
                        onToggle={handleToggle}
                        onAdjustWeight={handleAdjustWeightToggle}
                        isShared={isShared}
                        isExpandAllNotes={isExpandAllNotes}
                        isShort={isShort || isVisibleLength.length < 5}
                        key={item.id}
                        provided={provided}
                        isAddCategory={isAddCategory}
                        PlanDetails={planDetails}
                        setIsAddCategory={setIsAddCategory}
                        filterBy={filterBy}
                      />
                    )}
                  </Droppable>
                ))}
              </DragDropContext>
            </Accordion>
          </Wrapper>
        </Container>
      ) : (
        <CardViewWrapper>
          {Object.entries(groupedByStatus).map(([key, value]) => (
            <ActionItemCardView
              data={value}
              status={key}
              SetData={SetData}
              setIsNoteModel={setIsNoteModel}
            />
          ))}
        </CardViewWrapper>
      )}

      <BultAction
        style={{
          transition: "all 0.3s ease",
          opacity: isBulkShow ? "1" : "0",
          visibility: isBulkShow ? "visible" : "hidden",
          transform: isBulkShow
            ? "translateY(0) translateX(-50%)"
            : "translateY(100px) translateX(-50%)",
        }}
      >
        {isDisabled && (
          <BulkActionLoading>
            <LoadingOutlined />
          </BulkActionLoading>
        )}

        {isUndo && (
          <div
            className="bulk-action-item bulk-action-undo"
            onClick={handleUndo}
          >
            <UndoIcon />

            <h3>Undo</h3>
          </div>
        )}

        <div className="bulk-action-item">
          <div className="bulk-action-item-icon">
            <StarIcon
              className={`${
                isDisabledBulkAction
                  ? "disabled"
                  : isFocus.weight
                  ? "active"
                  : ""
              }`}
            />
          </div>
          <Select
            className={`bulk-action-select ${
              isDisabledBulkAction ? "disabled-action-select" : ""
            }`}
            bordered={false}
            suffixIcon={null}
            disabled={isDisabledBulkAction}
            value={bulkAction.weight}
            onClick={() => {
              if (isDisabledBulkAction) {
                return;
              }

              if (!isFocus.weight) {
                setIsFocus({
                  weight: true,
                });
              } else {
                setIsFocus({
                  weight: false,
                });
              }
            }}
            onBlur={() => {
              setIsFocus({
                weight: false,
              });
            }}
            onFocus={(e) => {
              e.preventDefault();
              setIsFocus({
                weight: true,
              });
            }}
            onChange={(value) => {
              setBulkAction({ ...bulkAction, weight: value });
              setIsFocus({
                weight: false,
              });

              handleBulkAction("weight", value);
            }}
            style={{
              width: "80px",
            }}
            size="small"
            options={actionItemWeightOptions}
          />
        </div>

        <div className="bulk-action-item">
          <div className="bulk-action-item-icon">
            <PlanProgressIcon
              className={`${
                isDisabledBulkAction
                  ? "disabled"
                  : isFocus.planProgress
                  ? "active"
                  : ""
              }`}
            />
          </div>
          <Select
            className={`bulk-action-select ${
              isDisabledBulkAction ? "disabled-action-select" : ""
            }`}
            bordered={false}
            suffixIcon={null}
            value={bulkAction.planProgress}
            disabled={isDisabledBulkAction}
            onClick={() => {
              if (isDisabledBulkAction) {
                return;
              }

              if (!isFocus.planProgress) {
                setIsFocus({
                  planProgress: true,
                });
              } else {
                setIsFocus({
                  planProgress: false,
                });
              }
            }}
            onChange={(value) => {
              setBulkAction({ ...bulkAction, planProgress: value });
              setIsFocus({
                planProgress: false,
              });

              handleBulkAction("planProgress", value);
            }}
            onFocus={(e) => {
              e.preventDefault();
              setIsFocus({
                planProgress: true,
              });
            }}
            onBlur={() => {
              setIsFocus({
                planProgress: false,
              });
            }}
            style={{
              width: "125px",
            }}
            size="small"
            options={actionItemWeightOptions}
          />
        </div>

        <div className="bulk-action-item">
          <div className="bulk-action-item-icon">
            <DurationIcon
              className={`${
                isDisabledBulkAction
                  ? "disabled"
                  : isFocus.duration
                  ? "active"
                  : ""
              }`}
            />
          </div>
          <Select
            className={`bulk-action-select ${
              isDisabledBulkAction ? "disabled-action-select" : ""
            }`}
            popupClassName="bulk-action-select-duration"
            bordered={false}
            suffixIcon={null}
            value={bulkAction.duration}
            disabled={isDisabledBulkAction}
            onClick={() => {
              if (isDisabledBulkAction) {
                return;
              }

              if (!isFocus.duration) {
                setIsFocus({
                  duration: true,
                });
              } else {
                setIsFocus({
                  duration: false,
                });
              }
            }}
            onChange={(value) => {
              setBulkAction({ ...bulkAction, duration: value });
              setIsFocus({
                duration: false,
              });

              handleBulkAction("duration", value);
            }}
            onFocus={(e) => {
              e.preventDefault();
              setIsFocus({
                duration: true,
              });
            }}
            onBlur={() => {
              setIsFocus({
                duration: false,
              });
            }}
            style={{
              width: "90px",
            }}
            size="small"
            options={durationList?.map((item) => ({
              label: item.key,
              value: item.value,
            }))}
          />
        </div>

        <div className="bulk-action-item">
          <div className="bulk-action-item-icon">
            <AssigneeIcon
              className={`${
                isDisabledBulkAction
                  ? "disabled"
                  : isFocus.assignee
                  ? "active"
                  : ""
              }`}
            />
          </div>
          <Select
            className={`bulk-action-select ${
              isDisabledBulkAction ? "disabled-action-select" : ""
            } ${userName === "Assignee" ? "" : "antd-assignee"}`}
            popupClassName="bulk-action-select-duration"
            bordered={false}
            suffixIcon={null}
            value={userName}
            labelInValue
            disabled={isDisabledBulkAction}
            onClick={() => {
              if (isDisabledBulkAction) {
                return;
              }

              if (!isFocus.assignee) {
                setIsFocus({
                  assignee: true,
                });
              } else {
                setIsFocus({
                  assignee: false,
                });
              }
            }}
            onBlur={() => {
              setIsFocus({
                assignee: false,
              });
            }}
            onFocus={(e) => {
              e.preventDefault();
              setIsFocus({
                assignee: true,
              });
            }}
            onChange={(value: any) => {
              setBulkAction({ ...bulkAction, assignee: value?.label });
              setIsFocus({
                assignee: false,
              });

              handleBulkAction("assignee", value);
            }}
            size="small"
          >
            {AssignOptions.map((option: any, index: any) => {
              const selectedUser = option?.label == bulkAction?.assignee;

              return (
                <Select.Option
                  key={index}
                  value={option.value}
                  style={{
                    backgroundColor: selectedUser ? "#e6f7ff" : "#fff",
                  }}
                >
                  {option.label}
                </Select.Option>
              );
            })}
          </Select>
        </div>

        <div className="bulk-action-item">
          <div className="bulk-action-date-picker-icon">
            <CalendarIcon
              className={`${
                isDisabledBulkAction
                  ? "disabled"
                  : isFocus.startDate
                  ? "active"
                  : ""
              }`}
            />
          </div>

          <DatePicker
            className={`bulk-action-date-picker ${
              isFocus.startDate ? "focused-action-select" : ""
            } ${isDisabledBulkAction ? "disabled-action-select" : ""}`}
            bordered={false}
            suffixIcon={null}
            allowClear={false}
            style={{
              width: "130px",
            }}
            open={isFocus.startDate}
            disabledDate={(current) => {
              return isDateDisabled(current, "start_line");
            }}
            placeholder="Start Date"
            disabled={isDisabledBulkAction}
            value={bulkAction.startDate}
            onChange={(value) => {
              if (isDisabledBulkAction) {
                return;
              }

              setBulkAction({ ...bulkAction, startDate: value });
              handleBulkAction("startDate", value);

              // Close the date picker dropdown
              setIsFocus({});
            }}
            onClick={() => {
              if (isDisabledBulkAction) {
                return;
              }
              setIsFocus({
                startDate: true,
              });
            }}
            onBlur={() => {
              setIsFocus({});
            }}
            onFocus={(e) => {
              e.preventDefault();
              setIsFocus({
                startDate: true,
              });
            }}
          />
        </div>

        <div className="bulk-action-item">
          <div className="bulk-action-date-picker-icon">
            <BellIcon
              className={`${
                isDisabledBulkAction
                  ? "disabled"
                  : isFocus.dueDate
                  ? "active"
                  : ""
              }`}
            />
          </div>

          <DatePicker
            className={`bulk-action-date-picker ${
              isFocus.dueDate ? "focused-action-select" : ""
            } ${isDisabledBulkAction ? "disabled-action-select" : ""}`}
            bordered={false}
            suffixIcon={null}
            allowClear={false}
            style={{
              width: "130px",
            }}
            disabledDate={(current) => {
              return isDateDisabled(current, "dead_line");
            }}
            placeholder="Due Date"
            disabled={isDisabledBulkAction}
            value={bulkAction.dueDate}
            onSelect={(value) => {
              if (isDisabledBulkAction) {
                return;
              }

              setBulkAction({ ...bulkAction, dueDate: value });

              handleBulkAction("dueDate", value);
            }}
            onClick={() => {
              if (isDisabledBulkAction) {
                return;
              }
              setIsFocus({
                dueDate: true,
              });
            }}
            onBlur={() => {
              setIsFocus({
                dueDate: false,
              });
            }}
            onFocus={(e) => {
              e.preventDefault();
              setIsFocus({
                dueDate: true,
              });
            }}
          />
        </div>
        <div
          className="bulk-action-item"
          style={{
            opacity: isDisabledBulkAction ? 0.5 : 1,
            cursor: isDisabledBulkAction ? "not-allowed" : "pointer",
          }}
          onClick={handleDeleteItems}
        >
          <DeleteIcon />
          <h3>Delete</h3>
        </div>
      </BultAction>
    </>
  );
};

export default ActionItems;
