import styled from "styled-components";

export const Wrapper = styled.div`
  display: flex;
  align-items: center;
  border-radius: 4px;
  background: var(--Primary-50, #ECF3F6);
  gap: 8px;
  padding: 4px 10px;
`;

export const Label = styled.span<{ $ellipsis?: boolean }>`
  flex: 1;
  color: var(--Neutral-500, #737373);
  font-family: Inter;
  font-size: 13px;
  font-weight: 400;
  line-height: 16px;

  ${({ $ellipsis }) => $ellipsis && `
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
  `}
`;

export const Icon = styled.span`
  margin-right: 4px;
  cursor: default;
`;

export const Actions = styled.div`
  display: flex;
  align-items: center;
`;

export const IconBtn = styled.button`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 28px;
  height: 28px;
  background: transparent;
  border: none;
  padding: 6px;

  svg {
    transition: transform 0.3s;
  }

  &:hover {
    svg {
      transform: scale(1.25);
    }
  }
`;

export const DeleteBtn = styled.button`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 28px;
  height: 28px;
  background: transparent;
  border: none;
  padding: 6px;

  img {
    transition: transform 0.3s;
  }

  &:hover {
    img {
      transform: scale(1.25);
    }
  }
`;
