import React, {
  useState,
  FC,
  useEffect,
  useImperativeHandle,
  forwardRef,
  createRef,
  useRef,
} from "react";
import { useDispatch, useSelector } from "react-redux";
import { Draggable } from "react-beautiful-dnd";
import { Accordion } from "react-bootstrap";
import { Button, InputField } from "elements";
import { Select, DatePicker, Popover, Modal, Row } from "antd";
import cc from "classnames";
import moment, { Moment } from "moment";
import { LoadingOutlined } from "@ant-design/icons";

import { DeleteItemModal } from "../DeleteItemModal";

import { ReactComponent as EditIcon } from "assets/svg/editBox.svg";
import { ReactComponent as DeleteIcon } from "assets/svg/deleteBox.svg";
import { ReactComponent as CloseIcon } from "assets/svg/closeCricle.svg";
import { ReactComponent as Cross } from "assets/svg/closeBox.svg";
import { ReactComponent as ArrowDownGray } from "assets/svg/arrowDownGray.svg";

import {
  Wrapper,
  Col,
  Dragger,
  AddButton,
  // UserPicture,
  InputGroup,
  NameContent,
  NameWrapper,
  NameContainer,
  UpdateStatusContent,
  StatusText,
  NewPlanProgress,
  UpdateStatusFooter,
} from "./styled";

// @ts-ignore
import classes from "./styled.module.scss";

import moveIcon from "./move.svg";

import { IItem } from "../@types";

import {
  upateActionItem,
  updateManualStatus,
  // @ts-ignore
} from "modules/actions/PlanActions";

// @ts-ignore
import { weightsList, categoryCount, durationList } from "constants";

// @ts-ignore
import { validateEmail, useUser } from "utils/utility";
import InviteUserModal from "../InviteUserModal";
import {
  ValidateDeadLineValue,
  ValidateStartLineValue,
} from "../../../../utils/validateValue";
import ErrorTooltip from "../../../../components/ErrorTooltip";

import { NoteContainer } from "../Note/styled";

import { ReactComponent as CompleteIcon } from "../Category/complete.svg";
import { ReactComponent as OnHoldIcon } from "../Category/onHold.svg";
import { ReactComponent as TodoIcon } from "../Category/todo.svg";
import { ReactComponent as InProgessIcon } from "../Category/inProgress.svg";

import { ReactComponent as CancelIcon } from "../Category/canceled.svg";
import { ReactComponent as UndoIcon } from "assets/svg/undo.svg";

import { ReactComponent as CalanderIcon } from "../Category/calander.svg";
import { isColumnVisible } from "../../../../utils/helper";

import { ActionItemsModal } from "../../../../components/ActionItemsModal";
import SelectMetric from "../Category/SelectMetric";
// @ts-ignore
import ManageCustomMetricsModal from "../../Modal/ManageCustomMetrics";
import AddNoteModal from "../../Modal/AddNoteModal";
import { toast } from "react-toastify";

// @ts-ignore
import { createNewActionItemsModal } from "../../../../modules/actions/PlanActions";

const { Option } = Select;

interface IProps {
  data?: IItem;
  additionalData?: {
    plan_start_date: string | null;
    plan_end_date: string | null;
    plan_owner?: string;
    category_start_date: string | null;
    category_end_date: string | null;
  };
  setEdit?: (value: boolean) => void;
  isNew?: boolean;
  projectPlanId: number;
  isShared?: boolean;
  isShort: boolean;
  index?: number;
  onCreate?: (values: string) => void;
  isExpandAll?: boolean;
  isAdd?: boolean;
  setIsAdd?: (value: boolean) => void;
  customizeColumns?: any;
  PlanDetails?: any;
  categoryId?: number;
  checkedItems?: any;
  setCheckedItems?: any;
  setIsProgressLoading?: (value: boolean) => void;
}

interface IHandle {
  focusInput: () => void;
}

interface IDraggableItemProps {
  data: IItem;
  additionalData?: {
    plan_start_date: string | null;
    plan_end_date: string | null;
    plan_owner?: string;
    category_start_date: string | null;
    category_end_date: string | null;
  };
  projectPlanId: number;
  isShared: boolean;
  isShort: boolean;
  index: number;
  isExpand?: boolean;
  customizeColumns?: any;
  PlanDetails?: any;
  status?: any;
  checkedItems?: any;
  setCheckedItems?: any;
  categoryId?: any;
  setIsProgressLoading?: (value: boolean) => void;
}

/* interface INewItemProps {
  projectPlanId: number;
  onCreate: (values: string) => void;
} */

export const DraggableItem: FC<IDraggableItemProps> = ({
  data,
  additionalData,
  projectPlanId,
  isShared,
  isShort,
  index = 0,
  isExpand,
  customizeColumns,
  PlanDetails,
  checkedItems,
  setCheckedItems,
  categoryId,
  setIsProgressLoading, 
}) => {
  const { id } = data;

  return (
    <Draggable key={id} draggableId={id.toString()} index={index}>
      {(provided) => (
        <Wrapper ref={provided.innerRef} {...provided.draggableProps}>
          <Col>
            <input
              type="checkbox"
              checked={checkedItems[categoryId]?.items?.[id] || false}
              onChange={(e) => {
                const isChecked = e.target.checked;
                setCheckedItems((prev: any) => {
                  const updatedItems = {
                    ...prev[categoryId]?.items,
                    [id]: isChecked,
                  };

                  const allItemsChecked = Object.values(updatedItems).every(
                    (checked) => checked
                  );

                  return {
                    ...prev,
                    [categoryId]: {
                      ...prev[categoryId],
                      items: updatedItems,
                      category: allItemsChecked,
                    },
                  };
                });
              }}
            />
          </Col>

          <Col>
            {!isShared && (
              <Dragger
                {...provided.dragHandleProps}
                $disabled={isShared}
                title={isShared ? "" : "Drag to reorder..."}
                data-testid="drag-button"
              >
                <img src={moveIcon} alt="" />
              </Dragger>
            )}
          </Col>

          <Item
            data={data}
            additionalData={additionalData}
            projectPlanId={projectPlanId}
            isShared={isShared}
            isShort={isShort}
            isExpandAll={isExpand}
            customizeColumns={customizeColumns}
            PlanDetails={PlanDetails}
            checkedItems={checkedItems}
            setCheckedItems={setCheckedItems}
            setIsProgressLoading={setIsProgressLoading}
          />
        </Wrapper>
      )}
    </Draggable>
  );
};

export const NewItem = forwardRef<IHandle, IProps>(
  ({ projectPlanId, isShort, onCreate, isAdd, setIsAdd }, ref) => {
    return (
      <Wrapper>
        <Col>
          <input type="checkbox" />
        </Col>

        <Col>
          <AddButton title="Add new action item." data-testid="add-button">
            <img src={moveIcon} alt="" />
          </AddButton>
        </Col>

        <Item
          isNew
          projectPlanId={projectPlanId}
          isShort={isShort}
          onCreate={onCreate}
          setIsAdd={setIsAdd}
          isAdd={isAdd}
        // ref={$itemRef}
        />
      </Wrapper>
    );
  }
);

const Item = forwardRef<IHandle, IProps>(
  (
    {
      PlanDetails,
      data,
      additionalData,
      isNew,
      setEdit,
      setIsAdd,
      projectPlanId,
      isShared = false,
      onCreate,
      isExpandAll,
      isAdd,
      customizeColumns,
      categoryId,
      checkedItems,
      setCheckedItems,
      setIsProgressLoading
    },
    ref
  ) => {
    const {
      id,
      name,
      duration,
      weight,
      plan_progress,
      responsible_person,
      start_line,
      dead_line,
      start,
      current,
      target,
      notes,
      status,
      label,
      distance_unit,
      energy_unit,
      height_unit,
      time_unit,
      volume_unit,
      weight_unit,
      count_unit,
      scale_unit,
      currency_unit,
      custom_unit_name,
    } = data || ({} as IItem);

    const { planDetails, updateStatusLoading, updateActionitemLoading } =
      useSelector((state: any) => state.plan.plan);

    const dispatch = useDispatch();
    const user = useUser();

    const onwerEmail = planDetails?.invited_owner;
    const userEmail = user?.email;
    const isOnwer = onwerEmail === userEmail;

    const [IsAddMetric, setIsAddMetric] = useState(false);

    const [isEdit, setIsEdit] = useState(false);

    const [isEditName, setIsEditName] = useState(false);
    const [editNameValue, setEditNameValue] = useState("");
    const [oldData, setOldData] = useState<any>({});

    const [itemName, setItemName] = useState("");
    const [emailToSearch, setEmailToSearch] = useState("");
    const [emailToInvite, setEmailToInvite] = useState<string | null>(null);
    const [isExpand, setIsExpand] = useState(true);
    const [isAddNote, setIsAddNote] = useState(false);
    const [isNoteModel, setIsNoteModel] = useState(false);
    const [isNote, setIsNote] = useState(false);
    const [updateProgressLoading, setUpdateProgressLoading] = useState(false);

    const [isEditNote, setIsEditNote] = useState(false);
    const [isActive, setIsActive] = useState(false);

    const [selectStatus, setSelectStatus] = useState(status || 1);
    const [updateStatusModel, setUpdateStatusModel] = useState(false);
    const [selectedValue, setSelectedValue] = useState<any>();
    const [selectedUnit, setSelectedUnit] = useState<string | null>();

    const [isNoteButton, setIsNoteButton] = useState(false);
    const [actionProgress, setActionProgress] = useState<number | 0>();
    const [updateStatus, setUpdateStatus] = useState<any>({
      name: name,
      status: 0,
      oldPlanProgress: 0,
      oldStatus: 0,
      newPlanProgress: null,
      userId: 0,
    });

    const nameInputRef = useRef<HTMLDivElement>(null);

    useEffect(() => {
      const handleClickOutside = (event: MouseEvent) => {
        if (
          nameInputRef.current &&
          !nameInputRef.current.contains(event.target as Node) &&
          isEditName
        ) {
          setIsEditName(false);
          handleChangeItemName();
        }
      };

      document.addEventListener("mousedown", handleClickOutside);
      return () => {
        document.removeEventListener("mousedown", handleClickOutside);
      };
    }, [isEditName, name, editNameValue]);

    
    function getUnit(label: any) {
      switch (label) {
        case "distance":
          setSelectedUnit(distance_unit);
          break;
        case "energy":
          setSelectedUnit(energy_unit);
          break;
        case "height":
          setSelectedUnit(height_unit);
          break;
        case "time":
          setSelectedUnit(time_unit);
          break;
        case "volume":
          setSelectedUnit(volume_unit);
          break;
        case "weight":
          setSelectedUnit(weight_unit);
          break;
        case "count":
          setSelectedUnit(count_unit);
          break;
        case "scale":
          setSelectedUnit(scale_unit);
          break;
        case "currency":
          setSelectedUnit(currency_unit);
          break;
        case "custom":
          setSelectedUnit(custom_unit_name);
          break;
        default:
          setSelectedUnit("");
      }
    }

    const [measurementPop, setMeasurementPop] = useState(false);

    const [values, setValues] = useState({
      start: start || null,
      current: current || null,
      target: target || null,
    });

    const [isStartLocked, setIsStartLocked] = useState(false);

    const notesData = notes ? notes[0] : null;

    useEffect(() => {
      if (isExpandAll && notesData && notesData?.context) {
        setIsExpand(true);
      } else {
        setIsExpand(false);
      }
    }, [isExpandAll]);

    useEffect(() => {
      getUnit(label);
    }, [measurementPop]);

    useEffect(() => {
      setActionProgress(plan_progress);
    }, [plan_progress]);

    const [isDeleted, setIsDeleted] = useState(false);

    const { users: planUsers } = useSelector(
      (state: any) => state.user.projectOwners
    );

    const selectedOwner = planUsers.find(
      (owner: { id: number }) => owner?.id === responsible_person?.id
    );
    const otherOwners = planUsers.filter(
      (owner: { id: number }) => owner?.id !== responsible_person?.id
    );
    const orderedPlanUsers = selectedOwner
      ? [selectedOwner, ...otherOwners]
      : planUsers;

    const isTrueOwner = user?.email === additionalData?.plan_owner;
    const filteredUsers = orderedPlanUsers?.filter((owner: { email: string }) =>
      owner.email.toLowerCase().includes(emailToSearch.toLowerCase())
    );

    const $inputRef = createRef<HTMLInputElement>();

    useImperativeHandle(ref, () => ({
      focusInput: () => {
        setIsEdit(true);
      },
    }));

    useEffect(() => {
      if (isEdit && $inputRef.current) {
        $inputRef.current.focus();
      }
    }, [isEdit, $inputRef]);

    useEffect(() => {
      if (isNew && $inputRef.current) {
        $inputRef.current.focus();
      }
    }, [isNew]);

    useEffect(() => {
      if (isEditName && $inputRef.current) {
        $inputRef.current.focus();
      }
    }, [isEditName]);

    useEffect(() => {
      if (isAdd) setIsActive(true);
    }, [isAdd]);

    useEffect(() => {
      if (isActive && $inputRef.current) {
        $inputRef.current.focus();
      }
    }, [isActive]);

    const handleEdit = () => {
      setItemName(name || "");
      setIsEditNote(true);
      setIsNoteModel(true);
    };

    const handleBlur = () => {
      setIsEdit(false);
      setEdit && setEdit(false);
      setIsAdd && setIsAdd(false);
      changeName();
    };

    const handleKeyDown = (e: React.KeyboardEvent<HTMLInputElement>) => {
      if (e.key === "Enter") {
        setIsEdit(false);
        setEdit && setEdit(false);
        setIsAdd && setIsAdd(false);
        changeName();
      } else if (e.key === "Escape") {
        setIsEdit(false);
        setEdit && setEdit(false);
        setIsAdd && setIsAdd(false);
        setItemName(name || "");
      }
    };

    const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
      let input = e.target.value;

      if (input?.startsWith(" ")) {
        input = input.trim();
      }

      if (input.length > 255) {
        input = input.slice(0, 255);
      }

      setItemName(input);
    };

    const changeName = () => {
      if (!itemName) return;

      if (isNew && onCreate) {
        onCreate(itemName);
        setItemName("");
      } else {
        dispatch(upateActionItem(id, { name: itemName }, projectPlanId, true));
      }

      setItemName("");
    };

    const startLineDate = additionalData?.category_start_date;
    const endLineDate = additionalData?.category_end_date;

    const isDateDisabled = (
      current: Moment,
      name: "start_line" | "dead_line"
    ): boolean => {
      if (!current) return false;

      if (name === "start_line") {
        if (
          (additionalData?.plan_start_date &&
            current.isBefore(moment(additionalData.plan_start_date), "day")) ||
          (additionalData?.category_start_date &&
            current.isBefore(moment(additionalData.category_start_date), "day"))
        ) {
          return true;
        }
        if (
          (additionalData?.plan_end_date &&
            current.isAfter(moment(additionalData.plan_end_date), "day")) ||
          (additionalData?.category_end_date &&
            current.isAfter(moment(additionalData.category_end_date), "day"))
        ) {
          return true;
        }
      } else if (name === "dead_line") {
        if (
          (additionalData?.plan_start_date &&
            current.isBefore(moment(additionalData.plan_start_date), "day")) ||
          (additionalData?.category_start_date &&
            current.isBefore(moment(additionalData.category_start_date), "day"))
        ) {
          return true;
        }
        if (
          (additionalData?.plan_end_date &&
            current.isAfter(moment(additionalData.plan_end_date), "day")) ||
          (additionalData?.category_end_date &&
            current.isAfter(moment(additionalData.category_end_date), "day"))
        ) {
          return true;
        }

        // if (start_line && current <= moment(start_line)) {
        //   return true;
        // }
      }

      return false;
    };

    const startLineError = ValidateStartLineValue(
      start_line || null,
      startLineDate || null,
      endLineDate || null
    );

    const deadLineError = ValidateDeadLineValue(
      dead_line || null,
      startLineDate || null,
      endLineDate || null,
      start_line || null
    );

    useEffect(() => {
      if (!data) return;
      setValues({
        start: start || null,
        current: current || null,
        target: target || null,
      });
      // getUnit(label)
    }, [data]);

    useEffect(() => {
      setIsStartLocked(isTrueOwner ? false : start !== null);
    }, [isTrueOwner, start]);

    const handleChangeInput = (e: any) => {
      const { name, value } = e.target;

      if (value === "" || /^[1-9][0-9]*$/.test(value) || value === "0") {
        setValues((prev) => ({
          ...prev,
          [name]: value === "" ? null : value,
        }));
      }
    };

    const handleUpdateMeasurement = () => {
      const unitKey = selectedValue.unitId
        ? "custom_unit"
        : "metric_unit"; // Fixed typo from "lable" to "label"

      let payload = {
        start: Number(values.start).toFixed(2),
        current: Number(values.current).toFixed(2),
        target: Number(values.target).toFixed(2),
        [unitKey]: selectedValue.unitId || selectedValue?.id, // Use computed property name for the key
      }
      if (selectedValue?.unitId) {
        payload = {
          ...payload,
          label: "custom"
        }
      }
      dispatch(
        upateActionItem(
          id,
          payload,
          projectPlanId,
          true
        )
      );

      setMeasurementPop(false);
      setIsStartLocked(!isTrueOwner && values.start !== null);
    };

    const StatusOptions = [
      {
        label: "To Do",
        value: 1,
        icon: <TodoIcon />,
      },
      {
        label: "In Progress",
        value: 2,
        icon: <InProgessIcon />,
      },
      {
        label: "On Hold",
        value: 3,
        icon: <OnHoldIcon />,
      },
      {
        label: "Completed",
        value: 4,
        icon: <CompleteIcon />,
      },
      {
        label: "Cancelled",
        value: 5,
        icon: <CancelIcon />,
      },
    ];

    useEffect(() => {
      setSelectStatus(status || 1);
    }, [status]);

    const disableSaveButton =
      !selectedValue ||
      selectedValue?.length === 0 ||
      !values?.start ||
      !values?.current ||
      !values?.target ||
      ((values.start === start || `${values.start}.00` === start) &&
        (values.current === current || `${values.current}.00` === current) &&
        (values.target === target || `${values.target}.00` === target));

    const UserName =
      responsible_person?.first_name && responsible_person?.last_name
        ? responsible_person?.first_name.substring(0, 1) +
        responsible_person?.last_name.substring(0, 1)
        : responsible_person?.name
          ? responsible_person?.name.split(" ").length > 1
            ? responsible_person.name.split(" ")[0].substring(0, 1) +
            responsible_person.name.split(" ")[1].substring(0, 1)
            : responsible_person.name.substring(0, 2)
          : responsible_person?.email?.substring(0, 2);

    const isWeight = isColumnVisible(customizeColumns, "weight");
    const isPlanProgress = isColumnVisible(customizeColumns, "plan_progress");
    const isStatus = isColumnVisible(customizeColumns, "status");
    const isMeasurement = isColumnVisible(customizeColumns, "measurement");
    const isStartLine = isColumnVisible(customizeColumns, "start_date");
    const isEndLine = isColumnVisible(customizeColumns, "due_date");
    const isDuration = isColumnVisible(customizeColumns, "duration");
    const isAssignee = isColumnVisible(customizeColumns, "assignee");
    const [modalOpen, setModalOpen] = useState(false);
    const handleAddMetric = () => {
      setModalOpen(true);
      setMeasurementPop(false);
    };

    const handleClose = () => {
      setUpdateStatusModel(false);
      setUpdateStatus({
        name: "",
        status: 0,
        oldStatus: 0,
        oldPlanProgress: 0,
        newPlanProgress: null,
      });
    };

    const handleUpdate = () => {
      if (updateStatus.newPlanProgress === null) {
        toast.error("Please select new plan progress");
      } else {
        const payload = {
          manual_override: "True",
          status: updateStatus.status,
          plan_progress: updateStatus.newPlanProgress,
        };

        dispatch(updateManualStatus(id, payload, projectPlanId, handleClose));

        setSelectStatus(
          updateStatus.newPlanProgress === 10
            ? 4
            : updateStatus.newPlanProgress === 0
              ? 1
              : 2
        );
        setActionProgress(updateStatus.newPlanProgress);
      }
    };

    const handleCloseLoading = () => {
      setUpdateProgressLoading(false);
    }

    const updateActionStatus = (value: number) => {
      let statusvalue: number = status;
      if (value === 10) {
        statusvalue = 4;
      } else if (value === 0) {
        statusvalue = 1;
      } else {
        statusvalue = 2;
      }
      setUpdateProgressLoading(true);
      setIsProgressLoading && setIsProgressLoading(true)
      setSelectStatus(statusvalue);
      setActionProgress(value);
      dispatch(
        upateActionItem(id, { plan_progress: value }, projectPlanId, true, handleCloseLoading)
      );
    };

    const onActionItemKeyUp = (e: any) => {
      if (e.key === "Enter") {
        handleChangeItemName();
      }

      if (e.key === "Escape") {
        setIsEditName(false);
        setEditNameValue(name ?? "");
      }
    };

    const handleCloseAction = () => {
      setIsEditName(false);
      setEditNameValue(name ?? "");
      toast.success(
        <div className="undo-button">
          <h6>Action item name updated successfully.</h6>
          <div className="undo-button-content" onClick={handleUndo}>
            <UndoIcon />
            Undo
          </div>
        </div>,
        {
          position: "top-right",
          autoClose: 5000,
        }
      );
    };

    const handleUndo = () => {
      const payload = {
        action_item: {
          name: name,
          id: id,
        },
      };

      dispatch(createNewActionItemsModal(payload, projectPlanId, null, false));
    };

    const handleChangeItemName = async () => {
      if (!editNameValue || editNameValue === "" || editNameValue === name)
        return;

      setOldData({
        name: name,
        id: id,
      });

      const payload = {
        action_item: {
          name: editNameValue,
          id: id,
        },
      };

      dispatch(
        createNewActionItemsModal(
          payload,
          projectPlanId,
          null,
          handleCloseAction,
          false
        )
      );
    };

    return (
      <>
        <Modal
          footer={null}
          closable={false}
          onCancel={handleClose}
          title={false}
          open={updateStatusModel}
          centered
          width={460}
          className="metricModel"
        >
          <div className="model-header">
            <Row justify="space-between">
              <h3>Update plan progress</h3>
              <Cross className={"c-pointer"} onClick={handleClose} />
            </Row>
          </div>

          <UpdateStatusContent>
            <h3>
              In order to change the status of your action items you need to
              update the project.
            </h3>

            <label htmlFor="">Action Item Name</label>
            <h4>{updateStatus?.name}</h4>

            <label htmlFor="">Status</label>
            <StatusText>
              {updateStatus?.newPlanProgress === null ? (
                StatusOptions.find(
                  (option: any) => option.value === updateStatus.oldStatus
                )?.icon
              ) : updateStatus?.newPlanProgress === 10 ? (
                <CompleteIcon />
              ) : updateStatus?.newPlanProgress === 0 ? (
                <TodoIcon />
              ) : updateStatus?.newPlanProgress >= 1 &&
                updateStatus?.newPlanProgress <= 9 ? (
                <InProgessIcon />
              ) : null}

              {updateStatus?.newPlanProgress === null
                ? StatusOptions.find(
                  (option: any) => option.value === updateStatus.oldStatus
                )?.label
                : updateStatus?.newPlanProgress === 10
                  ? "Completed"
                  : updateStatus?.newPlanProgress === 0
                    ? "To Do"
                    : updateStatus?.newPlanProgress >= 1 &&
                      updateStatus?.newPlanProgress <= 9
                      ? "In Progress"
                      : null}
            </StatusText>

            <label htmlFor="">Current Plan Progress</label>
            <h4>{updateStatus.oldPlanProgress || 0}</h4>

            <NewPlanProgress>
              <h2>New Plan Progress</h2>

              <Select
                style={{
                  width: "220px",
                  border: "1px solid #E5E5E5",
                }}
                onSelect={(value) => {
                  setUpdateStatus({
                    ...updateStatus,
                    newPlanProgress: value,
                  });
                }}
                placeholder="Select"
                value={updateStatus?.newPlanProgress}
                suffixIcon={<ArrowDownGray />}
                // @ts-ignore
                getPopupContainer={(trigger) => trigger.parentElement}
              >
                {categoryCount.map(
                  (count: { value: number; key: string }, index: number) => (
                    <Option key={index} value={count.value}>
                      {count.key}
                    </Option>
                  )
                )}
              </Select>
            </NewPlanProgress>
          </UpdateStatusContent>

          <UpdateStatusFooter>
            <button onClick={handleClose} disabled={updateStatusLoading}>
              Cancel
            </button>
            <button
              onClick={handleUpdate}
              disabled={
                updateStatusLoading || updateStatus?.newPlanProgress === null
              }
            >
              {updateStatusLoading ? <LoadingOutlined /> : "Save"}
            </button>
          </UpdateStatusFooter>
        </Modal>

        <DeleteItemModal
          itemId={id}
          itemName={name ?? ""}
          planId={projectPlanId}
          type="action"
          isVisible={isDeleted}
          onClose={() => setIsDeleted(false)}
          checkedItems={checkedItems}
          setCheckedItems={setCheckedItems}
        />

        <AddNoteModal
          allData={data}
          data={notesData}
          isVisible={isNote}
          isName={itemName}
          isEdit={isEditNote}
          isAdd={isAddNote}
          actionItemId={id}
          planId={projectPlanId}
          onClose={() => {
            setIsNote(false);
            setIsEditNote(false);
            setIsAddNote(false);
          }}
        />

        {isNoteModel && (
          <ActionItemsModal
            isActionItem
            allData={data}
            data={notesData}
            isName={itemName}
            actionItemsModal={isNoteModel}
            setActionItemsModal={setIsNoteModel}
            projectPlanId={projectPlanId}
            PlanDetails={PlanDetails}
            isOnwer={isOnwer}
          />
        )}

        <Accordion as={Col} activeKey={isExpand && !isEdit ? "1" : "0"}>
          <InputGroup>
            <NameContent $disabled={isShared}>
              <NameContainer
                $collapsed={notesData ? !(isExpand && !isEdit) : true}
              >
                <NameWrapper ref={nameInputRef}>
                  {isAdd && !isShared ? (
                    <>
                      <InputField
                        name="name"
                        value={itemName}
                        parentStyle={{ width: "100%" }}
                        placeholder="Action item name"
                        ref={$inputRef}
                        onChange={handleChange}
                        onKeyDown={handleKeyDown}
                        data-testid="name-input"
                        style={{ width: "100%" }}
                      />

                      <Button
                        size="md"
                        styleType="secondary"
                        style={{
                          whiteSpace: "nowrap",
                        }}
                        onClick={(e: any) => {
                          e.stopPropagation();
                          handleBlur();
                        }}
                        onMouseDown={(e: any) => {
                          e.preventDefault();
                          e.stopPropagation();
                          handleBlur();
                        }}
                      >
                        Cancel
                      </Button>
                      <Button
                        size="md"
                        style={{
                          whiteSpace: "nowrap",
                        }}
                        onCLick={(e: any) => {
                          e.stopPropagation();
                          changeName();
                        }}
                        onMouseDown={(e: any) => {
                          e.stopPropagation();
                          changeName();
                        }}
                        disabled={isNew && !itemName && itemName === ""}
                      >
                        Save
                      </Button>
                    </>
                  ) : isEditName ? (
                    <>
                      <InputField
                        name="name"
                        value={editNameValue}
                        ref={$inputRef}
                        onChange={(e: any) => setEditNameValue(e.target.value)}
                        parentStyle={{ width: "100%" }}
                        onKeyUp={onActionItemKeyUp}
                        onBlur={handleBlur}
                        placeholder="Action item name"
                        style={{
                          padding: "8px 10px",
                          width: "100%",
                          border: "1px solid #71AACA",
                          borderRadius: "4px",
                          boxShadow: "0px 0px 0px 2px rgba(208, 227, 237, 1)",
                          fontSize: "14px",
                          fontWeight: "400",
                          color: "#404040",
                        }}
                      />

                      <Button
                        size="md"
                        styleType="secondary"
                        style={{
                          whiteSpace: "nowrap",
                        }}
                        onClick={() => {
                          setIsEditName(false);
                          setEditNameValue(name ?? "");
                        }}
                        disabled={updateActionitemLoading}
                      >
                        Cancel
                      </Button>
                      <Button
                        size="md"
                        style={{
                          whiteSpace: "nowrap",
                        }}
                        disabled={
                          !editNameValue ||
                          editNameValue === "" ||
                          editNameValue === name ||
                          updateActionitemLoading
                        }
                        onClick={() => {
                          handleChangeItemName();
                        }}
                        loading={updateActionitemLoading}
                      >
                        {updateActionitemLoading ? <LoadingOutlined /> : "Save"}
                      </Button>
                    </>
                  ) : (
                    <span
                      data-testid="name-value"
                      onDoubleClick={() => {
                        setIsEditName(true);
                        setEditNameValue(name ?? "");
                      }}
                      style={{
                        textDecoration:
                          Number(actionProgress) === 10
                            ? "line-through"
                            : "none",
                        cursor: "default",
                      }}
                    >
                      {name}
                    </span>
                  )}
                </NameWrapper>

                <div>
                  {isNew ? null : (
                    <>
                      {!isShared && !isEditName && (
                        <NoteContainer className="action-items">
                          <EditIcon
                            onClick={handleEdit}
                            className="cursor-pointer"
                          />

                          <DeleteIcon
                            onClick={() => setIsDeleted(true)}
                            className="cursor-pointer"
                          />
                        </NoteContainer>
                      )}
                    </>
                  )}
                </div>
              </NameContainer>
            </NameContent>
          </InputGroup>
        </Accordion>

        {customizeColumns?.map((column: any) => {
          const key = column?.key;

          if (key === "weight") {
            return (
              isWeight && (
                <Col className={cc(classes.weightSelect)}>
                  {isNew ? null : (
                    <Select
                      className={cc(classes.dropdown, classes.weight , classes.customArrow)}
                      placeholder="0"
                      dropdownMatchSelectWidth={false}
                      dropdownRender={(menu) => (
                        <div className="weightlist">{menu}</div>
                      )}
                      value={{ key: weight.toString(), value: weight / 10 }}
                      disabled={isShared}
                      onChange={(value, _) =>
                        dispatch(
                          upateActionItem(
                            id,
                            { weight: Number(value) * 10 },
                            projectPlanId,
                            true
                          )
                        )
                      }
                      data-testid="weight-select"
                    >
                      {weightsList.map(
                        (
                          count: { value: number; key: string },
                          index: number
                        ) => (
                          <Option key={index} value={count.value}>
                            {count.key}
                          </Option>
                        )
                      )}
                    </Select>
                  )}
                </Col>
              )
            );
          }

          if (key === "plan_progress") {
            return (
              isPlanProgress && (
                <Col className={cc(classes.weightSelect)}>
                  {isNew ? null : (
                    <Select
                      className={cc(
                        classes.dropdown,
                        classes.weight,
                        !updateProgressLoading && classes.customArrow
                      )}
                      placeholder="0"
                      dropdownMatchSelectWidth={false}
                      dropdownRender={(menu) => (
                        <div className="weightlist">{menu}</div>
                      )}
                      value={
                        actionProgress
                          ? {
                            key: actionProgress.toString(),
                            value: actionProgress,
                          }
                          : null
                      }
                      suffixIcon={
                        updateProgressLoading ?
                      <LoadingOutlined 
                        style={{ 
                          fontSize: '12px',
                          color: '#8f8f90'
                        }} 
                        spin 
                      /> : null}
                      disabled={isShared}
                      onChange={(value, _) => updateActionStatus(Number(value))}
                      data-testid="progress-select"
                    >
                      {categoryCount.map(
                        (
                          count: { value: number; key: string },
                          index: number
                        ) => (
                          <Option key={index} value={count.value}>
                            {count.key}
                          </Option>
                        )
                      )}
                    </Select>
                  )}
                </Col>
              )
            );
          }

          if (key === "status") {
            return isNew
              ? null
              : isStatus && (
                <Col>
                  {isNew ? null : (
                    <Select
                      className="status-select"
                      style={{
                        width: "100%",
                      }}
                      disabled={isShared}
                      placeholder="Select status"
                      value={selectStatus || 1}
                      onSelect={(value) => {
                        if (value === 3 || value === 5) {
                          const payload = {
                            manual_override: "True",
                            status: value,
                          };

                          dispatch(
                            updateManualStatus(
                              id,
                              payload,
                              projectPlanId,
                              handleClose
                            )
                          );
                          setSelectStatus(value);
                        } else if (value !== selectStatus) {
                          setUpdateStatusModel(true);
                          setUpdateStatus({
                            name: name,
                            status: value,
                            oldStatus: selectStatus,
                            oldPlanProgress: plan_progress,
                            newPlanProgress: null,
                          });
                        }
                      }}
                      // @ts-ignore
                      getPopupContainer={(trigger) => trigger.parentElement}
                    >
                      {StatusOptions.map((option: any) => (
                        <Select.Option
                          key={option.value}
                          value={option.value}
                        >
                          {option.icon}
                          <span style={{ marginLeft: 8, color: "#000000d9" }}>
                            {option.label}
                          </span>
                        </Select.Option>
                      ))}
                    </Select>
                  )}
                </Col>
              );
          }

          if (key === "measurement") {
            return isNew
              ? null
              : isMeasurement && (
                <Col>
                  <Popover
                    content={
                      <div className="measurement-popover">
                        <div className="measurement-popover-items">
                          <div className="measurement-popover-item">
                            <label>Metric</label>

                            <SelectMetric
                              onAddMetric={handleAddMetric}
                              selectedValue={selectedValue}
                              onValueChange={setSelectedValue}
                              selectedUnit={selectedUnit}
                              setIsAddMetric={setIsAddMetric}
                            />
                          </div>
                          <div className="measurement-popover-item">
                            <label>Start</label>
                            <input
                              name="start"
                              type="number"
                              value={values?.start || ""}
                              placeholder="0"
                              disabled={
                                isShared ||
                                !isOnwer ||
                                selectedValue?.length === 0
                              }
                              onChange={handleChangeInput}
                              style={{ width: "50px" }}
                            />
                          </div>

                          <div className="measurement-popover-item">
                            <label>Current</label>
                            <input
                              name="current"
                              type="number"
                              value={values?.current || ""}
                              placeholder="0"
                              disabled={
                                isShared || selectedValue?.length === 0
                              }
                              onChange={handleChangeInput}
                              style={{ width: "50px" }}
                            />
                          </div>

                          <div className="measurement-popover-item">
                            <label>Target</label>

                            <input
                              name="target"
                              type="number"
                              value={values?.target || ""}
                              placeholder="0"
                              disabled={
                                isShared || selectedValue?.length === 0
                              }
                              onChange={handleChangeInput}
                              style={{ width: "50px" }}
                            />
                          </div>
                        </div>

                        <div className="measurement-popover-actions">
                          <button
                            onClick={() => {
                              setMeasurementPop(false);
                              setSelectedValue([]);
                              setValues({
                                start: start || null,
                                current: current || null,
                                target: target || null,
                              });
                            }}
                          >
                            Cancel
                          </button>
                          <button
                            onClick={handleUpdateMeasurement}
                            disabled={isShared || disableSaveButton}
                          >
                            Save
                          </button>
                        </div>
                      </div>
                    }
                    trigger="click"
                    placement="bottomRight"
                    overlayClassName="measurement-popover-overlay"
                    open={isShared ? false : measurementPop}
                    onOpenChange={(value) => {
                      setMeasurementPop(value);
                    }}
                    // builtinPlacements={{
                    //   bottomRight: {
                    //     points: ["tc", "bc"],
                    //     offset: [-50, 10],
                    //   },
                    // }}
                    autoAdjustOverflow
                    // @ts-ignore
                    getPopupContainer={(trigger) => trigger.parentElement}
                  >
                    <div
                      style={{
                        cursor: "pointer",
                      }}
                    >
                      {current || "--"}
                    </div>
                  </Popover>
                </Col>
              );
          }

          if (key === "start_date") {
            return (
              isStartLine && (
                <Col>
                  <div className={classes.datePicker_wrapper}>
                    {isNew ? null : isShared ? (
                      start_line ? (
                        moment(start_line).format("YYYY-MM-DD")
                      ) : null
                    ) : (
                      <DatePicker
                        className={classes.datepicker}
                        format="MMM D"
                        // suffixIcon={<LockIcon />}
                        suffixIcon={<CalanderIcon />}
                        disabledDate={(current) =>
                          isDateDisabled(current, "start_line")
                        }
                        allowClear={false}
                        disabled={isShared || !isOnwer}
                        value={start_line ? moment(start_line) : null}
                        onChange={(date, value) => {
                          if (date) {
                            const formattedDate = date.format("YYYY-MM-DD");
                            dispatch(
                              upateActionItem(
                                id,
                                {
                                  start_line: value ? formattedDate : null,
                                },
                                projectPlanId,
                                true
                              )
                            );
                          } else {
                            dispatch(
                              upateActionItem(
                                id,
                                {
                                  start_line: null,
                                },
                                projectPlanId,
                                true
                              )
                            );
                          }
                        }}
                        data-testid="start-date"
                      />
                    )}
                    {startLineError && (
                      <ErrorTooltip title={startLineError || ""} />
                    )}

                    {start_line && !isShared && (
                      <div className={classes.closeIcon}>
                        <CloseIcon
                          onClick={() => {
                            if (isOnwer) {
                              dispatch(
                                upateActionItem(
                                  id,
                                  {
                                    start_line: null,
                                  },
                                  projectPlanId,
                                  true
                                )
                              );
                            }
                          }}
                        />
                      </div>
                    )}
                  </div>
                </Col>
              )
            );
          }

          if (key === "due_date") {
            return (
              isEndLine && (
                <Col>
                  {isNew ? null : isShared ? (
                    dead_line ? (
                      moment(dead_line).format("YYYY-MM-DD")
                    ) : null
                  ) : (
                    <div className={classes.datePicker_wrapper}>
                      <DatePicker
                        className={classes.datepicker}
                        format="MMM D"
                        suffixIcon={<CalanderIcon />}
                        allowClear={false}
                        disabledDate={(current) =>
                          isDateDisabled(current, "dead_line")
                        }
                        disabled={isShared || !isOnwer}
                        value={dead_line ? moment(dead_line) : null}
                        onChange={(date, value) => {
                          if (date) {
                            const formattedDate = date.format("YYYY-MM-DD");
                            dispatch(
                              upateActionItem(
                                id,
                                {
                                  dead_line: value ? formattedDate : null,
                                },
                                projectPlanId,
                                true
                              )
                            );
                          } else {
                            dispatch(
                              upateActionItem(
                                id,
                                {
                                  dead_line: null,
                                },
                                projectPlanId,
                                true
                              )
                            );
                          }
                        }}
                        data-testid="end-date"
                      />
                      {deadLineError && (
                        <ErrorTooltip title={deadLineError || ""} />
                      )}

                      {dead_line && !isShared && (
                        <div className={classes.closeIcon}>
                          <CloseIcon
                            onClick={() => {
                              if (isOnwer) {
                                dispatch(
                                  upateActionItem(
                                    id,
                                    {
                                      dead_line: null,
                                    },
                                    projectPlanId,
                                    true
                                  )
                                );
                              }
                            }}
                          />
                        </div>
                      )}
                    </div>
                  )}
                </Col>
              )
            );
          }

          if (key === "duration") {
            return (
              isDuration && (
                <Col>
                  {isNew ? null : (
                    <Select
                      className={cc(classes.dropdown, classes.duration)}
                      placeholder="Select duration"
                      dropdownMatchSelectWidth={false}
                      value={duration === "00:00:00" ? "-" : duration}
                      disabled={isShared}
                      onChange={(value, _) =>
                        dispatch(
                          upateActionItem(
                            id,
                            { duration: value },
                            projectPlanId,
                            true
                          )
                        )
                      }
                      data-testid="duration-select"
                    >
                      {durationList.map(
                        (
                          count: { value: string; key: string },
                          index: number
                        ) => (
                          <Option key={index} value={count.value}>
                            {count.key}
                          </Option>
                        )
                      )}
                    </Select>
                  )}
                </Col>
              )
            );
          }

          if (key === "assignee") {
            return isNew
              ? null
              : isAssignee && (
                <Col>
                  {" "}
                  <Select
                    onSelect={(value) => {
                      dispatch(
                        upateActionItem(
                          id,
                          { responsible_person: value },
                          projectPlanId,
                          true
                        )
                      );

                      setEmailToSearch("");
                    }}
                    // value={responsible_person?.email?.substring(0, 2)}
                    value={UserName}
                    onSearch={(e) => setEmailToSearch(e)}
                    className={cc(classes.dropdown, classes.person)}
                    dropdownMatchSelectWidth={false}
                    dropdownStyle={{ minWidth: 200 }}
                    size="large"
                    filterOption={false}
                    showSearch
                    popupClassName="testing-popup"
                    notFoundContent={
                      validateEmail(emailToSearch) ? (
                        <Button
                          onClick={() => {
                            setEmailToInvite(emailToSearch);
                          }}
                        >
                          Invite {emailToSearch}
                        </Button>
                      ) : (
                        "No results. Enter email to invite"
                      )
                    }
                    disabled={isShared}
                    placeholder="Select user"
                    data-testid="owner-select"
                  // open
                  >
                    {filteredUsers?.map((owner: any, index: number) => {
                      return (
                        <Option
                          style={{ zIndex: "99999" }}
                          key={index}
                          value={owner?.id}
                          className={
                            owner?.public_id == responsible_person?.id ||
                              owner?.id == responsible_person?.id
                              ? classes.selected
                              : ""
                          }
                        >
                          <div className="hello">{owner?.email}</div>
                        </Option>
                      );
                    })}
                  </Select>
                </Col>
              );
          }
        })}

        <ManageCustomMetricsModal
          IsAddMetric={IsAddMetric}
          setIsAddMetric={setIsAddMetric}
          visible={modalOpen}
          setModalOpen={setModalOpen}
          goalId={planDetails?.goal?.id}
          project_plan={projectPlanId}
        />

        {isNew ? null : <Col></Col>}

        <InviteUserModal
          isActive={!!emailToInvite}
          emailToInvite={emailToInvite ?? ""}
          onClose={() => setEmailToInvite(null)}
        />
      </>
    );
  }
);
