import { useEffect, useState } from "react";

import { Modal } from "antd";

// @ts-ignore
import styles from "./styles.module.scss";
// @ts-ignore

import { useDispatch, useSelector } from "react-redux";
// @ts-ignore
import { websocketConnection } from "utils/websocket";

import { ReactComponent as ArootahStarLogoBig } from "assets/svg/arootahStarBig.svg";
import { ReactComponent as ArootahStarLogoSmall } from "assets/svg/arootahStar.svg";
import { ReactComponent as AriaModelArrowLeft } from "assets/svg/ArrowLeftBluee.svg";
import { ReactComponent as AriaModelArrowRight } from "assets/svg/ArrowRighBlue.svg";
import { ReactComponent as EmptySvg } from "assets/svg/empty.svg";
import { ReactComponent as CloseBox } from "assets/svg/closeBox.svg";
import { ReactComponent as Regenerate } from "assets/svg/reloadGray.svg";
import { ReactComponent as Plus } from "assets/svg/Plus.svg";
import { ReactComponent as Calander } from "assets/svg/calander.svg";
import { ReactComponent as MoveIcon } from "../../scenes/PlanDetails/ActionItems/Item/move.svg";
import { LoadingOutlined } from "@ant-design/icons";

// @ts-ignore
import loaderIcon from "assets/svg/assistant-loader.gif";

import { useNavigate } from "react-router-dom";
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";
// @ts-ignore
import { addBulkCategory } from "modules/actions/PlanActions";
import {
  convertUrlToBase64,
  formatDuration,
  formatDurationInHours,
} from "../../utils/helper";

const AriaModel = ({
  visible,
  onCancel,
}: {
  visible: boolean;
  onCancel: () => void;
  planDetails: any;
}) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const [dots, setDots] = useState("");
  const [loading, setLoading] = useState(false);
  const [isErorr, setIsError] = useState(false);
  const [data, setData] = useState<any>([]);
  const [currentIndex, setCurrentIndex] = useState(0);
  const [isSubmitLoading, setIsSubmitLoading] = useState(false);
  const [fileList, setFileList] = useState<any>([]);

  const [checkedItems, setCheckedItems] = useState<{
    [key: number]: {
      category: boolean;
      items: { [key: number]: boolean };
    };
  }>({});

  const { LoadingGoals, planDetails } = useSelector(
    (state: any) => state.plan.plan
  );

  const handleError = (message: any) => {
    setIsError(true);
    setLoading(false);
  };

  function get_url_name(url: any) {
    const a = url?.split("/");
    const b = a?.length > 0 && a[a?.length - 1];
    return b;
  }

  const setFileFormat = () => {
    const list: any = [];
    planDetails?.resource?.files?.map((file: any) => {
      list.push({
        uid: file?.id,
        name: get_url_name(file?.url),
        status: "done",
        url: file?.url,
      });
    });
    return list || [];
  };

  const handleMessage = (message: any) => {
    let res = JSON?.parse(message);
    if (res?.ping || res?.pong) return;
    if (res?.message?.Categories?.length > 0) {
      setLoading(false);
      setData(res?.message?.Categories);
    } else {
      setIsError(true);
      setLoading(false);
    }
  };

  const handleSubmit = async (e: any) => {
    e.preventDefault();
    setLoading(true);

    try {
      const filePromises = fileList?.map(async (file: any) => {
        const base64Data = await convertUrlToBase64(file.url);
        return {
          file_data: base64Data,
          filename: file.name,
        };
      });

      const files = await Promise.all(filePromises || []);
      const validFiles = files?.filter((file: any) => file.file_data !== null);

      websocketConnection.send(
        JSON.stringify({
          endpoint: "generate_project_action_suggestions",
          project_name: planDetails?.name || "",
          goal_id: planDetails?.goal?.id || "",
          objective: planDetails?.objective || "",
          duration: planDetails?.duration || "",
          purpose: planDetails?.purpose || "",
          brainstorm_ideas: planDetails?.idea || "",
          start_date: planDetails?.start_line || "",
          end_date: planDetails?.dead_line || "",
          files: validFiles || [],
        })
      );
    } catch (error) {
      console.error("Error processing files:", error);
      setLoading(false);
    }
  };

  const currentItem = data[currentIndex];

  const handleDragEnd = (result: any) => {
    if (!result.destination) return;

    const { source, destination } = result;
    const newData = [...data];
    const currentCategory = newData[currentIndex];
    const items = [...currentCategory.Action_Items];

    // Reorder the items
    const [reorderedItem] = items.splice(source.index, 1);
    items.splice(destination.index, 0, reorderedItem);

    currentCategory.Action_Items = items;
    newData[currentIndex] = currentCategory;
    setData(newData);

    setCheckedItems((prev) => {
      const newCheckedState = { ...prev };
      if (newCheckedState[currentIndex]?.items) {
        const itemStates = { ...newCheckedState[currentIndex].items };
        const sourceChecked = itemStates[source.index];
        const destinationChecked = itemStates[destination.index];

        if (source.index < destination.index) {
          for (let i = source.index; i < destination.index; i++) {
            itemStates[i] = itemStates[i + 1];
          }
        } else {
          for (let i = source.index; i > destination.index; i--) {
            itemStates[i] = itemStates[i - 1];
          }
        }
        itemStates[destination.index] = sourceChecked;

        newCheckedState[currentIndex] = {
          ...newCheckedState[currentIndex],
          items: itemStates,
        };
      }
      return newCheckedState;
    });
  };

  const handleCategoryCheck = (categoryIndex: number) => {
    setCheckedItems((prev) => {
      const newState = { ...prev };
      const isCurrentlyChecked = prev[categoryIndex]?.category || false;

      newState[categoryIndex] = {
        category: !isCurrentlyChecked,
        items: data[categoryIndex].Action_Items.reduce(
          (acc: any, _: any, index: number) => {
            acc[index] = !isCurrentlyChecked;
            return acc;
          },
          {} as { [key: number]: boolean }
        ),
      };

      return newState;
    });
  };

  const handleItemCheck = (categoryIndex: number, itemIndex: number) => {
    setCheckedItems((prev) => {
      const newState = { ...prev };
      if (!newState[categoryIndex]) {
        newState[categoryIndex] = {
          category: false,
          items: {},
        };
      }

      if (!newState[categoryIndex].items) {
        newState[categoryIndex].items = {};
      }

      newState[categoryIndex].items[itemIndex] =
        !prev[categoryIndex]?.items?.[itemIndex];

      const anyItemChecked = Object.values(newState[categoryIndex].items).some(
        (checked) => checked
      );
      newState[categoryIndex].category = anyItemChecked;

      return newState;
    });
  };

  useEffect(() => {
    websocketConnection?.setMessageCallback(handleMessage, handleError);

    return () => {
      websocketConnection?.setMessageCallback(null);
    };
  }, []);

  useEffect(() => {
    if (planDetails) {
      setFileList(setFileFormat());
    }
  }, [planDetails]);

  useEffect(() => {
    if (loading) {
      const interval = setInterval(() => {
        setDots((prev) => {
          if (prev === "...") return "";
          return prev + ".";
        });
      }, 500);

      return () => clearInterval(interval);
    }
  }, [loading]);

  useEffect(() => {
    if (visible && planDetails) {
      setLoading(true);

      const handleSubmit = async () => {
        const filePromises = fileList.map(async (file: any) => {
          const base64Data = await convertUrlToBase64(file.url);
          return {
            file_data: base64Data,
            filename: file.name,
          };
        });

        const files = await Promise.all(filePromises || []);

        const validFiles = files?.filter(
          (file: any) => file.file_data !== null
        );

        websocketConnection.send(
          JSON.stringify({
            endpoint: "generate_project_action_suggestions",
            project_name: planDetails?.name || "",
            goal_id: planDetails?.goal?.id || "",
            objective: planDetails?.objective || "",
            duration: planDetails?.duration || "",
            purpose: planDetails?.purpose || "",
            brainstorm_ideas: planDetails?.idea || "",
            start_date: planDetails?.start_line || "",
            end_date: planDetails?.dead_line || "",
            files: validFiles || [],
          })
        );

        setCurrentIndex(0);
        setData([]);
        setCheckedItems({});
      };

      handleSubmit();
    }
  }, [visible]);

  const handleCancel = () => {
    setIsSubmitLoading(false);
    onCancel();
  };

  const handleAddToProject = async () => {
    setIsSubmitLoading(true);
    const selectedCategories = data
      .map((category: any, categoryIndex: number) => {
        const checkedActionItems = category.Action_Items.filter(
          (_: any, itemIndex: number) =>
            checkedItems[categoryIndex]?.items[itemIndex]
        ).map((item: any) => ({
          project_plan: planDetails?.id,
          name: item?.name,
          weight: item?.weight,
          priority:
            item?.priority === "High" ? 1 : item?.priority === "Medium" ? 2 : 3,
          start_line: item?.start_date,
          dead_line: item?.end_date,
          duration: formatDurationInHours(item?.duration),
        }));

        if (checkedActionItems.length > 0) {
          return {
            name: category.name,
            start_line: category.start_date,
            dead_line: category.end_date,
            duration: formatDurationInHours(category.duration),
            project_plan: planDetails?.id,
            action_items: checkedActionItems,
            priority:
              category?.priority === "High"
                ? 1
                : category?.priority === "Medium"
                ? 2
                : 3,
          };
        }
        return null;
      })
      .filter(Boolean);

    const payload = {
      categories: selectedCategories,
    };

    await dispatch(addBulkCategory(payload, planDetails?.id, handleCancel));
  };

  return (
    <Modal
      open={visible}
      title={false}
      footer={false}
      closable={false}
      width={1080}
      onCancel={onCancel}
      centered
      className="disablePadding"
    >
      {LoadingGoals ? (
        <div className={styles.loader}>
          <img src={loaderIcon} alt="loader" width={52} height={53} />
        </div>
      ) : loading ? (
        <div className={styles.loader}>
          <ArootahStarLogoBig />

          <h1
            className={styles.gradientText}
            style={{
              fontSize: "24px",
            }}
          >
            Generating {dots}
          </h1>
        </div>
      ) : isErorr ? (
        <div className={styles.loader}>
          <h4 className={styles.skip} onClick={onCancel}>
            <CloseBox />
          </h4>

          <EmptySvg />
          <h1 className={styles.errorText}>
            AI interruption, please try again later.{" "}
          </h1>
          <h5 className={styles.notYou}>
            Aria timed out while generating your project plan. Please try again.
          </h5>
          <button className={styles.tryAgainButton} onClick={handleSubmit}>
            Try Again
          </button>
        </div>
      ) : (
        <div className={styles.ariaModel}>
          <div className={styles.ariaModel_header}>
            <div className={styles.ariaModel_header_left}>
              <ArootahStarLogoSmall />
              <h1>Aria Suggestions for Categories and Action Items</h1>
            </div>

            <CloseBox className="cursor-pointer" onClick={onCancel} />
          </div>
          <div className={styles.ariaModel_suggestion}>
            <h4>
              Review Category and Action Items suggestions and add them to the
              Project. You can always Regenerate suggestions to get updated
              ones.
            </h4>

            <div className={styles.ariaModel_suggestion_actions}>
              <button
                onClick={() =>
                  currentIndex > 0 && setCurrentIndex(currentIndex - 1)
                }
              >
                <AriaModelArrowLeft />
              </button>
              <span>
                {currentIndex + 1} of {data.length}
              </span>
              <button
                onClick={() =>
                  currentIndex < data.length - 1 &&
                  setCurrentIndex(currentIndex + 1)
                }
              >
                <AriaModelArrowRight />
              </button>
            </div>
          </div>
          <div className={styles.ariaModel_body}>
            <ul className={styles.listHeader}>
              <li>Category & Action Items</li>
              <li>Weight</li>
              <li>Measurement</li>
              <li>Start Date</li>
              <li>Due Date</li>
              <li>Duration</li>
            </ul>

            <div className={styles.planList_container}>
              <div className={styles.planList_category}>
                <input
                  type="checkbox"
                  checked={checkedItems[currentIndex]?.category || false}
                  onChange={() => handleCategoryCheck(currentIndex)}
                />
                <h2 className={styles.title}>{currentItem?.name}</h2>
                <span className={styles.weight}>--</span>
                <span className={styles.measurement}>--</span>
                <span className={styles.deadline}>
                  {new Date(currentItem?.start_date).toLocaleDateString(
                    "en-US",
                    { month: "short", day: "numeric" }
                  )}
                  <Calander />
                </span>
                <span className={styles.deadline}>
                  {new Date(currentItem?.end_date).toLocaleDateString("en-US", {
                    month: "short",
                    day: "numeric",
                  })}
                  <Calander />
                </span>
                <span className={styles.duration}>
                  {formatDuration(currentItem?.duration)}
                </span>
              </div>

              <DragDropContext onDragEnd={handleDragEnd}>
                <Droppable droppableId="action-items">
                  {(provided) => (
                    <div {...provided.droppableProps} ref={provided.innerRef}>
                      {currentItem?.Action_Items?.map(
                        (item: any, index: number) => (
                          <Draggable
                            key={index}
                            draggableId={`item-${index}`}
                            index={index}
                          >
                            {(provided, snapshot) => (
                              <div
                                ref={provided.innerRef}
                                {...provided.draggableProps}
                                className={styles.planList}
                              >
                                <input
                                  type="checkbox"
                                  checked={
                                    checkedItems[currentIndex]?.items[index] ||
                                    false
                                  }
                                  onChange={() =>
                                    handleItemCheck(currentIndex, index)
                                  }
                                />
                                <div {...provided.dragHandleProps}>
                                  <MoveIcon />
                                </div>
                                <div className={styles.title}>
                                  <h2>{item?.name}</h2>
                                  <h4
                                    className={styles.perioty}
                                    style={{
                                      backgroundColor:
                                        item?.priority === "High"
                                          ? "#FAD6D6"
                                          : item?.priority === "Medium"
                                          ? "#D0E3ED"
                                          : "#EDFDC6",
                                      color:
                                        item?.priority === "High"
                                          ? "#F00000"
                                          : item?.priority === "Medium"
                                          ? "#1271A6"
                                          : "#458C0A",
                                    }}
                                  >
                                    {item?.priority === "High"
                                      ? "High"
                                      : item?.priority === "Medium"
                                      ? "Medium"
                                      : "Low"}
                                  </h4>
                                </div>
                                <span className={styles.weight}>
                                  {item.weight}
                                </span>
                                <span className={styles.measurement}>--</span>
                                <span className={styles.deadline}>
                                  {new Date(item.start_date).toLocaleDateString(
                                    "en-US",
                                    { month: "short", day: "numeric" }
                                  )}
                                  <Calander />
                                </span>
                                <span className={styles.deadline}>
                                  {new Date(item.end_date).toLocaleDateString(
                                    "en-US",
                                    { month: "short", day: "numeric" }
                                  )}
                                  <Calander />
                                </span>
                                <span className={styles.duration}>
                                  {formatDuration(item?.duration)}
                                </span>
                              </div>
                            )}
                          </Draggable>
                        )
                      )}
                      {provided.placeholder}
                    </div>
                  )}
                </Droppable>
              </DragDropContext>
            </div>
          </div>
          <div className={styles.ariaModel_footer}>
            <button className={styles.regenerate} onClick={handleSubmit}>
              <Regenerate />
              Regenerate
            </button>

            <div className={styles.ariaModel_footer_actions}>
              <button onClick={onCancel} disabled={isSubmitLoading}>
                Cancel
              </button>
              <button
                onClick={handleAddToProject}
                disabled={
                  isSubmitLoading ||
                  !Object.values(checkedItems).some(
                    (category) =>
                      category.category ||
                      Object.values(category.items || {}).some(
                        (checked) => checked
                      )
                  )
                }
              >
                {isSubmitLoading ? (
                  <LoadingOutlined />
                ) : (
                  <>
                    <Plus />
                    Add to project
                  </>
                )}
              </button>
            </div>
          </div>
        </div>
      )}
    </Modal>
  );
};

export default AriaModel;
