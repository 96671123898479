// const webSocketURL = "ws://project-planner-36424-staging.botics.co/ws/chat/";

// class WebSocketConnection {
//   constructor() {
//     this.socket = null;
//   }

//   connect() {
//     this.socket = new WebSocket(webSocketURL);

//     this.socket.onopen = () => {
//       console.log("WebSocket connected");
//       // Perform any actions upon successful connection
//     };

//     this.socket.onmessage = (event) => {
//       const message = event.data;
//       console.log("Received message:", message);
//       // Handle incoming messages
//     };

//     this.socket.onclose = () => {
//       console.log("WebSocket disconnected");
//       // Handle disconnection
//     };
//   }

//   send(message) {
//     if (this.socket && this.socket.readyState === WebSocket.OPEN) {
//       this.socket.send(message);
//     } else {
//       console.log("WebSocket connection not established");
//     }
//   }

//   close() {
//     if (this.socket) {
//       this.socket.close();
//     }
//   }
// }

// export default WebSocketConnection;

// websocket.js

import { apiWebsocketURL } from "./constants";
const webSocketURL = apiWebsocketURL;

// const webSocketURL = "project-planner-36424.botics.co/ws/chat/"; // production
// const webSocketURL = "wss://project-planner-36424-staging.botics.co/ws/chat/"; //stagging

class WebSocketConnection {
  constructor() {
    this.socket = null;
    this.messageCallback = null;
    this.reconnectInterval = 1000; // Initial reconnect interval in ms
    this.maxReconnectInterval = 16000; // Max interval limit
    this.connect(); // Initial connection
  }

  connect() {
    this.socket = new WebSocket(webSocketURL);

    this.socket.onopen = () => {
      console.log("WebSocket connected");
      this.reconnectInterval = 1000; // Reset interval on successful connection
    };

    this.socket.onmessage = (event) => {
      const message = event.data;
      if (this.messageCallback) {
        this.messageCallback(message); // Pass the received message to the callback function
      }
    };

    this.socket.onclose = () => {
      console.log("WebSocket disconnected, attempting to reconnect...");
      this.reconnect();
    };

    this.socket.onerror = (error) => {
      console.error("WebSocket error:", error);
      this.socket.close(); // Close the socket to trigger reconnection
    };
  }

  reconnect() {
    setTimeout(() => {
      this.connect();
      this.reconnectInterval = Math.min(
        this.reconnectInterval * 2,
        this.maxReconnectInterval
      ); // Increase interval up to the maximum limit
    }, this.reconnectInterval);
  }

  send(message) {
    if (this.socket && this.socket.readyState === WebSocket.OPEN) {
      this.socket.send(message);
    } else {
      console.log("WebSocket connection not established");
    }
  }

  close() {
    if (this.socket) {
      this.socket.onclose = null; // Prevent reconnect on manual close
      this.socket.close();
    }
  }

  setMessageCallback(callback) {
    this.messageCallback = callback;
    this.errorCallback = callback;
  }

  isConnected() {
    return this.socket && this.socket.readyState === WebSocket.OPEN;
  }
}

export const websocketConnection = new WebSocketConnection();
