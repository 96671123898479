import { FC } from 'react';
import type { IAttachment } from '@types';

import { DownloadOutlined } from '@ant-design/icons';

import deleteIcon from "./delete.svg";

import {
  Wrapper,
  Label,
  Icon,
  Actions,
  IconBtn,
  DeleteBtn
} from "./styled";

export enum Type {
  File = 'file',
  Note = 'note',
  Link = 'link',
}

interface IItemProps {
  item: IAttachment;
  onRemove: (id: number) => void;
}

const FileItem: FC<IItemProps> = ({ item, onRemove }) => {
  const { id, file } = item;

  // Extract file name from the full path
  const getFileName = (filePath: string | null) => {
    if (!filePath) return '';
    try {
      const url = new URL(filePath);
      return url.pathname.split('/').pop() || ''; // Extract file name from URL
    } catch {
      return filePath.split('/').pop() || ''; // Fallback for non-URL paths
    }
  };

  const handleDownload = () => {
    if (!file)
      return;

    const link = document.createElement('a');

    link.href = file;
    link.download = getFileName(file);

    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  }

  const handleRemove = () => {
    onRemove(id);
  }

  const getIcon = (type: string) => {
    switch (type) {
      case Type.File:
        return <span title="Document">📃</span>;
      case Type.Note:
        return <span title="Note">📝</span>;
      case Type.Link:
        return <span title="Link">🔗</span>;
      default:
        return <span title="Note">📝</span>;
    }
  }

  return (
    <Wrapper>
      <Label $ellipsis={item.type === Type.File}>
        <Icon>{getIcon(item.type)}</Icon>
        {item.type === Type.File && getFileName(file)}
        {item.type === Type.Note && item.note}
        {item.type === Type.Link && item.external_link && (
          <a href={item.external_link} target="_blank" rel="noopener noreferrer">
            {item.external_link}
          </a>
        )}
      </Label>
      
      <Actions>
        {item.type === Type.File && (
          <IconBtn title="Download file" onClick={handleDownload}>
            <DownloadOutlined style={{ color: "#1271a6", fontSize: 14 }} />
          </IconBtn>
        )}
      
        <DeleteBtn title="Remove this document" onClick={handleRemove}>
          <img src={deleteIcon} alt="" />
        </DeleteBtn>
      </Actions>
    </Wrapper>
  );
};

export default FileItem;
