import { useEffect, useRef, useState } from "react";

import { useSelector, useDispatch } from "react-redux";

// @ts-ignore
import { AppLayout } from "components";
// @ts-ignore
import styles from "./styles.module.scss";
// @ts-ignore
import GoalSearch from "../../components/GoalSearch";
// @ts-ignore
import { websocketConnection } from "utils/websocket";

import moment from "moment";

import { ReactComponent as GoalIcon } from "assets/svg/goal.svg";
import { ReactComponent as ConnectIcon } from "assets/svg/connect.svg";
import { ReactComponent as ArootahStarLogo } from "assets/svg/arootahStar.svg";
import { ReactComponent as ReloadIcon } from "assets/svg/reload.svg";
import { getPath } from "../../components/AppSwitcher";
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";
import { ReactComponent as ArootahStarLogoBig } from "assets/svg/arootahStarBig.svg";

import { ReactComponent as Calander } from "assets/svg/calander.svg";
import { ReactComponent as MoveIcon } from "../../scenes/PlanDetails/ActionItems/Item/move.svg";
import { formatDuration, formatDurationInHours } from "../../utils/helper";
import { useNavigate, useSearchParams } from "react-router-dom";

import {
  getGaolList,
  BulkAriaProjectPlan,
  // @ts-ignore
} from "../../modules/actions/PlanActions";
import { LoadingOutlined } from "@ant-design/icons";

const PlanDetailsWizard = () => {
  const { gpsURL } = getPath();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();

  const project_name = searchParams.get("project_name");
  const objective = searchParams.get("objective");
  const goal_id = searchParams.get("goal_id");

  const [selectedGoal, setSelectedGoal] = useState<any>(null);
  const [loading, setLoading] = useState(false);
  const [isSaveLoading, setIsSaveLoading] = useState(false);

  const { wizardData, selectedGoalList, allGoalList } = useSelector(
    (state: any) => state.plan.plan
  );

  const [dots, setDots] = useState("");
  const [data, setData] = useState<any>([]);
  const [activeTextTab, setActiveTextTab] = useState(1);
  const [textEdit, setTextEdit] = useState(false);
  const [isGoalSelect, setIsGoalSelect] = useState(false);
  const [isGoalModel, setIsGoalModel] = useState(false);

  const [objectiveCopy, setObjectiveCopy] = useState("");
  const [purposeCopy, setPurposeCopy] = useState("");

  const goalRef = useRef<any>(null);

  const $objectiveRef = useRef<any>(null);
  const $purposeRef = useRef<any>(null);
  const $buttonsRef = useRef<any>([]);

  // Handle click outside
  const handleClickOutside = (event: any) => {
    if (
      !$objectiveRef.current?.contains(event.target) &&
      !$purposeRef.current?.contains(event.target) &&
      !$buttonsRef.current?.some((ref: any) => ref.contains(event.target))
    ) {
      setTextEdit(false);
    }
  };

  const handleCopyChange = (event: any, setValue: any, maxLength = 256) => {
    let input = event.target.value;

    if (input.length > maxLength) {
      input = input.slice(0, maxLength);
    }

    setValue(input);
  };

  const handleError = (message: any) => {
    handleRegenerate();
  };

  const handleMessage = (message: any) => {
    let res = JSON?.parse(message);
    if (res?.ping || res?.pong) return;
    if (res?.message?.Categories?.length > 0) {
      dispatch({
        type: "WIZARD_DATA",
        payload: res?.message,
      });
    } else {
      handleRegenerate();
    }
    setLoading(false);
  };

  const handleRegenerate = () => {
    setLoading(true);
    websocketConnection.send(
      JSON.stringify({
        endpoint: "generate_project_action_suggestions",
        project_name: project_name,
        objective: objective,
        goal_id: goal_id,
      })
    );
  };

  const handleDragEnd = (result: any) => {
    if (!result.destination) return;

    const { source, destination } = result;

    const sourceCategoryIndex = parseInt(source.droppableId.split("-")[1]);
    const destCategoryIndex = parseInt(destination.droppableId.split("-")[1]);

    const newData = JSON.parse(JSON.stringify(data));

    const [removed] = newData.Categories[
      sourceCategoryIndex
    ].Action_Items.splice(source.index, 1);

    newData.Categories[destCategoryIndex].Action_Items.splice(
      destination.index,
      0,
      removed
    );

    setData(newData);
  };

  const handleSave = () => {
    setIsSaveLoading(true);

    const payload = {
      projectDetails: {
        name: wizardData?.project_name,
        objective: objectiveCopy || "",
        purpose: purposeCopy || "",
        goalId: selectedGoal?.id
          ? selectedGoal?.id
          : goal_id !== "undefined"
          ? goal_id
          : "",
        start_line: moment(wizardData?.start_date).format("YYYY-MM-DD"),
        dead_line: moment(wizardData?.end_date).format("YYYY-MM-DD"),
      },
      categories: data?.Categories?.map((category: any) => ({
        name: category?.name,
        duration: formatDurationInHours(category?.duration),
        start_line: category?.start_date,
        dead_line: category?.end_date,
        action_items: category?.Action_Items?.map((item: any) => ({
          name: item?.name,
          // priority: item?.priority,
          weight: item?.weight,
          duration: formatDurationInHours(item?.duration),
          start_line: item?.start_date,
          dead_line: item?.end_date,
          measurement_label: item?.measurement_label,
          measurement_unit: item?.measurement_unit,
        })),
      })),
    };

    dispatch(BulkAriaProjectPlan(payload, handleCancel));
  };

  const handleCancel = () => {
    navigate("/project-planner");
    setIsSaveLoading(false);
    setIsGoalModel(false);
    setIsGoalSelect(false);
    setSelectedGoal(null);
    setData([]);
    setObjectiveCopy("");
    setPurposeCopy("");
  };

  useEffect(() => {
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  useEffect(() => {
    const handleClickOutside = (event: any) => {
      if (goalRef.current && !goalRef.current.contains(event.target)) {
        setIsGoalSelect(false);
      }
    };

    document.addEventListener("mousedown", handleClickOutside);

    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [goalRef]);

  useEffect(() => {
    websocketConnection?.setMessageCallback(handleMessage, handleError);

    return () => {
      websocketConnection?.setMessageCallback(null);
    };
  }, []);

  useEffect(() => {
    if (loading) {
      const interval = setInterval(() => {
        setDots((prev) => {
          if (prev === "...") return "";
          return prev + ".";
        });
      }, 500);

      return () => clearInterval(interval);
    }
  }, [loading]);

  useEffect(() => {
    if (wizardData) {
      setData(wizardData);
    } else {
      setLoading(true);
      dispatch(getGaolList());

      setTimeout(() => {
        handleRegenerate();
      }, 3000);
    }
  }, [wizardData]);

  useEffect(() => {
    if (goal_id) {
      setSelectedGoal(allGoalList?.find((goal: any) => goal?.id === goal_id));
    }
  }, [goal_id, allGoalList]);

  return (
    <AppLayout>
      {loading ? (
        <div className={styles.loader}>
          <ArootahStarLogoBig />

          <h1
            className={styles.gradientText}
            style={{
              fontSize: "24px",
            }}
          >
            Creating your project plan{dots}
          </h1>

          <h4 className={styles.poweredText}>
            Powered by <span>Aria™</span>
          </h4>
        </div>
      ) : (
        <div
          style={{
            padding: "0 20px",
          }}
        >
          <div>
            <div className={styles.wizardHeader}>
              <div className={styles.wizardHeader_left}>
                <ArootahStarLogo width={40} height={40} />
                <div className={styles.wizardHeader_content}>
                  <h3>Your project plan is ready</h3>
                  <h4>Here's your generated project plan.</h4>
                </div>

                <ReloadIcon
                  className={styles.reloadIcon}
                  width={28}
                  height={28}
                  onClick={() => {
                    handleRegenerate();
                  }}
                />
              </div>
              <div className={styles.wizardHeader_right}>
                <p>
                  Review your suggested project plan and choose which Categories
                  and Action Items to add to your project.
                </p>
              </div>
            </div>
          </div>

          <div>
            <div
              className={styles.planHeader}
              style={{
                marginBottom: "0px",
              }}
            >
              <div className={styles.planHeader_details}>
                <div className={styles.planHeader_details_left}>
                  <div className={styles.title}>
                    <h2 className="text-oneline">{wizardData?.project_name}</h2>
                  </div>

                  <h6 className={styles.deadline}>
                    Deadline:
                    <span>
                      <span>
                        {moment(wizardData?.project?.dead_line).format(
                          "MM/DD/YYYY"
                        )}
                      </span>
                    </span>
                  </h6>

                  <div className={styles.objectiveButtons}>
                    <button
                      ref={(el) => ($buttonsRef.current[0] = el)}
                      className={activeTextTab === 1 ? styles.active : ""}
                      type="button"
                      onClick={() => {
                        setActiveTextTab(1);
                        setTextEdit(true);
                      }}
                    >
                      Objective
                    </button>
                    <button
                      ref={(el) => ($buttonsRef.current[1] = el)}
                      className={activeTextTab === 2 ? styles.active : ""}
                      type="button"
                      onClick={() => {
                        setActiveTextTab(2);
                        setTextEdit(true);
                      }}
                    >
                      Purpose
                    </button>
                  </div>

                  <div className={styles.objectiveTextarea}>
                    {activeTextTab === 1 && (
                      <textarea
                        ref={$objectiveRef}
                        value={objectiveCopy || ""}
                        onChange={(event) =>
                          handleCopyChange(event, setObjectiveCopy, 255)
                        }
                        name={"name"}
                        readOnly={!textEdit}
                      />
                    )}

                    {activeTextTab === 2 && (
                      <textarea
                        ref={$purposeRef}
                        value={purposeCopy || ""}
                        onChange={(event) =>
                          handleCopyChange(event, setPurposeCopy, 255)
                        }
                        name={"purpose"}
                        readOnly={!textEdit}
                      />
                    )}
                  </div>
                </div>
                <div className={styles.planHeader_details_right}>
                  <div className={styles.goalitem}>
                    <div className={styles.goalitem_content}>
                      {selectedGoal?.id ? (
                        <h4 className="text-oneline">
                          {selectedGoal?.goal_name || "No name"}
                        </h4>
                      ) : (
                        <h2>No Goal Connected</h2>
                      )}

                      <div ref={goalRef} className={styles.goal_input_wrapper}>
                        <div>
                          {selectedGoal?.id ? (
                            <h6
                              onClick={() => {
                                window.open(
                                  `https://${gpsURL}/journeys/action-plans?j=${selectedGoal?.journey}&g=${selectedGoal?.id}&m=${selectedGoal?.master_plan_id}/`,
                                  "_blank"
                                );
                              }}
                            >
                              <GoalIcon />
                              See Goal
                            </h6>
                          ) : (
                            <h6
                              onClick={() => {
                                setIsGoalSelect(!isGoalSelect);
                              }}
                            >
                              <ConnectIcon />
                              Connect Goal
                            </h6>
                          )}
                        </div>

                        <GoalSearch
                          goalRef={goalRef}
                          allGoalList={allGoalList}
                          isGoalSelect={isGoalSelect}
                          setIsGoalSelect={setIsGoalSelect}
                          selectedGoal={selectedGoal}
                          setSelectedGoal={setSelectedGoal}
                          setIsGoalModel={setIsGoalModel}
                          onwer={true}
                          isHeader={true}
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className={styles.wizardBody}>
            <ul className={styles.listHeader}>
              <li>Category & Action Items</li>
              <li>Weight</li>
              <li>Measurement</li>
              <li>Start Date</li>
              <li>Due Date</li>
              <li>Duration</li>
            </ul>

            <DragDropContext onDragEnd={handleDragEnd}>
              {data?.Categories?.map((category: any, categoryIndex: number) => (
                <div className={styles.planList_container} key={categoryIndex}>
                  <Droppable droppableId={`category-${categoryIndex}`}>
                    {(provided, snapshot) => (
                      <div
                        {...provided.droppableProps}
                        ref={provided.innerRef}
                        className={`${styles.categoryDroppable} ${
                          snapshot.isDraggingOver ? styles.draggingOver : ""
                        }`}
                      >
                        <div className={styles.planList_category}>
                          <div className={styles.title}>
                            <input type="checkbox" />
                            <h2>{category?.name}</h2>
                          </div>
                          <span className={styles.weight}>--</span>
                          <span className={styles.measurement}>--</span>
                          <span className={styles.deadline}>
                            <span>
                              {new Date(
                                category?.start_date
                              ).toLocaleDateString("en-US", {
                                month: "short",
                                day: "numeric",
                              })}
                            </span>
                            <Calander />
                          </span>
                          <span className={styles.deadline}>
                            <span>
                              {new Date(category?.end_date).toLocaleDateString(
                                "en-US",
                                {
                                  month: "short",
                                  day: "numeric",
                                }
                              )}
                            </span>
                            <Calander />
                          </span>
                          <span className={styles.duration}>
                            {formatDuration(category?.duration)}
                          </span>
                        </div>

                        <div className={styles.dropArea}>
                          {category?.Action_Items?.map(
                            (item: any, index: number) => (
                              <Draggable
                                key={`${categoryIndex}-${index}`}
                                draggableId={`item-${categoryIndex}-${index}`}
                                index={index}
                              >
                                {(provided, snapshot) => (
                                  <div
                                    ref={provided.innerRef}
                                    {...provided.draggableProps}
                                    className={styles.planList}
                                  >
                                    <div className={styles.title}>
                                      <input type="checkbox" />
                                      <div {...provided.dragHandleProps}>
                                        <MoveIcon />
                                      </div>
                                      <h2>{item?.name}</h2>
                                      <h4
                                        className={styles.perioty}
                                        style={{
                                          backgroundColor:
                                            item?.priority === "High"
                                              ? "#FAD6D6"
                                              : item?.priority === "Medium"
                                              ? "#D0E3ED"
                                              : "#EDFDC6",
                                          color:
                                            item?.priority === "High"
                                              ? "#F00000"
                                              : item?.priority === "Medium"
                                              ? "#1271A6"
                                              : "#458C0A",
                                        }}
                                      >
                                        {item?.priority === "High"
                                          ? "High"
                                          : item?.priority === "Medium"
                                          ? "Medium"
                                          : "Low"}
                                      </h4>
                                    </div>
                                    <span className={styles.weight}>
                                      {item.weight}
                                    </span>
                                    <span className={styles.measurement}>
                                      --
                                    </span>
                                    <span className={styles.deadline}>
                                      <span>
                                        {new Date(
                                          item.start_date
                                        ).toLocaleDateString("en-US", {
                                          month: "short",
                                          day: "numeric",
                                        })}
                                      </span>
                                      <Calander />
                                    </span>
                                    <span className={styles.deadline}>
                                      <span>
                                        {new Date(
                                          item.end_date
                                        ).toLocaleDateString("en-US", {
                                          month: "short",
                                          day: "numeric",
                                        })}
                                      </span>
                                      <Calander />
                                    </span>
                                    <span className={styles.duration}>
                                      {formatDuration(item?.duration)}
                                    </span>
                                  </div>
                                )}
                              </Draggable>
                            )
                          )}
                          {provided.placeholder}
                        </div>
                      </div>
                    )}
                  </Droppable>
                </div>
              ))}
            </DragDropContext>
          </div>

          <div className={styles.model_footer}>
            <button onClick={handleCancel} disabled={isSaveLoading}>
              Cancel
            </button>
            <button onClick={handleSave} disabled={isSaveLoading}>
              {isSaveLoading ? <LoadingOutlined /> : "Save Project"}
            </button>
          </div>
        </div>
      )}
    </AppLayout>
  );
};

export default PlanDetailsWizard;
