export const ActionTypes = {
  GET_LOADING: "GET_OPTION_FILES_LOADING",
  GET_ERROR: "GET_OPTION_FILES_ERROR",
  GET_SUCCESS: "GET_OPTION_FILES_SUCCESS",

  CREATE_LOADING: "CREATE_OPTION_FILES_LOADING",
  CREATE_ERROR: "CREATE_OPTION_FILES_ERROR",
  CREATE_SUCCESS: "CREATE_OPTION_FILES_SUCCESS",

  DELETE_LOADING: "DELETE_OPTION_FILES_LOADING",
  DELETE_ERROR: "DELETE_OPTION_FILES_ERROR",
  DELETE_SUCCESS: "DELETE_OPTION_FILES_SUCCESS",
};
