import React from "react";
import { Button, Col, Divider, Radio, Row } from "antd";
import { ReactComponent as Sorting } from "../../../../assets/svg/sortingIcon.svg";

const sortbylist = [
  "Name",
  // 'Priority',
  "Startline",
  "Deadline",
  "Decision Progress",
];
const Sort = ({
  setSortedList,
  decision,
  setDecision,
  sortBy,
  setSortBy,
  isAscending,
  setIsAscending,
  handleChange,
  handleSort,
}) => {

  const sortByItem = (sortBy, isAscending) => {
    let item = sortBy;
    if (item === "Name") {
      if (isAscending) {
        setDecision([
          ...decision.sort((a, b) => a.problem.localeCompare(b.problem)),
        ]);
        setSortedList([
          ...decision.sort((a, b) => a.problem.localeCompare(b.problem)),
        ]);
        return;
      }
      setDecision([
        ...decision.sort((a, b) => b.problem.localeCompare(a.problem)),
      ]);
      setSortedList([
        ...decision.sort((a, b) => b.problem.localeCompare(a.problem)),
      ]);
      return;
    }
    if (item === "Startline") {
      if (isAscending) {
        setDecision([
          ...decision.sort(
            (a, b) => Date.parse(a.startline) - Date.parse(b.startline)
          ),
        ]);
        setSortedList([
          ...decision.sort(
            (a, b) => Date.parse(a.startline) - Date.parse(b.startline)
          ),
        ]);
        return;
      }
      setDecision([
        ...decision.sort(
          (a, b) => Date.parse(b.startline) - Date.parse(a.startline)
        ),
      ]);
      setSortedList([
        ...decision.sort(
          (a, b) => Date.parse(b.startline) - Date.parse(a.startline)
        ),
      ]);
      return;
    }
    if (item === "Deadline") {
      if (isAscending) {
        setDecision([
          ...decision.sort(
            (a, b) => Date.parse(a.deadline) - Date.parse(b.deadline)
          ),
        ]);
        setSortedList([
          ...decision.sort(
            (a, b) => Date.parse(a.deadline) - Date.parse(b.deadline)
          ),
        ]);
        return;
      }
      setDecision([
        ...decision.sort(
          (a, b) => Date.parse(b.deadline) - Date.parse(a.deadline)
        ),
      ]);
      setSortedList([
        ...decision.sort(
          (a, b) => Date.parse(b.deadline) - Date.parse(a.deadline)
        ),
      ]);
      return;
    }

    if (item === "Decision Progress") {
      if (isAscending) {
        setSortedList([
          ...decision.sort(
            (a, b) => Date.parse(a.deadline) - Date.parse(b.deadline)
          ),
        ]);
        setDecision([
          ...decision.sort(
            (a, b) => Date.parse(a.deadline) - Date.parse(b.deadline)
          ),
        ]);
        return;
      }
      setDecision([
        ...decision.sort(
          (a, b) => Date.parse(b.deadline) - Date.parse(a.deadline)
        ),
      ]);
      setSortedList([
        ...decision.sort(
          (a, b) => Date.parse(b.deadline) - Date.parse(a.deadline)
        ),
      ]);
      return;
    }
  };

  return (
    <Col x className={"sortByDivForDecision"}>
      <div className="text_white mb-1">Sort by</div>
      {sortbylist?.map((item, index) => (
        <div key={index} className={"checkboxDivFilter"}>
          <Radio
            checked={sortBy === item}
            className="mb-1 sortRadio text_white"
            onClick={(e) => {
              setSortBy(item);
              e.stopPropagation();
            }}
          >
            {item}
          </Radio>
        </div>
      ))}
      <Divider type="horizontal" className="sortDivider" />

      <Row justify="space-between" align="middle" className="mt-2">
        <div
          className="AscendingBoxForDecision"
          onClick={(e) => {
            setIsAscending(!isAscending);
            e.stopPropagation();
          }}
        >
          {!isAscending ? "Descending" : "Ascending"}
          <Sorting className="ml-1 color-white" />
        </div>
        <Button
          type="primary"
          onClick={(e) => {
            sortByItem(sortBy, isAscending);
            handleSort();
            handleChange("openSort", false);
          }}
          style={{ borderRadius: 5 }}
          className="whiteButton"
          disabled={!sortBy}
        >
          Sort
        </Button>
      </Row>
    </Col>
  );
};
export { Sort };
