export interface CustomUnitOption {
  id: number;
  label: number;
  value: string;
}

export interface CustomLabel {
  value: string;
  custom_unit_options: CustomUnitOption[];
  id: number;
}

export interface PlanDetails {
  goal: {
    label: string;
    custom_labels: CustomLabel[];
    custom_unit: number;
    [key: string]: any;
  };
}

export const getCustomUnitName = (planDetails: PlanDetails) => {
  if (planDetails?.goal?.label === "custom" && planDetails?.goal?.custom_unit) {
    for (const customLabel of planDetails.goal.custom_labels) {
      const customUnitOption = customLabel.custom_unit_options.find(
        (option) => option.id === planDetails.goal.custom_unit
      );
      if (customUnitOption) {
        return customUnitOption.value;
      }
    }
  }

  return (
    planDetails?.goal?.label === "custom" ? planDetails?.goal[planDetails?.goal?.label + "_unit"] : planDetails?.goal?.metric_unit_detail?.name
  );
};

export const getStatus = (items: any, isCount: boolean) => {
  if (items?.every((item: any) => item.plan_progress === 0)) {
    return isCount ? 0 : "To Do";
  }
  const completedCount = items?.filter(
    (item: any) => item.plan_progress === 10
  ).length;

  if (isCount) {
    return completedCount;
  }

  if (completedCount === items.length) {
    return "Completed";
  }

  if (
    items?.some(
      (item: any) => item.plan_progress > 0 && item.plan_progress < 10
    )
  ) {
    return "In Progress";
  }

  return "Unknown";
};

export const getUnit = (label: any) => {
  switch (label) {
    case "distance":
      return "distance_unit";
    case "energy":
      return "energy_unit";
    case "height":
      return "height_unit";
    case "time":
      return "time_unit";
    case "volume":
      return "volume_unit";
    case "weight":
      return "weight_unit";
    case "count":
      return "count_unit";
    case "scale":
      return "scale_unit";
    case "currency":
      return "currency_unit";
    case "custom":
      return "custom_unit_name";
    default:
      return "";
  }
};
