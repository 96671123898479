import { FC, useEffect } from "react";
import { CloseOutlined } from "@ant-design/icons";

import DocumentsList from "./DocumentsList";

import * as Styled from "./styled";

interface IProps {
  isOpen: boolean;
  optionId: number;
  onClose: () => void;
}

const useEscapeKeyListener = (onClose: () => void) => {
  useEffect(() => {
    const handleEscape = (event: KeyboardEvent) => {
      if (event.key === "Escape") {
        onClose();
      }
    };

    window.addEventListener("keydown", handleEscape);

    return () => window.removeEventListener("keydown", handleEscape);
  }, [onClose]);
};

const DocumentsModal: FC<IProps> = ({ isOpen, optionId, onClose }) => {
  useEscapeKeyListener(onClose);

  if (!isOpen)
    return null;

  return (
    <Styled.Background>
      <Styled.Modal>
        <Styled.Content>
          <Styled.Header>
            <Styled.Heading>Attachments</Styled.Heading>
            <CloseOutlined onClick={onClose} />
          </Styled.Header>

          <DocumentsList
            optionId={optionId}
            onClose={onClose}
          />
        </Styled.Content>
      </Styled.Modal>
    </Styled.Background>
  );
};

export default DocumentsModal;
