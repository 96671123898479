import React, { ChangeEvent, FC, FormEvent, useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useDropzone } from 'react-dropzone';
import { RadioButton, InputField, InputArea, Button } from "elements";

import {
  Wrapper,
  List,
  Hr,
  Content,
  Footer,
  ListPlaceholder,
  Form,
  FormFooter,
  FormTypes,
  FileUploadBox
} from "./styled";

import FileItem, { Type } from "./Item";

import { CreateOptionFile, DeleteOptionFile, GetOptionFiles } from "redux/DecisionMatrix/OptionFiles/action";

interface IProps {
  optionId: number;
  onClose: () => void;
}

const DocumentsList: FC<IProps> = ({ optionId, onClose }) => {
  const dispatch = useDispatch();
  const fileInputRef = useRef<HTMLInputElement>(null);

  const [selectedType, setSelected] = useState<Type>(Type.File);
  const [formState, setFormState] = useState<{ fileName?: string; link?: string; note?: string }>({});

  // @ts-ignore
  const { data, loading } = useSelector((state: { optionFilesReducer: { data: IItem[]; loading: boolean } }) => state.optionFilesReducer);

  useEffect(() => {
    dispatch(GetOptionFiles(optionId));
  }, [optionId]);

  // react-dropzone hook
  const { getRootProps, getInputProps, isDragActive } = useDropzone({
    onDrop: (acceptedFiles) => {
      if (acceptedFiles.length > 0) {
        setFormState((prev) => ({ ...prev, fileName: acceptedFiles[0].name }));
        if (fileInputRef.current) {
          // Assign the dropped file to the input ref for form submission
          const dataTransfer = new DataTransfer();
          dataTransfer.items.add(acceptedFiles[0]);
          fileInputRef.current.files = dataTransfer.files;
        }
      }
    },
    accept: {
      'application/pdf': ['.pdf'],
      'application/msword': ['.doc'],
      'application/vnd.ms-excel': ['.xls'],
      'text/csv': ['.csv'],
    },
    multiple: false, // Restrict to single file, adjust as needed
  });

  const handleRemove = (id: number) => {
    dispatch(DeleteOptionFile(id));
  }

  const handleAddFileClick = () => {
    // Trigger click on the hidden file input
    fileInputRef.current?.click();
  };

  const handleFileChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const files = event.target.files;

    if (files && files.length > 0) {
      setFormState(prev => ({ ...prev, fileName: files[0].name }));
    }
  };

  const handleSubmit = (event: FormEvent) => {
    event.preventDefault();
    const formData = new FormData();
    formData.append(`optionfile[0]option`, optionId.toString());

    switch(selectedType) {
      case Type.File:
        const files = fileInputRef.current?.files;
        if (files && files.length > 0) {
          formData.append(`optionfile[0]file`, files[0]);
          formData.append(`optionfile[0]type`, Type.File);
          dispatch(CreateOptionFile(formData));

          // Reset form
          setFormState({});
          fileInputRef.current.value = '';
        }
        break;
      case Type.Note:
        formData.append(`optionfile[0]note`, formState.note || '');
        formData.append(`optionfile[0]type`, Type.Note);
        dispatch(CreateOptionFile(formData));

        // Reset form
        setFormState({});
        break;
      case Type.Link:
        formData.append(`optionfile[0]external_link`, formState.link || '');
        formData.append(`optionfile[0]type`, Type.Link);
        dispatch(CreateOptionFile(formData));

        // Reset form
        setFormState({});
        break;
      default:
        break;
    }
  };

  const handleTypeChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (Object.values(Type).includes(event.target.id as Type)) {
      setSelected(event.target.id as Type);
      setFormState({});
    }
  };

  const handleInputChange = (event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    const { name, value } = event.target;
    setFormState(prev => ({ ...prev, [name]: value }));
  };

  const types = [
    {
      id: Type.File,
      label: 'Document'
    },
    {
      id: Type.Link,
      label: 'Link'
    },
    {
      id: Type.Note,
      label: 'Note'
    }
  ];

  return (
    <Wrapper>
      <FormTypes>
        {types.map((item) => (
          <RadioButton
            id={item.id}
            checked={selectedType === item.id}
            label={item.label}
            name="type"
            rtl
            onChange={handleTypeChange}
            styles={{
              label: {
                alignItems: 'center',
                flexDirection: 'row',
                gap: 6
              }
            }}
            key={item.id}
          />
        ))}
      </FormTypes>
      
      <Form onSubmit={handleSubmit}>
        {selectedType === Type.File && (
          <>
            <FileUploadBox
              {...getRootProps()} // Spread dropzone props for drag-and-drop
              onClick={handleAddFileClick}
              hasFile={!!formState.fileName}
              isDragActive={isDragActive} // Pass drag state for styling
            >
              <input
                {...getInputProps()} // Spread input props for dropzone
                ref={fileInputRef}
                style={{ display: 'none' }}
                onChange={handleFileChange}
              />
              {isDragActive ? 'Drop the file here!' : formState.fileName || 'Add Document'}
            </FileUploadBox>

            <FormFooter>
              {formState.fileName && <Button styleType="primary" type="submit">
                Upload
              </Button>}
            </FormFooter>
          </>
        )}

        {selectedType === Type.Note && (
          <>
            <InputArea
              name="note"
              value={formState.note || ''}
              placeholder="..."
              max={256}
              counter
              style={{ width: '100%', maxHeight: 180, overflowY: "auto" }}
              onChange={handleInputChange}
            />
            <FormFooter>
              <Button styleType="primary" type="submit" disabled={!formState.note?.length}>
                Add
              </Button>
            </FormFooter>
          </>
        )}

        {selectedType === Type.Link && (
          <>
            <InputField
              name="link"
              value={formState.link || ''}
              type="url"
              placeholder="https://"
              onChange={handleInputChange}
              parentStyle={{ flex: 1 }}
            />
            <FormFooter>
              <Button styleType="primary" type="submit" disabled={!formState.link?.length}>
                Add
              </Button>
            </FormFooter>
          </>
        )}
      </Form>

      <Hr $mb={0} />

      <Content>
        {data.length ? (
          <>
            <List>
              {data.map((item) => (
                <FileItem item={item} onRemove={handleRemove} key={item.id} />
              ))}
            </List>
          </>
        ) : (
          <ListPlaceholder>
            <span>There are no attachments yet.</span>
          </ListPlaceholder>
        )}
      </Content>

      <Hr $mt={0} />

      <Footer>
        <Button
          onClick={onClose}
          disabled={loading}
        >
          {loading ? "Loading..." : "Close"}
        </Button>
      </Footer>
    </Wrapper>
  );
};

export default DocumentsList;
