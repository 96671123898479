import { useEffect, useRef, useState } from "react";

import { Modal } from "antd";

// @ts-ignore
import styles from "./styles.module.scss";
// @ts-ignore
import GoalSearch from "../../components/GoalSearch";

import { useDispatch, useSelector } from "react-redux";
// @ts-ignore
import { websocketConnection } from "utils/websocket";

import { ReactComponent as ArootahStarLogo } from "assets/svg/arootahStar.svg";
import { ReactComponent as ArootahStarLogoBig } from "assets/svg/arootahStarBig.svg";
import { ReactComponent as ArrowDownGray } from "assets/svg/arrowDownGray.svg";
import { ReactComponent as EmptySvg } from "assets/svg/empty.svg";

// @ts-ignore
import loaderIcon from "assets/svg/assistant-loader.gif";

import {
  getGaolList,
  // @ts-ignore
} from "../../modules/actions/PlanActions";
import { useNavigate } from "react-router-dom";
import moment from "moment";

const WizardModel = ({
  visible,
  onCancel,
}: {
  visible: boolean;
  onCancel: () => void;
}) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const goalRef = useRef(null);

  const [dots, setDots] = useState("");
  const [isGoalSelect, setIsGoalSelect] = useState(false);
  const [selectedGoal, setSelectedGoal] = useState<any>(null);
  const [loading, setLoading] = useState(false);
  const [isErorr, setIsError] = useState(false);
  const [isNavigate, setIsNavigate] = useState(false);

  const [data, setData] = useState({
    name: "",
    detail: "",
  });

  const { user } = useSelector((state: any) => state.auth);
  const userName =
    user?.data?.first_name + " " + user?.data?.last_name || user?.name;

  const { allGoalList, LoadingGoals } = useSelector(
    (state: any) => state.plan.plan
  );

  const handleError = (message: any) => {
    setIsError(true);
    setLoading(false);
  };

  const handleMessage = (message: any) => {
    let res = JSON?.parse(message);
    if (res?.ping || res?.pong) return;
    if (res?.message?.Categories?.length > 0) {
      dispatch({
        type: "WIZARD_DATA",
        payload: res?.message,
      });

      setIsNavigate(true);
    } else {
      setIsError(true);
      setLoading(false);
    }
  };

  useEffect(() => {
    websocketConnection?.setMessageCallback(handleMessage, handleError);

    return () => {
      websocketConnection?.setMessageCallback(null);
    };
  }, []);

  useEffect(() => {
    if (isNavigate) {
      navigate(
        `/project-planner/plan-details/isWizard?project_name=${data.name}&objective=${data.detail}&goal_id=${selectedGoal?.id}`
      );
    }
  }, [isNavigate]);

  useEffect(() => {
    if (loading) {
      const interval = setInterval(() => {
        setDots((prev) => {
          if (prev === "...") return "";
          return prev + ".";
        });
      }, 500);

      return () => clearInterval(interval);
    }
  }, [loading]);

  const handleSubmit = (e: any) => {
    e.preventDefault();
    setLoading(true);

    websocketConnection.send(
      JSON.stringify({
        endpoint: "generate_project_action_suggestions",
        project_name: data.name,
        objective: data.detail,
        goal_id: selectedGoal?.id || "",
        start_date: moment().format("YYYY-MM-DD"),
        end_date: moment().add(1, "month").format("YYYY-MM-DD"),
      })
    );
  };

  useEffect(() => {
    dispatch(getGaolList());
  }, []);

  const handleCancel = () => {
    onCancel();
    setIsNavigate(false);
    setIsError(false);
    setLoading(false);
    setIsGoalSelect(false);
    setSelectedGoal(null);
    setData({
      name: "",
      detail: "",
    });
  };

  const handleSkip = () => {
    handleCancel();
    navigate(`/project-planner/create-plan`);
  };

  return (
    <Modal
      open={visible}
      title={false}
      footer={false}
      closable={false}
      maskClosable={false}
      width={1080}
      onCancel={handleCancel}
      centered
      className="disablePadding"
    >
      {LoadingGoals ? (
        <div className={styles.loader}>
          <img src={loaderIcon} alt="loader" width={52} height={53} />
        </div>
      ) : loading ? (
        <div className={styles.loader}>
          <ArootahStarLogoBig />

          <h1
            className={styles.gradientText}
            style={{
              fontSize: "24px",
            }}
          >
            Creating your project plan{dots}
          </h1>

          <h4 className={styles.poweredText}>
            Powered by <span>Aria™</span>
          </h4>
        </div>
      ) : isErorr ? (
        <div className={styles.loader}>
          <h4 className={styles.skip} onClick={handleSkip}>
            Skip
          </h4>

          <EmptySvg />
          <h1 className={styles.errorText}>
            Hmm… Something went wrong. Please try again.
          </h1>
          <h5 className={styles.notYou}>It's not you, it's us.</h5>
          <button className={styles.primaryButton} onClick={handleSubmit}>
            Try Again
          </button>
        </div>
      ) : (
        <div className={styles.wizardModel}>
          <h4 className={styles.skip} onClick={handleSkip}>
            Skip
          </h4>

          <ArootahStarLogo />

          <h1 className={styles.gradientText}>
            Hi {userName}, <br /> What kind of project do you want to create?
          </h1>

          <form action="">
            <div className={styles.formInput}>
              <label>Give your project a name. (optional)</label>
              <input
                type="text"
                placeholder="Project name"
                className={styles.input}
                value={data.name}
                onChange={(e) => setData({ ...data, name: e.target.value })}
              />
            </div>
            <div className={styles.formInput}>
              <label>Tell me about your project in a few sentences</label>
              <textarea
                name=""
                id=""
                placeholder="What do you want to achieve?"
                value={data.detail}
                onChange={(e) => setData({ ...data, detail: e.target.value })}
              ></textarea>
            </div>
            <div className={styles.formInput}>
              <label>
                Connect an existing goal to your project? (optional)
              </label>

              <div className={styles.goal_input_wrapper}>
                <div
                  ref={goalRef}
                  className={styles.goal_input}
                  onClick={() => setIsGoalSelect(!isGoalSelect)}
                  style={{
                    borderLeft: selectedGoal
                      ? `4px solid ${selectedGoal?.color || "#A3A3A3"}`
                      : "1px solid #e5e5e5",
                  }}
                >
                  {selectedGoal ? (
                    <div
                      className={styles.goal_input_selected}
                      style={{
                        borderLeftColor: selectedGoal?.color || "#A3A3A3",
                      }}
                    >
                      <h3>{selectedGoal?.goal_name || "No Name"}</h3>
                    </div>
                  ) : (
                    "Choose goal"
                  )}

                  <ArrowDownGray />
                </div>

                <GoalSearch
                  allGoalList={allGoalList}
                  goalRef={goalRef}
                  onwer={true}
                  outsideClick={true}
                  setSelectedGoal={setSelectedGoal}
                  setIsGoalModel={setIsGoalSelect}
                  isGoalSelect={isGoalSelect}
                  setIsGoalSelect={setIsGoalSelect}
                  selectedGoal={selectedGoal}
                  isWizard={true}
                  isProjectDetail={true}
                  isCustomHeight="150px"
                />
              </div>
            </div>

            <div className={styles.wizardFooter}>
              <button onClick={onCancel}>Back</button>
              <button
                onClick={handleSubmit}
                disabled={data?.detail ? false : true}
              >
                Create Project
              </button>
            </div>
          </form>
        </div>
      )}
    </Modal>
  );
};

export default WizardModel;
