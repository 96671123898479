import styled from "styled-components";

export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
`;

export const Content = styled.div`
  height: 220px;
`;

export const List = styled.div`
  display: flex;
  flex-direction: column;
  gap: 4px;
  overflow-y: auto;
  padding-right: 12px;
  max-height: 220px;

  ::-webkit-scrollbar:vertical {
    width: 4px !important;
  }

  ::-webkit-scrollbar-track {
    background: transparent !important ;
  }

  ::-webkit-scrollbar-thumb:vertical {
    background-color: #D7DADE;
    border-radius: 100px;
  }
`;

export const Hr = styled.hr<{ $mt?: number; $mb?: number; }>`
  margin: 12px 0;
  border: none;
  height: 1px;
  background: var(--Neutral-200, #D9D9D9);
`;

export const Footer = styled.div`
  display: flex;
  justify-content: flex-end;
`;

export const ListPlaceholder = styled.div`
  height: 220px;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  gap: 12px;

  span {
    color: var(--Neutral-500, #737373);
    text-align: center;
    font-family: Inter;
    font-size: 14px;
    font-weight: 400;
    line-height: 20px;
  }
`;

export const FormTypes = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 16px;
  gap: 14px;
`;

export const Form = styled.form`
  display: flex;
  flex-direction: column;
  gap: 1rem;
  min-height: 80px;
  padding: 0 15%;
`;

export const FormFooter = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const FileUploadBox = styled.div<{ hasFile: boolean; isDragActive: boolean }>`
  align-self: center;
  width: 300px;
  height: 80px;
  border: 2px dashed #666;
  border-radius: 8px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  background-color: ${({ hasFile, isDragActive }) =>
    isDragActive ? '#e0e0e0' : hasFile ? '#f8f8f8' : '#fff'};
  color: ${({ hasFile, isDragActive }) => (isDragActive ? '#000' : hasFile ? '#333' : '#666')};
  transition: all 0.2s ease-in-out;

  &:hover {
    border-color: #333;
    background-color: #f8f8f8;
  }
`;
