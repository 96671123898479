import { ActionTypes } from "./types";

let INITIAL_STATE = {
  data: [] as any[],
  loading: false,
  error: null,
};

export default (state = INITIAL_STATE, action: any) => {
  switch (action.type) {
    // GET
    case ActionTypes.GET_LOADING:
      return {
        ...state,
        loading: true
      };

    case ActionTypes.GET_SUCCESS:
      return {
        ...state,
        loading: false,
        data: action.payload
      };

    case ActionTypes.GET_ERROR:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };

    // CREATE
    case ActionTypes.CREATE_LOADING:
      return {
        ...state,
        loading: true
      };

    case ActionTypes.CREATE_SUCCESS:
      return {
        ...state,
        data: [
          ...state.data,
          action.payload[0]
        ],
        loading: false,
      };

    case ActionTypes.CREATE_ERROR:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };

    // DELETE
    case ActionTypes.DELETE_LOADING:
      return {
        ...state,
        loading: true
      };

    case ActionTypes.DELETE_SUCCESS:
      return {
        ...state,
        data: state.data.filter(item => item.id !== action.payload),
        loading: false,
      };

    case ActionTypes.DELETE_ERROR:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };

    default:
      return state;
  }
};
