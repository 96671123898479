import { updateCons } from "api";
import { Error } from "utils/Response";
import { GetCons } from "../GetCons/action";
import { ActionTypes } from "./types";
import { GetDescriptionsSummary } from "redux/DecisionMatrix/GetDecisionSummary/action";

const activeRequests = {}; // Store active requests

export const UpdateCons =
  (Data, consId, id, setEditConsInput, optionId) => async (dispatch) => {
    // Cancel previous request if it exists
    if (activeRequests[consId]) {
      activeRequests[consId].abort();
    }

    // Create a new AbortController
    const controller = new AbortController();
    const signal = controller.signal;
    activeRequests[consId] = controller; // Store new controller

    try {
      dispatch({ type: ActionTypes.LOADING });

      const { data, status } = await updateCons(Data, consId, { signal });

      dispatch({
        type: ActionTypes.SUCCESS,
        payload: data,
      });

      if (status === 200) {
        dispatch(GetCons(id));
        if (optionId) {
          dispatch(GetDescriptionsSummary(optionId));
        }
        setEditConsInput(null);
      }

      // Remove the request from activeRequests after completion
      delete activeRequests[consId];

    } catch (error) {
      if (error.name === "AbortError") {
        console.log(`Request for consId ${consId} was aborted.`);
      } else {
        console.error("API Error:", error);
        Error(error?.response?.data?.error);
        dispatch({
          type: ActionTypes.ERROR,
          payload: error,
        });
      }
    }
  };
